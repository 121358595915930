import styled, { css } from 'styled-components'
import { ListProps, ListItem } from '.'
import { lighten } from 'polished'

const wrapperModifiers = {
  small: () => css`
    img.icon {
      width: 36px;
      height: 36px;
    }
  `,
  medium: () => css`
    img.icon {
      width: 48px;
      height: 48px;
    }
  `,
  disabled: () => css`
    opacity: 0.5;
    pointer-events: none;
  `
}

export const Wrapper = styled.ul`
  hr {
    width: 100%;
    margin: auto;
    color: rgba(33, 33, 33, 0.04);
    border-color: rgba(33, 33, 33, 0.04);
    background-color: rgba(33, 33, 33, 0.04);
  }
`

export const Item = styled.li<
  Pick<ListProps, 'preventIconStyle' | 'spacingIcon' | 'size' | 'padding'> &
    Pick<ListItem, 'disabled'>
>`
  ${({ theme, size, padding, preventIconStyle, spacingIcon, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${padding ? '15px' : '15px 0px'};
    cursor: pointer;
    list-style: none;
    ${!!size && wrapperModifiers[size]}
    ${!!disabled && wrapperModifiers.disabled}

    &:hover {
      background-color: ${lighten(0.68, theme.colors.mainBg)};
    }

    img.icon,
    svg {
      border-radius: ${preventIconStyle ? '0px' : '50%'};
      margin-right: ${spacingIcon ? '22px' : '0'};
    }
  `}
`

export const ItemText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.small};
  `}
`

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: auto;
  display: flex;
  flex-direction: column;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }
`
