import styled from 'styled-components'
import myHospitalizationImg from 'presentation/assets/icons/my-hospitalization.png'

export const Wrapper = styled.main`
  h2 {
    margin-top: 20px;
    font-weight: ${({ theme }) => theme.font.black};
  }
  p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const PersonRole = styled.h5`
  line-height: 21px;
  letter-spacing: -0.28px;
  padding-left: 9px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  font-weight: ${({ theme }) => theme.font.normal};
`

export const PersonName = styled.h6`
  line-height: 21px;
  letter-spacing: -0.28px;
  padding-left: 9px;
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  font-weight: ${({ theme }) => theme.font.normal};
`

export const AddCompanion = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: 0;
  font-family: ${({ theme }) => theme.font.family};

  svg {
    width: 7%;
    margin-right: 10px;
  }

  label {
    color: #4a4a4a;
    font-size: 14px;
  }
`

export const Guidance = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 100px;
  width: 100%;
  row-gap: 15px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0;

  div:last-child {
    grid-column: 1/3;
  }

  div {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: ${({ theme }) => theme.font.semiBold};
    box-shadow: -2px 3px 5px -4px rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.colors.white};
    border: 1.5px solid ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.border.radius.small};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    color: ${({ theme }) => theme.colors.primary};

    svg {
      margin-right: 8px;
    }
  }
`

export const SurgeryInfo = styled.div``

export const MyHospitalization = styled.section`
  background-image: url(${myHospitalizationImg});
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;

  /* @media (min-width: 475px) {
    background-position: initial;
  } */
`
