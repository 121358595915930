import React from 'react'

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Heading from 'presentation/shared/components/Heading'

import { useHistory } from 'react-router'

import * as S from '../styles'

type ProcedureReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function ProcedureReview({
  surgicalOrder,
  visualizationMode
}: ProcedureReviewProps) {
  const history = useHistory()

  const onEdit = () =>
    history.push('/pedido-cirurgico/procedimento', {
      procedure: surgicalOrder.procedure,
      cid: surgicalOrder.cid,
      surgical_order_id: surgicalOrder.surgical_order_id,
      clinicalHistory: surgicalOrder.clinicalHistory,
      expectedDate: surgicalOrder.expectedDate
    })

  return (
    <>
      <S.ProcedureContainer>
        <header>
          <Heading style={{ margin: '32px 0 20px' }} as="h2" weight={500}>
            Procedimento
          </Heading>
          {!visualizationMode && <small onClick={onEdit}>Editar</small>}
        </header>
        {surgicalOrder.procedure?.map((tuss) => (
          <div key={tuss.code}>
            {tuss.isMain && (
              <>
                <h6>Procedimento principal</h6> <br />
              </>
            )}

            <h6>Tuss: </h6>
            <span>{tuss.code}</span>
            <br />
            <h6>Descrição: </h6>
            <br />
            <span style={{ margin: '10px 0' }}>{tuss.description}</span>
            <br />
            <h6>Quantidade: </h6>
            <span>{tuss.quantity}</span>
          </div>
        ))}
      </S.ProcedureContainer>
      <S.ProcedureContainer>
        <header>
          <Heading style={{ margin: '20px 0' }} weight={500} as="h2">
            História clinica
          </Heading>
          {!visualizationMode && <small onClick={onEdit}>Editar</small>}
        </header>
        <div style={{ minHeight: '155px' }}>
          <p>{surgicalOrder.clinicalHistory}</p>
        </div>
      </S.ProcedureContainer>

      <S.ProcedureContainer>
        <header>
          <Heading style={{ margin: '20px 0' }} as="h2" weight={500}>
            CID
          </Heading>
          {!visualizationMode && <small onClick={onEdit}>Editar</small>}
        </header>
        {surgicalOrder.cid?.map((cid) => (
          <div key={cid.code}>
            <h6>CID: </h6>
            <span>{cid.code}</span>
            <br />
            <h6>Descrição: </h6>
            <br />
            <span style={{ margin: '10px 0' }}>{cid.description}</span>
          </div>
        ))}
      </S.ProcedureContainer>
    </>
  )
}
