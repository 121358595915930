import React, { useState } from 'react'
import { useHistory } from 'react-router'
import * as S from './styles'
import Items from './items'

import { ReactComponent as HomeIcon } from 'presentation/assets/icons/home.svg'
import { ReactComponent as PlusIcon } from 'presentation/assets/icons/plus.svg'
import ProfilePic from '../ProfilePic'
import SheetModal from '../SheetModal'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'

type bottomMenuActions = {
  onAppointment?: (label: string) => void
}

const BottomMenu = ({ onAppointment }: bottomMenuActions) => {
  const history = useHistory()
  const account = useStores().currentAccount.getCurrentAccount()
  const [handleMenu, setHandleMenu] = useState(false)

  const redirect = (url: string) => {
    setHandleMenu(false)
    makeLocalStorageAdapter().set('favorite_doctor', {})
    return history.push(url)
  }

  const items: Record<
    Extract<Profile, Profile.PATIENT | Profile.DOCTOR>,
    { path?: string; noFill?: boolean; icon: React.ReactNode; label?: string }[]
  > = {
    [Profile.PATIENT]: [
      // {
      //   path: '/ajuda',
      //   noFill: true,
      //   icon: <HelpIcon />
      // },
      {
        path: '/home',
        icon: <HomeIcon />,
        label: 'Início'
      },
      {
        path: '/perfil',
        icon: (
          <ProfilePic src="https://source.unsplash.com/random" size="small" />
        ),
        label: 'Perfil'
      }
    ],
    [Profile.DOCTOR]: [
      {
        path: '/home',
        icon: <HomeIcon />
      },
      {
        path: undefined,
        icon: (
          <S.OpenMenuButton
            onClick={() => setHandleMenu(!handleMenu)}
            data-testid="open-menu-button"
          >
            <PlusIcon fill="#ffffff" />
          </S.OpenMenuButton>
        )
      },
      {
        path: '/perfil',
        icon: (
          <ProfilePic src="https://source.unsplash.com/random" size="small" />
        )
      }
    ]
  }

  const handleAppointment = (label: string) => {
    if (onAppointment) {
      onAppointment(label)
      setHandleMenu(false)
    }
  }

  return (
    <S.Wrapper>
      {(account.user.role === Profile.DOCTOR ||
        account.user.role === Profile.PATIENT) &&
        items[account.user.role].map((item, index) => {
          return item.path ? (
            <S.MenuItem
              key={index}
              to={item.path}
              activeClassName="active-menu"
              nofill={item.noFill ? 'true' : undefined}
            >
              <div>{item.icon}</div>
              <label>{item.label}</label>
            </S.MenuItem>
          ) : (
            <div key={index}>{item.icon}</div>
          )
        })}
      <SheetModal
        isOpen={handleMenu}
        style={{
          background: '#F8F4FF',
          boxShadow: 'none'
        }}
        marginbottom="89px"
        onClose={() => null}
        backdrop="false"
        size={300}
      >
        {account?.user?.role ? (
          <S.ModalMenu>
            {Items[account?.user?.role]?.map((item, index) => (
              <S.ModalItem
                key={`${item.label}-${index}`}
                onClick={() => {
                  item.link.length === 0
                    ? handleAppointment(item.label)
                    : redirect(item.link)
                }}
                data-testid={item.link.length > 0 && item.label}
              >
                <S.ModalIcon src={item.icon} />
                <S.ModalDescription>{item.label}</S.ModalDescription>
              </S.ModalItem>
            ))}
          </S.ModalMenu>
        ) : null}
      </SheetModal>
    </S.Wrapper>
  )
}

export default BottomMenu
