import React from 'react'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { RemoteUpdateLifeHabits } from 'service/usecases/update-life-habits/remote-update-life-habits'
import LifeHabits from 'presentation/patient/pages/LifeHabits'

export default function makePatientLifeHabits() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const patientRepository = new PatientRepository(apiRepository)

  const updateLifeHabits = new RemoteUpdateLifeHabits(patientRepository)

  return <LifeHabits updateLifeHabits={updateLifeHabits} />
}
