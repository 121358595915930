import React, { useEffect, useState } from 'react'

import AllergiesLayout from 'presentation/doctor/layouts/Allergies'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { Allergy } from 'domain/entities/allergy-model'

type Props = {
  loadPatientInfo: LoadPatientInfo
}

export default function Allergies({ loadPatientInfo }: Props) {
  const [allergies, setAllergies] = useState<Allergy[]>()

  useEffect(() => {
    async function loadData() {
      try {
        const patient = await loadPatientInfo.load([
          'allergies {description, createdAt}'
        ])
        setAllergies(patient.allergies ?? [])
      } catch (error) {
        return
      }
    }
    loadData()
  }, [])
  return <AllergiesLayout allergies={allergies ?? []} />
}
