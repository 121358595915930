import Checkbox from 'presentation/shared/components/Checkbox'
import React, { HTMLAttributes } from 'react'

import * as S from './styles'

type Props = {
  children: React.ReactNode
  onChange: (value: boolean) => void
} & HTMLAttributes<HTMLDivElement>

export default function TermsContainer({
  children,
  onChange,
  ...props
}: Props) {
  return (
    <>
      <S.Wrapper {...props}>
        <S.TermsWrapper>{children}</S.TermsWrapper>
        <Checkbox
          label="Aceito os termos de Uso Geral e Política de Privacidade da Plataforma"
          id="terms"
          name="terms"
          labelFor="terms"
          style={{ marginTop: 'auto' }}
          onCheck={onChange}
        />
      </S.Wrapper>
    </>
  )
}
