import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import ReviewOrder from 'presentation/doctor/components/Forms/AddSurgicalOrder/ReviewOrder'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { useServices } from '../../../../hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from '../../../../shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'

type Props = {
  updateSurgicalOrder: UpdateSurgicalOrder
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
  loadSurgicalCenter: LoadSurgeryCenter
} & WithLoadingProps

type Location = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

function ReviewSurgicalOrder({
  updateSurgicalOrder,
  loadSurgicalOrderDocument,
  loadSurgicalCenter,
  setIsLoading
}: Props) {
  const { state } = useLocation<Location>()
  const [selectedOrder, setSelectedOrder] = useState<SurgicalOrderModel>()
  const surgicalOrderService = useServices().surgicalOrder

  const { surgicalOrder, visualizationMode } = state || {}

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        setSelectedOrder(
          await surgicalOrderService.loadSurgicalOrder(
            surgicalOrder.surgical_order_id as number,
            [
              'surgical_order_id',
              'patient_id',
              'patientName',
              'patient { phone, email, birthday, gender, cpf, healthInsurance {healthInsuranceName, healthInsuranceCode}}',
              'procedure {procedure_code, code, description, quantity, doctor_name, isMain, surgery_id, pro_fat_id}',
              'opme {solicitations {description, quantity}, providers}',
              'cid {code, description}',
              'hospitalizationMode',
              'hospitalizationType',
              'healthInsurance {healthInsuranceName, healthInsuranceCode}',
              'allergy',
              'hospital {name, hospital_id}',
              'clinicalHistory',
              'surgicalCenter',
              'documents {document_id, type, name}',
              'expectedDate',
              'expectedDuration',
              'hospitalizationAvgTimeInDays',
              'patientHospitalized',
              'requestedUtiDiaries',
              'laterality',
              'preliminaryScintigraphyTime',
              'xrayOfPieceTime',
              'medicalTeamName',
              'medicalTeam { type, name, crm, phone }',
              'anesthetistTeam { anesthesiaType, name, crm, phone }',
              'hasBloodType',
              'hasHemoconcentrates',
              'cellSaverSpec',
              'bloodBagQuantity',
              'equipment { name, specification }',
              'cme { description, quantity }'
            ]
          )
        )
      } catch (e) {
        toast.error(e.message)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [surgicalOrder])

  return (
    <ReviewOrder
      updateSurgicalOrder={updateSurgicalOrder}
      surgicalOrder={selectedOrder ?? {}}
      loadSurgicalOrderDocument={loadSurgicalOrderDocument}
      loadSurgicalCenter={loadSurgicalCenter}
      visualizationMode={visualizationMode}
    />
  )
}

export default WithLoading(ReviewSurgicalOrder)
