import { makeAutoObservable } from 'mobx'
import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import { LoadPatientByCpf } from 'domain/usecases/patient/load-patient-by-cpf'
import { SearchPatient } from 'domain/usecases/patient/search-patient'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { LoadPatientDocuments } from 'domain/usecases/patient/load-patient-documents'
import { DownloadPatientDocument } from 'domain/usecases/patient/download-patient-document'
import { LoadPatientHideContact } from 'domain/usecases/patient/load-patient-hide-contact'
import { LoadPatientPossibleMotherName } from 'domain/usecases/patient/load-patient-possible-mother-name'
import { ValidatePatientMotherAndBirthday } from 'domain/usecases/patient/validate-patient-mother-and-birthday'
import { ValidateRegisteredPatientInDb } from 'domain/usecases/patient/validate-registered-patient-in-db'
import { InvitePatientFromMv } from 'domain/usecases/patient/invite-patient-from-mv'
import { LoadPatientPendencies } from 'domain/usecases/patient/load-patient-pendencies'
import { LoadPatientSchedules } from 'domain/usecases/patient/load-patient-schedules'
import { Interval, Pagination } from 'service/protocols/api/api-client'
import { InviteNewBornPatient } from 'domain/usecases/patient/invite-newborn-patient'
import { GetPatientNextAttendances } from 'domain/usecases/patient/get-patient-next-attendances'
import { UpdatePatientById } from 'domain/usecases/patient/update-patient-by-id'
import { GetPatientOrigins } from 'domain/usecases/patient/get-patient-origins'
import { GetPatientClassificationTypes } from 'domain/usecases/patient/get-patient-classification-types'

type Services = {
  invitePatient: InvitePatient
  invitePatientFromMv: InvitePatientFromMv
  loadPatientByCpf: LoadPatientByCpf
  searchPatient: SearchPatient
  loadPatientInfo: LoadPatientInfo
  loadPatientDocuments: LoadPatientDocuments
  loadPatientPendencies: LoadPatientPendencies
  downloadPatientDocument: DownloadPatientDocument
  loadPatientHideContact: LoadPatientHideContact
  loadPatientPossibleMotherName: LoadPatientPossibleMotherName
  validatePatientMotherAndBirthday: ValidatePatientMotherAndBirthday
  validateRegisteredPatientInDb: ValidateRegisteredPatientInDb
  loadPatientSchedules: LoadPatientSchedules
  inviteNewBornPatient: InviteNewBornPatient
  getPatientNextAttendances: GetPatientNextAttendances
  updatePatientById: UpdatePatientById
  getPatientOrigins: GetPatientOrigins
  getPatientClassificationTypes: GetPatientClassificationTypes
}

export class PatientService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async invitePatient(
    params: InvitePatient.Params
  ): Promise<InvitePatient.Model> {
    return this.services.invitePatient.update(params)
  }

  async loadPatientByCpf(
    cpf: string,
    params: LoadPatientByCpf.Params
  ): Promise<LoadPatientByCpf.Model> {
    return this.services.loadPatientByCpf.load(cpf, params)
  }

  async searchPatient(
    params: SearchPatient.Params
  ): Promise<SearchPatient.Model> {
    return this.services.searchPatient.load(params)
  }

  async loadPatientInfo(
    params: LoadPatientInfo.Params,
    patient_id?: number
  ): Promise<LoadPatientInfo.Model> {
    return this.services.loadPatientInfo.load(params, patient_id)
  }

  async loadPatientDocuments(
    params: LoadPatientDocuments.Params
  ): Promise<LoadPatientDocuments.Model> {
    return this.services.loadPatientDocuments.load(params)
  }

  async downloadPatientDocument(
    document_id: number
  ): Promise<DownloadPatientDocument.Model> {
    return this.services.downloadPatientDocument.load(document_id)
  }

  async loadPatientHideContact(
    params: LoadPatientHideContact.Params
  ): Promise<LoadPatientHideContact.Model> {
    return this.services.loadPatientHideContact.load(params)
  }

  async loadPatientPossibleMotherName(
    params: LoadPatientPossibleMotherName.Params
  ): Promise<LoadPatientPossibleMotherName.Model> {
    return this.services.loadPatientPossibleMotherName.load(params)
  }

  async validatePatientMotherAndBirthday(
    params: ValidatePatientMotherAndBirthday.Params
  ): Promise<ValidatePatientMotherAndBirthday.Model> {
    return this.services.validatePatientMotherAndBirthday.validate(params)
  }

  async validateRegisteredPatientInDb(
    params: ValidateRegisteredPatientInDb.Params
  ): Promise<ValidateRegisteredPatientInDb.Model> {
    return this.services.validateRegisteredPatientInDb.validate(params)
  }

  async invitePatientFromMv(
    params: InvitePatientFromMv.Params
  ): Promise<InvitePatientFromMv.Model> {
    return this.services.invitePatientFromMv.add(params)
  }

  async loadPatientPendencies(
    params: LoadPatientPendencies.Params
  ): Promise<LoadPatientPendencies.Model> {
    return this.services.loadPatientPendencies.load(params)
  }

  async loadPatientSchedules(
    params: LoadPatientSchedules.Params,
    pagination: Pagination,
    interval: Interval
  ): Promise<LoadPatientSchedules.Model[]> {
    return this.services.loadPatientSchedules.load(params, pagination, interval)
  }

  async inviteNewBornPatient(
    params: InviteNewBornPatient.Params
  ): Promise<InviteNewBornPatient.Model> {
    return this.services.inviteNewBornPatient.add(params)
  }

  async getPatientNextAttendances(
    params: GetPatientNextAttendances.Params
  ): Promise<GetPatientNextAttendances.Model> {
    return this.services.getPatientNextAttendances.load(params)
  }

  async updatePatientById(
    params: UpdatePatientById.Params
  ): Promise<UpdatePatientById.Model> {
    return this.services.updatePatientById.update(params)
  }
  async getPatientClassificationTypes(
    params?: GetPatientClassificationTypes.Params
  ): Promise<GetPatientClassificationTypes.Model> {
    return this.services.getPatientClassificationTypes.get(params)
  }
  async getPatientOrigins(
    params?: GetPatientOrigins.Params
  ): Promise<GetPatientOrigins.Model> {
    return this.services.getPatientOrigins.get(params)
  }
}

export default PatientService
