import { observer } from 'mobx-react'
import { useStores } from 'presentation/hooks/use-stores'
import React from 'react'
import { useHistory } from 'react-router'
import HamburguerIcon from '../Icons/HamburguerIcon'
import Logo from '../Logo'
import SideMenu from '../SideMenu'
import * as S from './styles'

export const Header = observer(() => {
  const menuStore = useStores().menu
  const history = useHistory()

  return (
    <>
      <S.Wrapper aria-label="header">
        <S.HamburguerIconWrapper
          aria-label="Open menu icon"
          onClick={() => menuStore.open()}
        >
          <HamburguerIcon />
        </S.HamburguerIconWrapper>

        <S.LogoWrapper onClick={() => history.push('/home')}>
          <Logo />
        </S.LogoWrapper>
        <div></div>
      </S.Wrapper>
      <SideMenu />
    </>
  )
})

export default Header
