import React from 'react'
import Heading from 'presentation/shared/components/Heading'
import * as S from './styles'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'

type Props = {
  pendency: SurgicalPendency
}

export const SurgicalPendencyHeader = ({ pendency }: Props) => {
  return (
    <header>
      <S.SubtitleWrapper>
        <div>
          <h6>Responsável CRMO:</h6>
          <span>{pendency.requestedBy?.name}</span>
        </div>
        <div>
          <h6>Pendência gerada:</h6>
          <span>{dateFormatter.format(new Date(pendency.createdAt))}</span>
        </div>
      </S.SubtitleWrapper>
      <Heading as="h2" color="primary" size="medium">
        {pendency.surgicalOrder?.patientName}
      </Heading>
      <Heading as="h2" color="primary" size="medium">
        {pendency.surgicalOrder?.procedure?.[0].description}
      </Heading>
      <S.AnswerInfoWrapper>
        <div
          style={{
            margin: '0.3rem 0 1.7rem'
          }}
        >
          <h6>Retorno Cirurgião:</h6>
          <span>
            {pendency.answeredAt
              ? dateFormatter.format(new Date(pendency.answeredAt))
              : '-'}
          </span>
        </div>
        {/*<div>*/}
        {/*  <h6>Data da validação:</h6>*/}
        {/*  <span>*/}
        {/*    {pendency.answeredAt*/}
        {/*      ? dateFormatter.format(new Date(pendency.))*/}
        {/*      : '-'}*/}
        {/*  </span>*/}
        {/*</div>*/}
      </S.AnswerInfoWrapper>
    </header>
  )
}

const dateFormatter = new Intl.DateTimeFormat('pt-BR', {
  timeStyle: 'short',
  dateStyle: 'short'
})
