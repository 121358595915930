import React, { useEffect, useState } from 'react'

import AttendancesLayout from 'presentation/patient/layouts/Attendances'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { Hospital } from 'domain/entities/hospital-model'
import { getStatusInfo } from 'presentation/utils/service-status'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'

export default function Attendances() {
  const [surgicalOrders, setSurgicalOrders] = useState<SurgicalOrderModel[]>([])
  const patientService = useServices().patient

  useEffect(() => {
    async function loadData() {
      patientService
        .loadPatientInfo([
          'surgicalOrders (filterSolicitations: true) {',
          'surgical_order_id',
          'procedure {description}',
          'hospital {name, hospital_id}',
          'surgeryDate',
          'scheduled',
          'status{status isActive}',
          'doctor{name, role}',
          '}'
        ])
        .then(
          (patientData) =>
            patientData.surgicalOrders &&
            setSurgicalOrders(patientData.surgicalOrders)
        )
    }
    loadData()
  }, [])

  return (
    <AttendancesLayout
      surgicalOrders={surgicalOrders.map((surgicalOrder) => {
        const statusInfo = getStatusInfo(
          surgicalOrder?.status?.find((status) => status.isActive)?.status ?? ''
        )
        return {
          doctorName: surgicalOrder?.doctor?.name ?? '-',
          doctorRole: 'Médico responsável',
          unit: new Hospital(
            surgicalOrder?.hospital?.hospital_id ?? 0,
            surgicalOrder?.hospital?.name ?? '',
            surgicalOrder?.hospital?.name ?? ''
          ).name,
          status: statusInfo?.label ?? '',
          statusColor: handleStatusColor(surgicalOrder) ?? '',
          color: handleStatusColor(surgicalOrder) ?? '',
          surgical_order_id: surgicalOrder?.surgical_order_id ?? 1,
          date: surgicalOrder?.surgeryDate ?? '',
          title:
            surgicalOrder?.procedure?.[0].description ??
            'Procedimento cirúrgico'
        }
      })}
    />
  )
}

function handleStatusColor(surgicalOrder: SurgicalOrderModel) {
  let color = '#888888'
  surgicalOrder.status?.forEach((status) => {
    if (status.status === SurgicalOrderStatus.CANCELLED) {
      return (color = '#F48989')
    } else if (status.status === SurgicalOrderStatus.SCHEDULED) {
      return (color = '#1BD15D')
    }
  })
  return color
}
