import React from 'react'

import ServiceSolicitationLayout from 'presentation/patient/layouts/ServiceSolicitation'
import { AddHospitalizationService } from 'domain/usecases/hospitalization/add-hospitalization-service'
import { useStores } from 'presentation/hooks/use-stores'

type Props = {
  addHospitalizationService: AddHospitalizationService
}

export default function ServiceSolicitation({
  addHospitalizationService
}: Props) {
  const currentSurgery = useStores().currentSurgery

  return (
    <ServiceSolicitationLayout
      addHospitalizationService={addHospitalizationService}
      surgical_order_id={currentSurgery.getSurgeryIdSelected()}
    />
  )
}
