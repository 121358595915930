import React from 'react'
import * as S from './styles'

import Heading from '../Heading'
import ProfilePic from '../ProfilePic'
import { ReactComponent as LocationIcon } from 'presentation/assets/icons/location.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar.svg'
import { ReactComponent as PadlockIcon } from 'presentation/assets/icons/padlock.svg'
import { ReactComponent as ClockIcon } from 'presentation/assets/icons/clock.svg'
import { getRandomProfileImageUrl } from 'presentation/utils/get-random-profile-image'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

export type ServiceOverviewProps = {
  orderId?: number
  surgicalId?: number
  title?: string
  status?: string
  type?: string
  color?: string
  unit?: string
  date?: string
  onClick?: () => void
  patientName?: string
  doctorEmail?: string
  doctorPhone?: string
  patientEmail?: string
  patientPhone?: string
  style?: CSSProperties
  surgical_order_id?: number
  token?: string
  timeLeft?: string
  doctorName?: string
}

const ServiceOverview = ({
  orderId,
  title,
  status,
  type,
  unit,
  date,
  color,
  onClick,
  patientName,
  style,
  token,
  timeLeft,
  doctorName
}: ServiceOverviewProps) => {
  return (
    <S.Wrapper onClick={onClick} style={style}>
      <S.Header color={color} style={{ fontWeight: 600 }}>
        {status}
      </S.Header>
      <S.Content>
        <S.ContentHeader>
          <S.ServiceType>{type}</S.ServiceType>
        </S.ContentHeader>
        <Heading size="medium" as="h5" color="lightPurple">
          {title}
        </Heading>
        <S.AdditionalInfo>
          <S.LocationContainer>
            <S.Location>
              <LocationIcon />
              <span>{unit}</span>
            </S.Location>
            {timeLeft && (
              <S.TimeLeft>
                <ClockIcon />
                <span>
                  <strong>{timeLeft}</strong>
                  para o seu token expirar
                </span>
              </S.TimeLeft>
            )}
            {orderId && (
              <S.Order>
                <S.OrderId>
                  <span>
                    <strong>Nº Aviso: </strong>
                    {orderId}
                  </span>
                </S.OrderId>
              </S.Order>
            )}
          </S.LocationContainer>
        </S.AdditionalInfo>
        <S.Location>
          {token ? <PadlockIcon /> : <CalendarIcon />}
          {token ? (
            <span>
              TOKEN: <strong>{token}</strong>
            </span>
          ) : (
            <span>{date}</span>
          )}
        </S.Location>
        {patientName && (
          <S.ParticipantsContainer>
            <S.Patient>
              <ProfilePic size="small" src={getRandomProfileImageUrl()} />
              <div style={{ marginLeft: '10px' }}>
                <S.ParticipantTitle>Paciente</S.ParticipantTitle>
                <span>{patientName}</span>
              </div>
            </S.Patient>
          </S.ParticipantsContainer>
        )}
        {doctorName && (
          <S.DoctorWrapper>
            <ProfilePic
              data-testid="profile pic"
              size="small"
              src={getRandomProfileImageUrl()}
            />
            <S.DoctorNameWrapper>
              <p id="title">Médico responsável</p>
              <p>{doctorName}</p>
            </S.DoctorNameWrapper>
          </S.DoctorWrapper>
        )}
      </S.Content>
    </S.Wrapper>
  )
}

export default ServiceOverview
