import React, { useEffect, useState } from 'react'
import AccompanyingLayout from 'presentation/patient/layouts/AccompanyingList'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { Accompanying } from 'domain/entities/accompanying-model'
import { DeleteAccompanying } from 'domain/usecases/accompanying/delete-accompanying'
import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'

type Props = {
  loadPatientInfo: LoadPatientInfo
  deleteAccompanying: DeleteAccompanying
  updateAccompanying: UpdateAccompanying
}

export default function Accompanyings({
  loadPatientInfo,
  deleteAccompanying,
  updateAccompanying
}: Props) {
  const [accompanyings, setAccompanyings] = useState([] as Accompanying[])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function loadData() {
      const response = await loadPatientInfo.load([
        'patient_id',
        'accompanists {name, lastName, accompanying_id, createdAt, email, phone, relationship, sendEmail, sendSMS, isMain}'
      ])
      setAccompanyings(response.accompanists ?? [])
      setLoading(false)
    }
    loadData()
  }, [])

  if (loading) return <></>

  return (
    <AccompanyingLayout
      updateAccompanying={updateAccompanying}
      companions={accompanyings}
      deleteAccompanying={deleteAccompanying}
    />
  )
}
