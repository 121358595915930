import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import { Signup } from 'domain/usecases/authentication/signup'

import 'main/config/yup'
import * as yup from 'yup'
import * as S from './styles'

import { cpfMask, phoneMask } from 'presentation/utils/masks'
import Button from 'presentation/shared/components/Button'
import TextField from 'presentation/shared/components/TextField'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import ActualPage from 'presentation/shared/components/ActualPage'
import TextButton from 'presentation/shared/components/TextButton'
import StepDots from 'presentation/shared/components/StepDots'
import SheetModal from 'presentation/shared/components/SheetModal/'
import { AddPatientFormValues } from 'presentation/patient/pages/Signup'
import PasswordField from 'presentation/shared/components/PasswordField'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import { EmailInUseError } from 'common/errors/user/email-in-use'
import { CpfInUseError, UserAlreadyExistsError } from 'common/errors'
import { toast } from 'react-toastify'
import SelectField from 'presentation/shared/components/SelectField'
import { Gender } from 'common/enum/gender'
import { maritalStatus } from 'presentation/utils/default-marital-status'
import normalizeText from 'common/utils/getNormalizedText'
import PatientTerms from 'presentation/assets/terms/patient-terms'
import TermsContainer from 'presentation/shared/components/TermsContainer'
import { PatientEmailNotEqualsToInMvError } from 'common/errors/user/patient-email-not-equal-mv'

type AddPatientFormProps = {
  useCase?: Signup
  initialValues?: AddPatientFormValues
  update?: boolean
}

export default function AddPatientForm({
  useCase,
  initialValues = {} as AddPatientFormValues,
  update
}: AddPatientFormProps) {
  const [swiper, updateSwiper] = useState({} as CarouselState)
  const [isCreated, setIsCreated] = useState(false)
  const history = useHistory()

  function goToLogin() {
    history.push('/login')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: update ? validatePatientSchema : addPatientSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await useCase?.signup({
          name: values.name,
          cpf: values.cpf.replace(/\D+/g, ''),
          email: values.email,
          password: values.password,
          phone: values.phone.replace(/\D+/g, ''),
          gender: values.gender,
          maritalStatus: values.maritalStatus
        })

        setIsCreated(true)
      } catch (err: any) {
        if (err.name === EmailInUseError.name) {
          swiper.slideTo(1)
          formik.setFieldError('email', err.message)
          return
        }
        if (err.name === CpfInUseError.name) {
          swiper.slideTo(0)
          formik.setFieldError('cpf', err.message)
          return
        }
        if (err.name === UserAlreadyExistsError.name) {
          toast.error('O usuário já existe')
          return
        }
        if (err.name === PatientEmailNotEqualsToInMvError.name) {
          toast.error(err.message, {
            autoClose: 60000
          })
          return
        }
        toast.error(err.message)
      }
    }
  })

  const isEnabled: Record<number, boolean> = {
    0:
      !!formik.errors.name ||
      !!formik.errors.cpf ||
      !!formik.errors.gender ||
      !!formik.errors.maritalStatus,
    1: !!formik.errors.phone || !!formik.errors.email,
    2: !!formik.errors.password || !!formik.errors.passwordConfirmation,
    3: !formik.isValid
  }

  return (
    <>
      <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
        <S.Controls>
          <ActualPage text="Voltar" onClick={swiper.slidePrev} />
          <TextButton
            onClick={() => history.goBack()}
            underline
            size="small"
            type="button"
          >
            Cancelar
          </TextButton>
        </S.Controls>
        <Carousel
          slidesPerView={1}
          touch={false}
          state={swiper}
          setState={updateSwiper}
          containerStyle={{ height: '100%' }}
        >
          <S.Step>
            <S.Content>
              <TextField
                label="Nome completo"
                name="name"
                value={formik.values.name}
                onInputChange={(val) =>
                  formik.handleChange('name')(normalizeText(val))
                }
                onBlur={formik.handleBlur('name')}
                error={formik.touched.name ? formik.errors.name : undefined}
                disabled={update}
                style={{
                  marginBottom: '24px'
                }}
              />
              <TextField
                label="CPF"
                name="cpf"
                defaultValue={formik.values.cpf}
                mask={cpfMask}
                onInputChange={formik.handleChange('cpf')}
                onBlur={formik.handleBlur('cpf')}
                error={formik.touched.cpf ? formik.errors.cpf : undefined}
                disabled={update}
                style={{
                  marginBottom: '24px'
                }}
              />
              <SelectField
                label="Sexo"
                name="gender"
                defaultValue={formik.values.gender ?? ''}
                items={[
                  {
                    label: 'Masculino',
                    value: Gender.MALE
                  },
                  {
                    label: 'Feminino',
                    value: Gender.FEMALE
                  }
                ]}
                onInputChange={formik.handleChange('gender')}
                onBlur={formik.handleBlur('gender')}
                error={formik.touched.gender ? formik.errors.gender : undefined}
                style={{
                  marginBottom: '24px'
                }}
              />
              <SelectField
                label="Estado civil"
                name="maritalStatus"
                defaultValue={formik.values.maritalStatus ?? ''}
                onInputChange={formik.handleChange('maritalStatus')}
                onBlur={formik.handleBlur('maritalStatus')}
                error={
                  formik.touched.maritalStatus
                    ? formik.errors.maritalStatus
                    : undefined
                }
                items={maritalStatus}
                required
              />
            </S.Content>
          </S.Step>
          <S.Step>
            <S.Content>
              <TextField
                label="Celular"
                name="phone"
                defaultValue={formik.values.phone}
                mask={phoneMask}
                onInputChange={formik.handleChange('phone')}
                onBlur={formik.handleBlur('phone')}
                error={formik.touched.phone ? formik.errors.phone : undefined}
                disabled={update && !!formik.initialValues.phone}
                style={{
                  marginBottom: '24px'
                }}
              />
              <TextField
                label="E-mail"
                name="email"
                defaultValue={formik.values.email}
                onInputChange={formik.handleChange('email')}
                disabled={update && !!formik.initialValues.email}
                onBlur={formik.handleBlur('email')}
                error={formik.touched.email ? formik.errors.email : undefined}
              />
            </S.Content>
          </S.Step>
          <S.Step>
            <S.Content>
              <PasswordField
                style={{
                  marginBottom: '24px'
                }}
                label="Crie uma senha:"
                name="password"
                value={formik.values.password}
                onInputChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                error={
                  formik.touched.password ? formik.errors.password : undefined
                }
              />
              <PasswordField
                label="Confirme sua senha:"
                name="passwordConfirmation"
                value={formik.values.passwordConfirmation}
                onInputChange={formik.handleChange('passwordConfirmation')}
                onBlur={formik.handleBlur('passwordConfirmation')}
                error={
                  formik.touched.passwordConfirmation
                    ? formik.errors.passwordConfirmation
                    : undefined
                }
              />
            </S.Content>
          </S.Step>
          <S.Step>
            <Heading size="large" style={{ marginTop: '20px' }}>
              Termo de Uso Geral e Política de Privacidade da Plataforma
            </Heading>
            <TermsContainer
              onChange={(val) => formik.setFieldValue('terms', val)}
            >
              <PatientTerms />
            </TermsContainer>
          </S.Step>
        </Carousel>
        <S.Footer>
          <StepDots total={4} active={swiper.activeIndex || 0} />
          <Button
            data-testid={swiper.activeIndex === 3 ? 'submit' : 'button'}
            disabled={isEnabled[swiper.activeIndex]}
            fullWidth
            type={swiper.activeIndex === 3 ? 'submit' : 'button'}
            onClick={
              swiper.activeIndex === 3 ? formik.submitForm : swiper.slideNext
            }
          >
            Prosseguir
          </Button>
        </S.Footer>
        <SheetModal
          isOpen={isCreated}
          onClose={goToLogin}
          isDraggable={false}
          size={250}
        >
          <S.ConfirmationModal>
            <Heading>
              Cadastro efetuado com <strong>sucesso</strong>
            </Heading>
            <SupportText>
              Agora você pode utilizar todos os recursos do{' '}
              <strong>Jornada Cirúrgica</strong>
            </SupportText>
            <Button fullWidth onClick={goToLogin}>
              Fazer Login
            </Button>
          </S.ConfirmationModal>
        </SheetModal>
      </S.Wrapper>
    </>
  )
}

const schema = {
  name: yup.string().required(),
  cpf: yup.string().cpf().required(),
  gender: yup.string().required(),
  maritalStatus: yup.string().required(),
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação diferente da senha')
    .required(),
  terms: yup.boolean().oneOf([true]).required()
}

const addPatientSchema = yup.object().shape({
  ...schema,
  email: yup.string().email().required(),
  phone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required()
})

const validatePatientSchema = yup.object().shape(schema)
