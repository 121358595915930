import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent
} from 'styled-components'

const GlobalStyles: GlobalStyleComponent<any, DefaultTheme> = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid #f4f0fa;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

    html {
      height: 100%;
      min-height: 100%;
      background-color: ${({ theme }) => theme.colors.lightestGray};
    }

    body {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      background-color: ${({ theme }) => theme.colors.rootBg};
    }

    #root {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      min-height: 100%;
      position: relative;
      max-height: 100%;
      font-family: ${({ theme }) => theme.font.family};
      font-size: ${({ theme }) => theme.font.sizes.small};
      background-color: ${({ theme }) => theme.colors.mainBg};

      @media (min-width: 800px) {
        ${({ mobile }) =>
          mobile &&
          `
          width: 50rem;
          margin: 0 auto;
        `}
      }
    }
    .Toastify__toast--success {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff !important;
    }

  .swiper-container {
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .swiper-wrapper {
    width: 100%;
    height: 100% !important;
    z-index: 0;
  }

  .swiper-slide {
    height: 100% !important;
    z-index: 0;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1366px) {
    html {
      font-size: 14px;
    }
  }
`

export default GlobalStyles
