import styled, { css } from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  flex: 1;
  max-width: 20rem;
`

export const Content = styled.div`
  flex: 1 0;
`

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
`
export const PasswordRecoveryModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  p {
    margin-top: 12px;
    margin-bottom: 24px;
  }
`

export const PasswordRecoveryItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 18px;
    width: 100%;
    cursor: pointer;
    border-radius: ${theme.border.radius.small};
    background-color: ${theme.colors.mainBg};

    span {
      margin-left: 24px;
      color: ${theme.colors.gray};
      font-weight: 600;
    }

    p {
      margin-top: 12px;
      margin-bottom: 24px;
    }

    svg {
      width: 48px;
      height: 48px;
    }

    & + & {
      margin-top: 24px;
    }
  `}
`
