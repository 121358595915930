import { IntegrationStatusModel } from 'domain/entities/integration-surgical-status-model'
import { GetAllStatus } from 'domain/usecases/surgical-status/get-all-surgical-status'
import { makeAutoObservable } from 'mobx'

export class SurgicalStatusContext {
  constructor(private readonly getAllStatus: GetAllStatus) {
    makeAutoObservable(this)
  }

  status: IntegrationStatusModel[] = []

  getStatus() {
    return this.status
  }

  setStatus(status: IntegrationStatusModel[]) {
    return (this.status = status)
  }

  async load() {
    try {
      const status = await this.getAllStatus.get()
      const sortedStatus = status?.sort((a, b) => {
        if (a.isFirstStatus && !b.isFirstStatus) return -1
        if (!a.isFirstStatus && b.isFirstStatus) return 1
        if (a.isEndStatus && !b.isEndStatus) return 1
        if (!a.isEndStatus && b.isEndStatus) return -1
        return a.integration_status_id - b.integration_status_id
      })
      this.setStatus(sortedStatus)
    } catch (error: any) {
      return error.message
    }
  }
}

export default SurgicalStatusContext
