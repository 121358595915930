import React from 'react'

import BottomMenu from 'presentation/shared/components/BottomMenu'
import Header from 'presentation/shared/components/Header'
import ActualPage from 'presentation/shared/components/ActualPage'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import ServiceOverview, {
  ServiceOverviewProps
} from 'presentation/shared/components/ServiceOverview'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

type Props = {
  surgicalOrders: ServiceOverviewProps[]
}

export default function AttendancesLayout({ surgicalOrders }: Props) {
  const history = useHistory()

  return (
    <>
      <Header />
      <Container>
        <ActualPage text="Voltar" />
        <Heading style={{ margin: '24px 0' }}>Meus atendimentos</Heading>
        {surgicalOrders.map((surgicalOrder) => {
          return (
            <ServiceOverview
              {...surgicalOrder}
              date={
                surgicalOrder.date
                  ? moment(surgicalOrder.date)
                      .utc(true)
                      .format('DD/MM/YYYY - HH:mm')
                  : '-'
              }
              key={surgicalOrder.surgical_order_id}
              title={surgicalOrder?.title}
              type="Cirurgia"
              style={{ marginTop: '24px' }}
              onClick={() =>
                history.push('/cirurgia', {
                  id: surgicalOrder.surgical_order_id
                })
              }
            />
          )
        })}
      </Container>
      <BottomMenu />
    </>
  )
}
