import React from 'react'
import Checkbox from '../Checkbox'
import ProfilePic from '../ProfilePic'
import * as S from './styles'
import moment from 'moment-timezone'
import { Menu, MenuItem } from '@material-ui/core'
import ThreeDots from 'presentation/assets/icons/three-dots.svg'

export type SelectableListItem = {
  name: string
  createdAt?: Date
  image?: string
  id: number
}

type ActionProps = {
  label: string
  handle: (itemId: any) => void
}

type Props = {
  actions?: ActionProps[]
  items?: SelectableListItem[]
  itemClick?: (item: any) => void
  removeById?: (itemId: any) => void
  set?: (item: any) => void
  selectedItems: number[]
  setSelectedItems: (items: number[]) => void
}

const SelectableList = ({
  actions,
  items,
  itemClick,
  selectedItems,
  setSelectedItems
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const check = (id: number) => {
    const findItem = selectedItems.find((item) => item === id)
    if (findItem !== null && findItem !== undefined) {
      setSelectedItems(selectedItems.filter((item) => item !== id))
    } else {
      setSelectedItems([...selectedItems, id])
    }
  }

  const click = (id: number) => {
    itemClick && itemClick(id)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <S.Wrapper>
      {items?.map((item) => (
        <S.ListItem key={item.id}>
          <div>
            <Checkbox onCheck={() => check(item.id)} />
          </div>
          <S.AccompanyingInfo onClick={() => click(item.id)}>
            {!!item.image && <ProfilePic src={item.image} />}
            <div>
              <p>{item.name}</p>Inserido em{' '}
              {moment(item.createdAt)
                .tz('America/Sao_Paulo')
                .locale('pt-br')
                .format('DD MMM [de] YYYY')}
            </div>
          </S.AccompanyingInfo>

          <div>
            <S.MenuButton type="button" onClick={handleClick}>
              <img className="img" src={ThreeDots} alt="Trash" />
            </S.MenuButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => click(item.id)}>Visualizar</MenuItem>
              {actions &&
                actions.map(({ handle, label }, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handle({ itemId: item.id })}
                  >
                    {label}
                  </MenuItem>
                ))}
            </Menu>
          </div>
        </S.ListItem>
      ))}
    </S.Wrapper>
  )
}

export default SelectableList
