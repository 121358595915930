import React from 'react'

import { useHistory } from 'react-router-dom'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Heading from 'presentation/shared/components/Heading'

import * as S from '../styles'

type BloodBankReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function BloodBankReview({
  surgicalOrder,
  visualizationMode
}: BloodBankReviewProps) {
  const history = useHistory()

  return (
    <>
      <S.ReviewHeader>
        <Heading weight={500}>Banco de Sangue</Heading>
        {!visualizationMode && (
          <small
            onClick={() =>
              history.push('/pedido-cirurgico/banco-de-sangue', {
                surgical_order_id: surgicalOrder.surgical_order_id,
                surgicalOrder
              })
            }
          >
            Editar
          </small>
        )}
      </S.ReviewHeader>
      <S.InfoColumnContainer>
        <h6>Tipagem sanguínea</h6>
        <span>{surgicalOrder.hasBloodType ? 'Sim' : 'Não'}</span>
        <h6>Hemoconcentrados</h6>
        <span>{surgicalOrder.hasHemoconcentrates ? 'Sim' : 'Não'}</span>
        <h6>Cell Saver</h6>
        <span>{surgicalOrder.cellSaverSpec ? 'Sim' : 'Não'}</span>
        <h6>Especificação</h6>
        <span>{surgicalOrder.cellSaverSpec || '-'}</span>
        <h6>Quantidade</h6>
        <span>{surgicalOrder.bloodBagQuantity ?? '-'}</span>
      </S.InfoColumnContainer>
    </>
  )
}
