import styled, { css, DefaultTheme } from 'styled-components'

import { ProfilePicProps } from '.'

export const Wrapper = styled.div<Pick<ProfilePicProps, 'upload'>>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, upload }) =>
    upload &&
    css`
      > img {
        border: 4px solid transparent;
        cursor: pointer;

        transition: border-color 0.2s;
        &:hover {
          border-color: ${theme.colors.lightGreen};
        }
      }
    `}

  div {
    display: flex;
    flex-direction: column;
  }
  label {
    background: transparent;
    position: relative;
    border: none;
    bottom: 30px;
    left: 70%;
    cursor: pointer;

    input {
      display: none;
    }
  }
`

export const Image = styled.img<ProfilePicProps>`
  border-radius: 50%;
  border: 1px solid #cdcdcd;
  ${({ size, outlined, theme }) => css`
    ${!!size && wrapperModifiers[size]}
    ${!!outlined && wrapperModifiers.outlined(theme, size)}
  `}
`

const wrapperModifiers = {
  large: () => css`
    width: 83px;
    height: 83px;
  `,

  xl: () => css`
    width: 166px;
    height: 166px;
  `,

  medium: () => css`
    width: 48px;
    height: 48px;
  `,

  small: () => css`
    width: 39px;
    height: 39px;
  `,

  outlined: (theme: DefaultTheme, size: ProfilePicProps['size']) => css`
    padding: ${paddingsBySize[size!]};
    border: 1px solid ${theme.colors.primary};
  `
}

const paddingsBySize = {
  large: '4px',
  medium: '2px',
  small: '1px',
  xl: '8px'
}
