import styled from 'styled-components'
import { ErrorProps } from 'presentation/shared/components/Error/index'

export const ErrorSpan = styled.span<ErrorProps>`
  font-size: ${({ size, theme }) => theme.font.sizes[size]};
  color: ${({ color, theme }) => theme.colors[color]};
  font-weight: 600;
  text-align: center;
  padding-top: 36px;
`
