import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteUpdateSurgicalOrder implements UpdateSurgicalOrder {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  update(
    params: UpdateSurgicalOrder.Params
  ): Promise<UpdateSurgicalOrder.Model> {
    return this.surgicalOrderRepository.updateSurgicalOrder(params)
  }
}
