export enum Gender {
  MALE = 'Masculino',
  FEMALE = 'Feminino'
}

export const gender = [
  {
    label: 'Masculino',
    value: Gender.MALE
  },
  { label: 'Feminino', value: Gender.FEMALE }
]
