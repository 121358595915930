import { GetAgendaClassification } from 'domain/usecases/agenda/get-agenda-classification'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteGetAgendaClassification implements GetAgendaClassification {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  get(
    params: GetAgendaClassification.Params
  ): Promise<GetAgendaClassification.Model> {
    return this.surgicalOrderRepository.getAgendaClassifications(params)
  }
}
