import React, { useState } from 'react'
import * as S from './styles'

import DeleteIcon from 'presentation/assets/icons/trash-outline-purple.svg'
import RefreshIcon from 'presentation/assets/icons/refresh-white.svg'
import NoImage from 'presentation/assets/images/no-image.jpg'
import ArrowDowIcon from 'presentation/assets/icons/arrow-down-purple.svg'
import FileViewer from 'presentation/shared/components/FileViewer'
import EyeIcon from 'presentation/assets/icons/eye-purple.svg'
import { getThumbnail } from '../../Functions'

export type UploadFileCanProps = {
  canDownload?: boolean
  canUpdate?: boolean
  canDelete?: boolean
  canPreview?: boolean
}

export type ListFile<IdentifierType = unknown> = {
  file: File
  identifier?: IdentifierType
  page?: number
  fileCan?: UploadFileCanProps
  thumbnail?: string
}

type UploadListFileProps<ListFileType = unknown> = {
  listFile: ListFile<ListFileType>
  allCan: UploadFileCanProps
  onUpdate?: (
    listFile: ListFile<ListFileType>,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  onDelete?: (listFile: ListFile<ListFileType>) => void
  onDownload?: (listFile: ListFile<ListFileType>) => void

  acceptFiles?: string
}

export function UploadListFile<ListFileType = unknown>({
  listFile,
  allCan,
  onUpdate,
  onDelete,
  onDownload,
  acceptFiles = 'image/*,application/pdf'
}: UploadListFileProps<ListFileType>) {
  const can = {
    canPreview: true,
    ...allCan,
    ...listFile.fileCan
  }
  const file = listFile.file
  const [showPreview, setShowPreview] = useState<boolean>(false)

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0]
    if (!newFile) return
    const thumbnail = await getThumbnail(newFile)
    onUpdate?.(
      {
        ...listFile,
        file: newFile,
        thumbnail
      },
      event
    )
  }

  return (
    <S.ListFileContainer>
      <S.ListFileThumbnail thumbnailImage={listFile.thumbnail || NoImage}>
        {can.canUpdate && (
          <section>
            <img src={RefreshIcon} />
            <label>Clique aqui para alterar</label>
          </section>
        )}
        <p>{file.name}</p>
        {can.canUpdate && (
          <input
            type="file"
            name={file.name}
            id={file.name}
            onChange={onChange}
            accept={acceptFiles}
          />
        )}
      </S.ListFileThumbnail>
      <S.ListFileActions>
        {can.canDelete && (
          <S.ListFileActionButton
            type="button"
            onClick={() => onDelete?.(listFile)}
          >
            <img src={DeleteIcon} alt="Ícone de excluir de arquivo" />
            <label>Excluir</label>
          </S.ListFileActionButton>
        )}
        {can.canDownload && (
          <S.ListFileActionButton
            type="button"
            onClick={() => onDownload?.(listFile)}
          >
            <img src={ArrowDowIcon} alt="Ícone de download de arquivo" />
            <label>Download</label>
          </S.ListFileActionButton>
        )}
        {can.canPreview && (
          <S.ListFileActionButton
            type="button"
            onClick={() => setShowPreview(true)}
          >
            <img src={EyeIcon} alt="Ícone de visualizar de arquivo" />
            <label>Visualizar</label>
          </S.ListFileActionButton>
        )}
      </S.ListFileActions>
      <FileViewer
        file={{
          contentType: file.type,
          fileUrl: URL.createObjectURL(file)
        }}
        show={showPreview}
        onClose={() => setShowPreview(false)}
      />
    </S.ListFileContainer>
  )
}
