import styled from 'styled-components'

export type DividerProps = {
  margin?: string
}

const Divider = styled.div<DividerProps>`
  height: 1px;
  background-color: #c2c2c2;
  margin-top: ${(props) => props.margin || '16px'};
  margin-bottom: ${(props) => props.margin || '16px'};
`

export default Divider
