import React, { useState } from 'react'

import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import * as yup from 'yup'

import TextField from 'presentation/shared/components/TextField'
import Checkbox from 'presentation/shared/components/Checkbox'
import Button from 'presentation/shared/components/Button'
import ActualPage from 'presentation/shared/components/ActualPage'
import * as S from './styles'
import 'main/config/yup'
import Modal from 'presentation/shared/components/Modal'
import { phoneMask } from 'presentation/utils/masks'
import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'
import { toast } from 'react-toastify'

type Props = {
  method: 'update' | 'create'
  addAccompanying?: AddAccompanying
  updateAccompanying?: UpdateAccompanying
  initialValues?: AccompanyingFormValues
}

export default function AccompanyingForm({
  method,
  updateAccompanying,
  addAccompanying,
  initialValues = {} as AccompanyingFormValues
}: Props) {
  const history = useHistory()
  const [validateNotification, setValidateNotification] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const handleForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (!values.sendEmail && !values.sendSMS && !validateNotification) {
        return setConfirmationModal(true)
      } else {
        try {
          const requestObject = {
            name: values.name,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            sendSMS: !!values.sendSMS,
            sendEmail: !!values.sendEmail,
            isMain: !!values.isMain,
            relationship: values.relationship
          }
          if (method === 'create') {
            await addAccompanying?.add(requestObject)
          } else if (method === 'update') {
            await updateAccompanying?.update({
              ...requestObject,
              accompanying_id: values.accompanying_id
            })
          }
          setSuccessModal(true)
        } catch (error) {
          toast.error(error.message)
        }

        setValidateNotification(false)
      }
    }
  })

  const continueWithoutNotification = () => {
    setValidateNotification(true)
    handleForm.submitForm()
  }

  return (
    <S.Wrapper onSubmit={handleForm.handleSubmit} role="form">
      <S.Header>
        <ActualPage text="Acompanhante" onClick={history.goBack} />
      </S.Header>
      <S.Content>
        <TextField
          label="Nome"
          placeholder="Seu nome"
          name="name"
          onInputChange={handleForm.handleChange('name')}
          onBlur={handleForm.handleBlur('name')}
          error={handleForm.touched.name && handleForm.errors.name}
          value={handleForm.values?.name}
          required
        />
        <TextField
          label="Sobrenome"
          placeholder="Seu sobrenome"
          name="lastName"
          style={{ marginTop: '20px' }}
          onInputChange={handleForm.handleChange('lastName')}
          onBlur={handleForm.handleBlur('lastName')}
          value={handleForm.values?.lastName}
          error={handleForm.touched.lastName && handleForm.errors.lastName}
        />
        <TextField
          label="Parentesco"
          placeholder="Mãe"
          name="relationship"
          style={{ marginTop: '20px' }}
          onInputChange={handleForm.handleChange('relationship')}
          onBlur={handleForm.handleBlur('relationship')}
          value={handleForm.values?.relationship}
          error={
            handleForm.touched.relationship && handleForm.errors.relationship
          }
        />
        <TextField
          label="E-mail"
          placeholder="seuemail@gmail.com"
          style={{ marginTop: '20px' }}
          name="email"
          onInputChange={handleForm.handleChange('email')}
          onBlur={handleForm.handleBlur('email')}
          value={handleForm.values?.email}
          error={handleForm.touched.email && handleForm.errors.email}
          required
        />
        <TextField
          label="Celular"
          placeholder="+55 (xx) xxxxxxxxx"
          style={{ marginTop: '20px' }}
          mask={phoneMask}
          name="phone"
          onInputChange={handleForm.handleChange('phone')}
          onBlur={handleForm.handleBlur('phone')}
          error={handleForm.touched.phone && handleForm.errors.phone}
          initialValue={handleForm.values?.phone}
          value={handleForm.values?.phone}
          required
        />
        <S.NotificationType>
          <p>Notificações via:</p>
          <div>
            <Checkbox
              onCheck={() =>
                handleForm.setFieldValue(
                  'sendEmail',
                  !handleForm.values.sendEmail
                )
              }
              label="E-mail"
              id="Email"
              labelFor="Email"
              checked={handleForm.values?.sendEmail}
            />
            <Checkbox
              onCheck={() =>
                handleForm.setFieldValue('sendSMS', !handleForm.values.sendSMS)
              }
              label="SMS"
              id="sms"
              labelFor="sms"
              checked={handleForm.values?.sendSMS}
            />
          </div>
        </S.NotificationType>
        <S.NotificationType>
          <p>Deseja que o acompanhante seja o principal?</p>
          <Checkbox
            onCheck={() =>
              handleForm.setFieldValue('isMain', !handleForm.values.isMain)
            }
            label="Acompanhante principal"
            id="isMain"
            labelFor="isMain"
            checked={handleForm.values?.isMain}
          />
        </S.NotificationType>
      </S.Content>
      <S.Buttons>
        <Button type="submit" disabled={!handleForm.isValid} fullWidth>
          {method === 'create' ? 'Adicionar ' : 'Atualizar '}
          acompanhante
        </Button>
      </S.Buttons>
      <Modal show={confirmationModal}>
        <S.ConfirmationModal>
          <span>Deseja continuar sem selecionar uma forma de notificação?</span>
          <div>
            <Button type="button" onClick={() => setConfirmationModal(false)}>
              Não
            </Button>
            <Button type="button" onClick={continueWithoutNotification}>
              Sim
            </Button>
          </div>
        </S.ConfirmationModal>
      </Modal>
      <Modal
        show={successModal}
        title={`Acompanhante ${
          method === 'create' ? 'adicionado' : 'alterado'
        } com sucesso :)!`}
        description="Agora seu acompanhante vai poder estar com você ao longo de toda a sua jornada cirurgica"
        close={() => history.push('/acompanhantes')}
      />
    </S.Wrapper>
  )
}

export type AccompanyingFormValues = {
  name?: string
  lastName?: string
  relationship?: string
  email?: string
  phone?: string
  sendEmail?: boolean
  sendSMS?: boolean
  accompanying_id?: number
  isMain?: boolean
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().optional(),
  relationship: yup.string().optional(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .test('masked-phone-validation', 'Telefone inválido', function (val) {
      return !!(
        val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
        val?.match(/^[0-9]{13}$/)
      )
    })
    .required(),
  sendEmail: yup.boolean().optional(),
  sendSMS: yup.boolean().optional(),
  isMain: yup.boolean().optional()
})
