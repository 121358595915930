import React from 'react'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import ActualPage from 'presentation/shared/components/ActualPage'

import * as S from './styled'
import { useHistory } from 'react-router'
import Heading from 'presentation/shared/components/Heading'
import Button from 'presentation/shared/components/Button'
import { orientationSteps } from './components'

export const SurgicalJourneyOrientations = () => {
  const [startNavigation, setStartNavigation] = React.useState(false)
  const [carousel, setCarousel] = React.useState({} as CarouselState)
  const history = useHistory()

  return (
    <>
      <Header />
      <Container hasHeader>
        <ActualPage text="Voltar" onClick={history.goBack} />
        <S.Wrapper>
          {!startNavigation ? (
            <S.Main>
              <Heading
                as="h1"
                size="large"
                color="primary"
                style={{ marginBottom: '12px' }}
              >
                Minha cirurgia
              </Heading>
              <span>Conheça as etapas para a sua cirurgia</span>
            </S.Main>
          ) : (
            <Carousel
              state={carousel}
              setState={setCarousel}
              slidesPerView={1}
              spaceBetween={20}
            >
              {orientationSteps(carousel)}
            </Carousel>
          )}

          {!startNavigation && (
            <Button onClick={() => setStartNavigation(true)}>Iniciar</Button>
          )}
        </S.Wrapper>
      </Container>
      <BottomMenu />
    </>
  )
}
