import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 14px;
      cursor: pointer;
    }
  }
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.border.radius.small};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
`

export const MedicalTeamRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  gap: 10px;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.normal};
  }

  label {
    color: ${({ theme }) => theme.colors.mediumLightGray};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }
`

export const AnesthetistTeamRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  gap: 10px;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.normal};
  }

  div {
    display: flex;
    margin-right: 20px;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.colors.mediumGray};
    gap: 10px;
    div {
      display: flex;
      flex-direction: column;
      gap: 0px;

      p {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`
