import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import MedicineAndDiseases from 'presentation/patient/pages/MedicinesAndDiseases'
import { RemoteUpdateMedicineAndDiseases } from 'service/usecases/update-medicine-and-diseases/remote-update-medicine-and-diseases'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export default function makePatientMedicineAndDiseases() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)
  const updateMedicineAndDiseases = new RemoteUpdateMedicineAndDiseases(
    patientRepository
  )
  return (
    <MedicineAndDiseases
      updateMedicineAndDiseases={updateMedicineAndDiseases}
    />
  )
}
