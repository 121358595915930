import { SurgicalPendencyRepository as Repository } from 'repository/interfaces/surgical-pendency-repository'
import { AddSurgicalPendency } from 'domain/usecases/surgical-pendency/add-surgical-pendency'
import { IApiRepository } from 'service/protocols/api/api-repository'
import {
  answerSurgicalPendency,
  createSurgicalPendency,
  finishSurgicalPendency
} from 'repository/graphql/mutations'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { LoadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/load-surgical-pendency-document'
import { FinishSurgicalPendency } from 'domain/usecases/surgical-pendency/finish-surgical-pendency'

export class SurgicalPendencyRepository implements Repository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addSurgicalPendency(
    params: AddSurgicalPendency.Params
  ): Promise<AddSurgicalPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddSurgicalPendency.Model>

    const query = createSurgicalPendency

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddSurgicalPendency.Model
    }
  }

  async answerSurgicalPendency({
    group_id,
    ...data
  }: AnswerSurgicalPendency.Params): Promise<AnswerSurgicalPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AnswerSurgicalPendency.Model>

    const query = answerSurgicalPendency
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(data, 'data'),
          ...makeGraphQLVariable(group_id, 'group_id')
        }
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AnswerSurgicalPendency.Model
    }
  }

  async uploadSurgicalPendencyDocument(
    params: UploadSurgicalPendencyDocument.Params
  ): Promise<UploadSurgicalPendencyDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadSurgicalPendencyDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/surgical-pendency/upload-attachment',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadSurgicalPendencyDocument.Model
    }
  }

  async loadSurgicalPendencyDocument(
    surgical_order_id: number,
    document_id: number,
    group_id?: string
  ): Promise<LoadSurgicalPendencyDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalPendencyDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/surgical-pendency/${surgical_order_id}/attachment/${document_id}${
          group_id ? `?group_id=${group_id}` : ''
        }`
      },
      true
    )

    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    return {
      contentType,
      data
    }
  }

  async finishSurgicalPendency(
    params: FinishSurgicalPendency.Params
  ): Promise<FinishSurgicalPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishSurgicalPendency.Model>

    const query = finishSurgicalPendency

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishSurgicalPendency.Model
    }
  }
}
