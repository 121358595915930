import React from 'react'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteUpdateSurgicalOrder } from 'service/usecases/update-surgical-order/remote-update-surgical-order'
import ReviewSurgicalOrder from 'presentation/doctor/pages/AddSurgicalOrder/ReviewSurgicalOrder'
import { RemoteLoadSurgicalOrderDocument } from 'service/usecases/load-surgical-order-document/load-upload-surgical-order-document'
import { RemoteLoadSurgeryCenter } from 'service/usecases/load-surgery-center/remote-load-surgery-center'
import { SurgeryCenterRepository } from 'repository/repositories/surgery-center/surgery-center-repository'

export default function makePatientReviewSurgicalOrder() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const surgeryCenterRepository = new SurgeryCenterRepository(apiRepository)
  const updateSurgicalOrder = new RemoteUpdateSurgicalOrder(
    surgicalOrderRepository
  )
  const loadSurgicalOrderDocument = new RemoteLoadSurgicalOrderDocument(
    surgicalOrderRepository
  )
  const loadSurgicalCenter = new RemoteLoadSurgeryCenter(
    surgeryCenterRepository
  )
  return (
    <ReviewSurgicalOrder
      updateSurgicalOrder={updateSurgicalOrder}
      loadSurgicalOrderDocument={loadSurgicalOrderDocument}
      loadSurgicalCenter={loadSurgicalCenter}
    />
  )
}
