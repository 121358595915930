import React, { InputHTMLAttributes } from 'react'
import MaskedInput from 'react-text-mask'
import * as S from './styles'
import { DefaultTheme } from 'styled-components'
import theme from 'presentation/styles/theme'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar-v2.svg'

export type TextFieldProps = {
  onInputChange?: (value: string) => void
  label?: string
  mask?: (string | RegExp)[] | ((value: string) => (string | RegExp)[])
  initialValue?: string | number | undefined
  labelIcon?: React.ReactNode
  onLabelIconClick?: () => void
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  iconLocale?: 'inside' | 'outside'
  onIconClick?: (param?: any) => void
  disabled?: boolean
  error?: string | false
  bgColor?: 'mainBg' | 'white' | 'lightestGray'
  required?: boolean
  iconMargin?: string
  outline?: boolean
  outlineColor?: 'primary' | 'gray'
  labelSize?: keyof DefaultTheme['font']['sizes']
  labelColor?: keyof typeof theme.colors
  guide?: boolean
  onResetTableValue?: () => any
  regex?: RegExp
  iconDisabled?: boolean
  inputSize?: string
  showCalendarIcon?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const TextField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    label,
    name,
    mask,
    error,
    disabled = false,
    onInputChange,
    style,
    bgColor = 'white',
    icon,
    iconPosition,
    onIconClick,
    required = false,
    iconLocale,
    iconMargin,
    labelColor,
    labelSize = 'small',
    onResetTableValue,
    labelIcon,
    onLabelIconClick,
    guide,
    regex,
    iconDisabled = false,
    inputSize = '100%',
    showCalendarIcon = false,
    ...props
  }: TextFieldProps,
  ref
) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    if (onResetTableValue && newValue === '') onResetTableValue()

    if (newValue && regex && !regex.test(newValue)) {
      return
    }

    !!onInputChange && onInputChange(newValue)
    !!props.onChange && props.onChange(e)
  }

  const iconClick = () => {
    !!onIconClick && onIconClick()
  }

  const labelIconClick = () => {
    !!onLabelIconClick && onLabelIconClick()
  }

  return (
    <S.Wrapper
      style={style}
      disabled={disabled}
      error={!!error}
      type={props.type}
    >
      {(!!label || !!labelIcon) && (
        <S.LabelWrapper>
          {!!label && (
            <S.Label
              htmlFor={name}
              labelColor={labelColor}
              labelSize={labelSize}
            >
              {label}
              {required ? <S.Asterisk>*</S.Asterisk> : ''}
            </S.Label>
          )}
          {!!labelIcon && (
            <S.LabelIcon onClick={labelIconClick}>{labelIcon}</S.LabelIcon>
          )}
        </S.LabelWrapper>
      )}
      <S.InputWrapper showCalendarIcon={showCalendarIcon}>
        {(props.type === 'date' || props.type === 'datetime-local') &&
          showCalendarIcon && (
            <S.CalendarIcon>
              <CalendarIcon />
            </S.CalendarIcon>
          )}
        {!!icon && (
          <S.Icon
            id="textfield-icon"
            onClick={iconClick}
            iconPosition={iconPosition}
            iconLocale={iconLocale}
            iconMargin={iconMargin}
            disabled={iconDisabled}
          >
            {icon}
          </S.Icon>
        )}
        {mask ? (
          <MaskedInput
            type="text"
            mask={mask}
            guide={guide ?? true}
            // value={value}
            disabled={disabled}
            name={name}
            {...props}
            onChange={onChange}
            render={(ref, props) => (
              <S.Input
                ref={(input) => ref(input!)}
                bgColor={bgColor}
                inputSize={inputSize}
                {...(label ? { id: name } : {})}
                {...props}
              />
            )}
          />
        ) : (
          <S.Input
            type="text"
            disabled={disabled}
            name={name}
            bgColor={bgColor}
            ref={ref}
            inputSize={inputSize}
            iconPosition={iconPosition}
            {...(label ? { id: name } : {})}
            {...props}
            onChange={onChange}
          />
        )}
      </S.InputWrapper>
      {!!error && <S.Error className="error">{error}</S.Error>}
    </S.Wrapper>
  )
}

export default React.forwardRef(TextField)
