import React from 'react'

import { useHistory } from 'react-router-dom'

import AlertIcon from 'presentation/assets/icons/alert.svg'
import CheckIcon from 'presentation/assets/icons/check-icon.svg'

import Header from 'presentation/shared/components/Header'
import ActualPage from 'presentation/shared/components/ActualPage'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import Button from 'presentation/shared/components/Button'
import List, { ListItem } from 'presentation/shared/components/List'
import { ItemText } from 'presentation/shared/components/List/styles'
import { useStores } from 'presentation/hooks/use-stores'
import { PatientDocument } from 'common/enum/patient-document'

import * as S from './styles'
import theme from 'presentation/styles/theme'

export const AddDocumentsForm = () => {
  const history = useHistory()

  const filesContext = useStores().file

  const getStatusIconByType = (type: PatientDocument) => {
    const files = filesContext.listFiles?.filter(
      (file) => file.identifier === type
    )
    return files && files.length > 0 ? CheckIcon : AlertIcon
  }

  const documentItems: ListItem[] = [
    {
      title: (
        <ItemText>
          Pedido Cirúrgico
          <strong style={{ color: theme.colors.alert }}>*</strong>
        </ItemText>
      ),
      icon: getStatusIconByType(PatientDocument.SURGICAL_ORDER),
      click: () =>
        history.push('/pedido-cirurgico/save/documentos/anexo', {
          type: 'order'
        })
    },
    {
      title: <ItemText>Relatório Médico</ItemText>,
      icon: getStatusIconByType(PatientDocument.MEDICAL_REPORT),
      click: () =>
        history.push('/pedido-cirurgico/save/documentos/anexo', {
          type: 'medical_report'
        })
    },
    {
      title: <ItemText>Laudos</ItemText>,
      icon: getStatusIconByType(PatientDocument.EXAM_REPORT),
      click: () =>
        history.push('/pedido-cirurgico/save/documentos/anexo', {
          type: 'medical_exams'
        })
    }
  ]

  const completeAttachment = () =>
    filesContext.listFiles?.some(
      (file) => file.identifier === PatientDocument.SURGICAL_ORDER
    ) || false

  return (
    <>
      <Header />
      <Container
        hasHeader
        primaryButton={
          <Button
            fullWidth
            disabled={!completeAttachment()}
            onClick={() => history.goBack()}
            data-testid="btn-send"
          >
            Enviar
          </Button>
        }
        secondaryButton={
          <Button
            fullWidth
            variant="outlined"
            data-testid="btn-cancel"
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
        }
      >
        <S.Wrapper>
          <ActualPage
            data-testid="btn-go-back"
            onClick={() => history.goBack()}
            text="Voltar"
          />
          <Heading size="large" as="h1" color="primary">
            Documentos
          </Heading>
          <SupportText color="lightGray">
            Você possui <strong>documentos</strong> que comprove necessidade da
            cirurgia?
          </SupportText>
          <S.DocumentsList data-testid="list-documents">
            <List items={documentItems} size={'small'} />
          </S.DocumentsList>
        </S.Wrapper>
      </Container>
    </>
  )
}
