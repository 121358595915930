import React from 'react'
import * as S from './styles'

import List, { ListItem } from '../List'
import Heading from '../Heading'
import SupportText from '../SupportText'

type SurgeryDocumentListProps = {
  documents: ListItem[]
  supportText?: boolean
  title?: boolean
  padding?: boolean
}

export default function SurgeryDocumentList({
  documents,
  supportText = true,
  title = true,
  padding = true
}: SurgeryDocumentListProps) {
  return (
    <S.Wrapper>
      {supportText && (
        <SupportText style={{ textAlign: 'center', fontStyle: 'italic' }}>
          Faça a gestão dos documentos dos procedimentos
        </SupportText>
      )}
      {title && <Heading as="h2">Documentos</Heading>}
      <S.List>
        <List padding={padding} size="medium" items={documents} />
      </S.List>
    </S.Wrapper>
  )
}
