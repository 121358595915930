import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { ApiRepository } from 'repository/api-repository'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import ChangeSurgicalOrderPersonalInfo from 'presentation/patient/pages/CreateSurgicalOrder/UpdatePersonalInfo'
import { RemotePatientUpdateInfo } from 'service/usecases/patient-update-info/remote-patient-update-info'

export default function makePatientSaveSurgicalOrderPersonalInfo() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const patientApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const patientRepository = new PatientRepository(patientApiRepository)
  const loadPatientInfo = new RemoteLoadPatientInfo(patientRepository)
  const useCase = new RemotePatientUpdateInfo(patientRepository)

  return (
    <ChangeSurgicalOrderPersonalInfo
      loadPatientInfo={loadPatientInfo}
      useCase={useCase}
    />
  )
}
