import { AccountModel } from 'domain/entities/account-model'
import { LoadUserRole } from 'domain/usecases/authentication/load-user-role'
import { PbacAdapter } from 'infra/pbac-adapter/pbac-adapter'
import { makeAutoObservable } from 'mobx'
import { Permissions } from './permissions'

export class DefaultPermissions implements Permissions {
  constructor(
    private pbacAdapter: PbacAdapter,
    private loadUserRole: LoadUserRole,
    private getCurrentUser: () => AccountModel
  ) {
    makeAutoObservable(this)
  }
  set(params: string[]) {
    return (this.pbacAdapter = new PbacAdapter(params))
  }

  can(permission: string) {
    return this.pbacAdapter.can(permission)
  }

  getAbilities() {
    return this.pbacAdapter.getAbilities()
  }

  async load() {
    if (
      this.getCurrentUser()?.accessToken &&
      this.getCurrentUser()?.user.role
    ) {
      try {
        const abilities = await this.loadUserRole.load(['abilities'])
        this.set(abilities.abilities)
      } catch (error) {
        return
      }
    }
  }

  clean() {
    return (this.pbacAdapter = new PbacAdapter())
  }
}
