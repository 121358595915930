import React from 'react'

import { UpdateLifeHabits } from 'domain/usecases/patient/update-life-habits'
import LifeHabitsLayout from 'presentation/patient/layouts/LifeHabits'

type Props = {
  updateLifeHabits: UpdateLifeHabits
}

export default function LifeHabits({ updateLifeHabits }: Props) {
  return <LifeHabitsLayout updateLifeHabits={updateLifeHabits} />
}
