import React, { useEffect, useState } from 'react'

import { Header } from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { UpdateMedicineAndDiseases } from 'domain/usecases/patient/update-medicine-and-diseases'
import MedicinesAndDiseasesForm from 'presentation/patient/components/Forms/MedicinesAndDiseases'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import { useServices } from 'presentation/hooks/use-services'
import { MedicinesAndDiseases } from 'domain/entities/patient-model'

type Props = {
  updateMedicineAndDiseases?: UpdateMedicineAndDiseases
}

export default function MedicinesAndDiseasesLayout({
  updateMedicineAndDiseases
}: Props) {
  const patientServices = useServices().patient
  const [medicinesDiseases, setMedicinesDiseases] =
    useState<MedicinesAndDiseases>()

  const loadPatient = async () => {
    const patient = await patientServices.loadPatientInfo([
      `medicinesDiseases{
        isAllergy,
        allergyRemarks,
        isMedicines,
        medicinesRemarks,
        isInsulin,
        insulinRemarks,
        otherDiseases,
        illnesses,
        functionalCapacity
      }`
    ])
    if (patient?.medicinesDiseases)
      setMedicinesDiseases(patient?.medicinesDiseases)
  }

  useEffect(() => {
    loadPatient()
  }, [])

  const handleInitialValues = () => {
    if (medicinesDiseases) {
      return {
        ...medicinesDiseases,
        illnesses: medicinesDiseases?.illnesses?.split(',')
      }
    }
  }

  return (
    <>
      <Header />
      <Container hasHeader>
        <MedicinesAndDiseasesForm
          updateMedicineAndDiseases={updateMedicineAndDiseases}
          initialValues={handleInitialValues()}
        />
      </Container>
      <BottomMenu />
    </>
  )
}
