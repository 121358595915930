import React, { useState } from 'react'

import { useHistory } from 'react-router'

import * as S from './styles'
import { Accompanying } from 'domain/entities/accompanying-model'
import SelectableList from 'presentation/shared/components/SelectableList'
import ActualPage from 'presentation/shared/components/ActualPage'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import { DeleteAccompanying } from 'domain/usecases/accompanying/delete-accompanying'
import { toast } from 'react-toastify'
import Modal from 'presentation/shared/components/Modal'
import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'
import Button from 'presentation/shared/components/Button'

type Props = {
  companions?: Accompanying[]
  deleteAccompanying: DeleteAccompanying
  updateAccompanying?: UpdateAccompanying
}

type RemoveProps = {
  itemId?: number
}

export default function AccompanyingLayout({
  companions,
  deleteAccompanying,
  updateAccompanying
}: Props) {
  const history = useHistory()
  const [selectedItems, setSelectedItems] = useState([] as number[])
  const [companionList, setCompanionList] = useState(companions)
  const [handleDeleteModal, setDeleteModal] = useState<boolean>(false)
  const [handleUpdateIsMainModal, setHandleUpdateIsMainModal] =
    useState<boolean>(false)
  const user = useStores().currentAccount.getCurrentAccount().user

  const removeAccompanying = async ({ itemId }: RemoveProps) => {
    try {
      await deleteAccompanying.delete({
        accompanists_ids: itemId ? [itemId] : selectedItems
      })
      setCompanionList(
        companionList?.filter((companion) => {
          return selectedItems.indexOf(companion.accompanying_id ?? 0) === -1
        })
      )
      setSelectedItems([])
      setDeleteModal(true)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const updateAccompanyingMain = async ({ itemId }: RemoveProps) => {
    try {
      const accompanyingToUpdate = companionList?.find(
        (companion) => companion.accompanying_id === itemId
      )

      if (accompanyingToUpdate) {
        accompanyingToUpdate.isMain = !accompanyingToUpdate.isMain

        delete accompanyingToUpdate.createdAt

        updateAccompanying?.update({
          ...accompanyingToUpdate
        })

        setHandleUpdateIsMainModal(true)
        // setCompanionList(companionList?.map((companion) => companion))
      }
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const accompanyingRedirect = (id: any) =>
    history.push('/acompanhantes/save', {
      accompanying: companions?.find((item) => item.accompanying_id === id)
    })

  const actions = [
    {
      label: 'Adicionar/remover como principal',
      handle: updateAccompanyingMain
    },
    {
      label: 'Remover',
      handle: removeAccompanying
    }
  ]

  return (
    <>
      <Header />
      <Container hasHeader>
        <S.Wrapper>
          <S.Content>
            <S.Header>
              <ActualPage text="Voltar" onClick={history.goBack} />
              {selectedItems.length > 0 && (
                <p onClick={() => removeAccompanying({ itemId: undefined })}>
                  Excluir acompanhante
                </p>
              )}
            </S.Header>
            <Heading
              as="h1"
              size="medium"
              color="primary"
              style={{ marginBottom: '12px' }}
            >
              Acompanhante selecionado
            </Heading>
            <SelectableList
              actions={actions}
              itemClick={accompanyingRedirect}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              items={companionList
                ?.filter((accompanying) => accompanying.isMain)
                .map(mapAccompanying)}
            />
            <Heading
              as="h1"
              size="medium"
              color="primary"
              style={{ marginBottom: '12px', marginTop: '12px' }}
            >
              Outros acompanhantes
            </Heading>
            <SelectableList
              actions={actions}
              itemClick={accompanyingRedirect}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              items={companionList
                ?.filter((accompanying) => !accompanying.isMain)
                .map(mapAccompanying)}
            />
          </S.Content>
          {user.role === Profile.PATIENT && (
            <Button
              onClick={() => history.push('/acompanhantes/save')}
              fullWidth
              style={{
                padding: '2rem'
              }}
            >
              + Adicionar acompanhante
            </Button>
          )}
        </S.Wrapper>
      </Container>
      <Modal
        show={handleDeleteModal}
        close={() => setDeleteModal(false)}
        title="Acompanhante removido com sucesso!"
      />

      <Modal
        show={handleUpdateIsMainModal}
        close={() => setHandleUpdateIsMainModal(false)}
        title="Prioridade do acompanhante alterada!"
      />
    </>
  )
}

const mapAccompanying = (accompanying: Accompanying) => ({
  name: accompanying.name ?? '',
  id: accompanying.accompanying_id ?? 0,
  createdAt: accompanying.createdAt
})
