import React from 'react'

import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteLoadPatientDocuments } from 'service/usecases/load-patient-documents/remote-load-patient-documents'
import SurgeryDetails from 'presentation/shared/pages/SurgeryDetails'
import { RemoteLoadSurgeryDetails } from 'service/usecases/load-surgery-details/remote-load-surgery-details'
import { SurgeryRepository } from 'repository/repositories/surgery/surgery-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteLoadSurgicalOrder } from 'service/usecases/load-surgical-order/remote-load-surgical-order'
import { RemoteLoadSurgicalOrderStatus } from 'service/usecases/load-surgical-order-status/remote-load-surgical-order-status'

export default function makePatientSurgeryDetails() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)
  const surgeryRepository = new SurgeryRepository(apiRepository)
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)

  const loadPatientDocuments = new RemoteLoadPatientDocuments(patientRepository)
  const loadSurgeryDetails = new RemoteLoadSurgeryDetails(surgeryRepository)
  const loadSurgicalOrder = new RemoteLoadSurgicalOrder(surgicalOrderRepository)
  const loadSurgicalOrderStatus = new RemoteLoadSurgicalOrderStatus(
    surgicalOrderRepository
  )

  return (
    <SurgeryDetails
      loadSurgeryDetails={loadSurgeryDetails}
      loadPatientDocuments={loadPatientDocuments}
      loadSurgicalOrder={loadSurgicalOrder}
      loadSurgicalOrderStatus={loadSurgicalOrderStatus}
    />
  )
}
