import { LoadDoctorSchedule } from 'domain/usecases/doctor/load-doctor-schedule'
import { SearchDoctors } from 'domain/usecases/doctor/search-doctors'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { FinishDoctorSurgicalOrder } from 'domain/usecases/doctor/finish-doctor-surgical-order'
import { LoadDoctorInfo } from 'domain/usecases/doctor/load-doctor-info'
import { LoadDoctorByInviteToken } from 'domain/usecases/doctor/load-doctor-by-invite-token'
import { UpdateDoctor } from 'domain/usecases/doctor/update-doctor'
import { LoadDocumentExternalCertifications } from 'domain/usecases/doctor/load-documents-external-certifications'
import { UploadDoctorDocument } from 'domain/usecases/doctor/upload-doctor-document'
import { LoadDoctorPendencies } from 'domain/usecases/doctor/load-doctor-pendencies'
import { LoadRecommendedDoctors } from 'domain/usecases/doctor/load-recommended-doctors'
import { ResendDoctorInvite } from 'domain/usecases/doctor/resendDoctorInvite'
import { LoadDoctorDocument } from 'domain/usecases/doctor/load-doctor-document'
import { AddFavoriteSurgicalOrder } from 'domain/usecases/doctor/add-favorite-surgical-order'
import { GetRequiredDoctorDocuments } from 'domain/usecases/doctor/get-doctor-required-documents'
import { LinkSecretary } from 'domain/usecases/doctor/link-secretary'
import { LoadDoctorSurgicalOrders } from 'domain/usecases/doctor/load-doctor-surgical-orders'

type Services = {
  loadDoctorSchedule: LoadDoctorSchedule
  searchDoctors: SearchDoctors
  loadDoctor: LoadDoctor
  finishDoctorSurgicalOrder: FinishDoctorSurgicalOrder
  loadDoctorInfo: LoadDoctorInfo
  loadDoctorByInviteToken: LoadDoctorByInviteToken
  updateDoctor: UpdateDoctor
  loadDocumentsExternalCertifications: LoadDocumentExternalCertifications
  uploadDoctorDocument: UploadDoctorDocument
  loadDoctorPendencies: LoadDoctorPendencies
  loadRecommendedDoctors: LoadRecommendedDoctors
  resendDoctorInvite: ResendDoctorInvite
  loadDoctorDocument: LoadDoctorDocument
  addFavoriteSurgicalOrder: AddFavoriteSurgicalOrder
  getRequiredDoctorDocuments: GetRequiredDoctorDocuments
  linkSecretary: LinkSecretary
  loadDoctorSurgicalOrders: LoadDoctorSurgicalOrders
}

export class DoctorService {
  constructor(services: Services) {
    this.services = services
  }

  private readonly services: Services

  async loadDoctorSchedule(
    params: LoadDoctorSchedule.Params
  ): Promise<LoadDoctorSchedule.Model> {
    return this.services.loadDoctorSchedule.load(params)
  }

  async searchDoctors(
    params: SearchDoctors.Params
  ): Promise<SearchDoctors.Model> {
    return this.services.searchDoctors.load(params)
  }

  async loadDoctor(params: LoadDoctor.Params): Promise<LoadDoctor.Model> {
    return this.services.loadDoctor.load(params)
  }

  async finishDoctorSurgicalOrder(
    params: FinishDoctorSurgicalOrder.Params
  ): Promise<FinishDoctorSurgicalOrder.Model> {
    return this.services.finishDoctorSurgicalOrder.add(params)
  }

  async loadDoctorInfo(
    doctor_id: number,
    params?: LoadDoctorInfo.Params
  ): Promise<LoadDoctorInfo.Model> {
    return this.services.loadDoctorInfo.load(doctor_id, params)
  }

  async loadDoctorByInviteToken(
    token: string,
    params: LoadDoctorByInviteToken.Params
  ): Promise<LoadDoctorByInviteToken.Model> {
    return this.services.loadDoctorByInviteToken.load(token, params)
  }

  async updateDoctor(params: UpdateDoctor.Params): Promise<UpdateDoctor.Model> {
    return this.services.updateDoctor.update(params)
  }

  async loadExternalCertifications(
    params: LoadDocumentExternalCertifications.Params
  ): Promise<LoadDocumentExternalCertifications.Model> {
    return this.services.loadDocumentsExternalCertifications.load(params)
  }

  async uploadDoctorDocument(
    params: UploadDoctorDocument.Params
  ): Promise<UploadDoctorDocument.Model> {
    return this.services.uploadDoctorDocument.upload(params)
  }

  async loadDoctorPendencies(
    params: LoadDoctorPendencies.Params
  ): Promise<LoadDoctorPendencies.Model> {
    return this.services.loadDoctorPendencies.load(params)
  }

  async loadRecommendedDoctors(
    params: LoadRecommendedDoctors.Params
  ): Promise<LoadRecommendedDoctors.Model> {
    return this.services.loadRecommendedDoctors.load(params)
  }

  async resendDoctorInvite(
    params: ResendDoctorInvite.Params
  ): Promise<ResendDoctorInvite.Model> {
    return this.services.resendDoctorInvite.resend(params)
  }

  async loadDoctorDocument(
    params: LoadDoctorDocument.Params
  ): Promise<LoadDoctorDocument.Model> {
    return this.services.loadDoctorDocument.load(params)
  }

  async addFavoriteSurgicalOrder(
    params: AddFavoriteSurgicalOrder.Params
  ): Promise<AddFavoriteSurgicalOrder.Model> {
    return this.services.addFavoriteSurgicalOrder.add(params)
  }

  getRequiredDoctorDocuments(
    doctor: GetRequiredDoctorDocuments.Params
  ): GetRequiredDoctorDocuments.Model {
    return this.services.getRequiredDoctorDocuments.execute(doctor)
  }

  async linkSecretary(
    params: LinkSecretary.Params
  ): Promise<LinkSecretary.Model> {
    return this.services.linkSecretary.execute(params)
  }

  async loadDoctorSurgicalOrders(
    params: LoadDoctorSurgicalOrders.Params
  ): Promise<LoadDoctorSurgicalOrders.Model> {
    return this.services.loadDoctorSurgicalOrders.load(params)
  }
}

export default DoctorService
