import { Profile } from '../enum/profile'
import { Authentication } from '../../domain/usecases/authentication/authentication'

export const profileBind: Record<Profile, Authentication.Params['role']> = {
  [Profile.DOCTOR]: 'DOCTOR',
  [Profile.PATIENT]: 'PATIENT',
  [Profile.SECRETARY]: 'SECRETARY',
  [Profile.HOSPITAL]: 'ADMIN',
  [Profile.CRMO]: 'CRMO',
  [Profile.BUDGET]: 'CRMO',
  [Profile.HOSPITALIZATION]: 'CRMO',
  [Profile.OPME]: 'CRMO',
  [Profile.PRE_SCHEDULING]: 'CRMO',
  [Profile.SCHEDULING]: 'CRMO',
  [Profile.TRIAGE]: 'CRMO',
  [Profile.RECEPTIONIST]: 'CRMO'
}
