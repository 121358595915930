import React from 'react'
import { ReactComponent as BriefcaseIcon } from 'presentation/assets/icons/briefcase.svg'
import { ReactComponent as AccompanyingIcon } from 'presentation/assets/icons/accompanying.svg'
import { ReactComponent as FavoriteIcon } from 'presentation/assets/icons/favorite.svg'
import { ReactComponent as PendenciesIcon } from 'presentation/assets/icons/alert-circle-grey.svg'
import { ReactComponent as DashboardIcon } from 'presentation/assets/icons/dashboard.svg'

type OptionsType = {
  link: string
  label: string
  icon: React.ReactNode | string
}

type ProfilesType = {
  doctor: OptionsType[]
  patient: OptionsType[]
  admin: OptionsType[]
  secretary: OptionsType[]
  crmo: OptionsType[]
  hospitalization: OptionsType[]
  doctor_register: OptionsType[]
  director_doctor_register: OptionsType[]
  admin_sectorial_crmo: OptionsType[]
}

export const profiles: ProfilesType = {
  doctor: [
    {
      link: '/home',
      label: 'Home',
      icon: <DashboardIcon />
    },
    {
      link: '/secretaria',
      label: 'Secretária',
      icon: <BriefcaseIcon />
    },
    // {
    //   link: '',
    //   label: 'Exames e consultas',
    //   icon: <ExamsIcon />
    // },
    {
      link: '/favoritos',
      label: 'Proc. Favoritados',
      icon: <FavoriteIcon />
    },
    {
      link: '/pendencias',
      label: 'Pendências',
      icon: <PendenciesIcon />
    }
    // {
    //   link: '',
    //   label: 'Fale conosco',
    //   icon: <ContactUsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Termos e politicas',
    //   icon: <TermsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Ajuda',
    //   icon: <HelpIcon />
    // }
  ],
  patient: [
    {
      link: '/home',
      label: 'Home',
      icon: <DashboardIcon />
    },
    {
      link: '/acompanhantes',
      label: 'Acompanhantes',
      icon: <AccompanyingIcon />
    }
    // {
    //   link: '',
    //   label: 'Exames e consultas',
    //   icon: <ExamsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Orçamentos',
    //   icon: <BudgetsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Fale conosco',
    //   icon: <ContactUsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Termos e politicas',
    //   icon: <TermsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Ajuda',
    //   icon: <HelpIcon />
    // }
  ],
  admin: [],
  secretary: [],
  crmo: [],
  hospitalization: [],
  doctor_register: [],
  director_doctor_register: [],
  admin_sectorial_crmo: []
}
