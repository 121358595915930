import React from 'react'

import { useHistory } from 'react-router-dom'
import Heading from 'presentation/shared/components/Heading'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

import * as S from '../styles'

type OpmeReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function OpmeReview({
  surgicalOrder,
  visualizationMode
}: OpmeReviewProps) {
  const history = useHistory()

  const opmes = surgicalOrder.opme?.solicitations
  const providers = surgicalOrder.opme?.providers || []

  return (
    <>
      <S.ProcedureContainer>
        <header>
          <Heading style={{ margin: '32px 0 24px' }} as="h2" weight={500}>
            OPME
          </Heading>
          {!visualizationMode && (
            <small
              onClick={() =>
                history.push('/pedido-cirurgico/opme', {
                  surgical_order_id: surgicalOrder.surgical_order_id,
                  opme: surgicalOrder.opme
                })
              }
            >
              Editar
            </small>
          )}
        </header>
        {!opmes?.length && <span>Procedimento não utiliza OPME.</span>}
        {opmes?.length
          ? opmes?.map((opme, idx) => (
              <div key={opme.description}>
                {<h6>OPME {idx + 1}</h6>}
                <br />
                <h6>Descrição: </h6>
                <span>{opme.description}</span>
                <br />
                <h6>Quantidade: </h6>
                <span>{opme.quantity}</span>
                <br />
              </div>
            ))
          : null}
      </S.ProcedureContainer>
      {providers.length ? (
        <S.ProcedureContainer style={{ marginTop: '16px' }}>
          <div>
            {providers.map((provider, idx) => (
              <div
                style={{ display: 'block', padding: '0', boxShadow: 'none' }}
                key={provider + idx}
              >
                <h6 style={{ display: 'block' }}>Fornecedor {idx + 1}</h6>
                <span>{provider ?? '-'}</span>
              </div>
            ))}
          </div>
        </S.ProcedureContainer>
      ) : null}
    </>
  )
}
