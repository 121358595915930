import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import * as S from './styles'

import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import newSurgicalOrder from 'presentation/assets/icons/filled-doctor.svg'
import { ServicesMenu } from 'presentation/patient/components/Forms/ServicesMenu'

export default function ServicesLayout() {
  const history = useHistory()
  const [selectedItem, setSelectedItem] = useState<string>('appointments')

  const { state } = useLocation<{ anchor: string }>()

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelectedItem(state.anchor)
  }, [])

  return (
    <>
      <Header />
      <div ref={scrollRef}>
        <ServicesMenu
          onSelectItem={(itemMenu: string) => setSelectedItem(itemMenu)}
          selectedItemMenu={selectedItem}
        />
      </div>
      <Container style={{ paddingTop: '0px' }}>
        {selectedItem === 'surgery' && (
          <S.ServiceOption onClick={() => history.push('/pedido-cirurgico')}>
            <S.Icon>
              <img src={newSurgicalOrder} alt="newSurgicalOrder" />
            </S.Icon>
            <S.Title>Novo pedido cirúrgico</S.Title>
          </S.ServiceOption>
        )}
      </Container>
      <BottomMenu />
    </>
  )
}
