import { FindAllOpmes } from 'domain/usecases/surgical-order/find-all-opmes'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export class RemoteFindAllOpmes implements FindAllOpmes {
  constructor(private surgicalOrderRepository: SurgicalOrderRepository) {}

  async findAll(params: FindAllOpmes.Params): Promise<FindAllOpmes.Model> {
    return this.surgicalOrderRepository.findAllOpmes(params)
  }
}
