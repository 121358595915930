import { PatientDocument } from 'common/enum/patient-document'
import { HealthInsurance } from 'domain/entities/health-insurance'
import {
  HealthInsurancePlans,
  LoadHealthInsurancePlans
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { uniqBy } from 'lodash'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { PatientInsuranceInfoForm } from 'presentation/patient/components/Forms/CarouselInsuranceInfo'
import UpdateInsuranceInfoLayout from 'presentation/patient/layouts/CreateSurgicalOrder/UpdateInsuranceInfo'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { getThumbnail } from 'presentation/shared/components/UploadSection/Functions'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import React, { useEffect, useState } from 'react'

type ChangeSurgicalOrderPersonalInfoProps = {
  loadHealthInsurance: LoadHealthInsurancePlans
  loadPatientInfo: LoadPatientInfo
  uploadInsuranceCard: UploadPatientDocument
  updateInsuranceInfo: UpdatePatientHealthInsurance
  updatePatientPersonalInfo: UpdatePatientInfo
} & WithLoadingProps

function ChangeSurgicalOrderInsuranceInfo({
  loadHealthInsurance,
  uploadInsuranceCard,
  loadPatientInfo,
  updateInsuranceInfo,
  updatePatientPersonalInfo,
  setIsLoading
}: ChangeSurgicalOrderPersonalInfoProps) {
  const [patientInfo, setPatientInfo] = useState({} as PatientInsuranceInfoForm)
  const [healthInsurances, setHealthInsurances] = useState(
    [] as HealthInsurancePlans[]
  )
  const currentHospital = useStores().currentHospital
  const patientService = useServices().patient
  const hospital_id = currentHospital.getHospitalSelected()

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)
      const insurances = await loadHealthInsurance.load({
        hospital_id: hospital_id,
        params: [
          'code',
          'description',
          'plans {code, description, subPlan{code, description}}',
          'ansRegister'
        ]
      })
      setHealthInsurances(insurances)
      const patientInsurance = await loadPatientInfo.load([
        'company',
        `healthInsurance {
          healthInsuranceCode,
          healthInsuranceName,
          healthPlanCode,
          healthPlanName,
          company
          healthPlanName
          healthCard
        }`,
        `documentHealthInsurance {
          document_id,
          name,
          documentUri,
          type,
          sendedAt
        }`
      ])

      const fileDocument: ListFile<PatientDocument>[] = await Promise.all(
        (patientInsurance.documentHealthInsurance || []).map(
          async ({ document_id, name, type }, page) => {
            const fileDocument = await patientService.downloadPatientDocument(
              document_id
            )
            const file = new File([fileDocument.data], name ?? '', {
              type: fileDocument.contentType
            })
            const thumbnail = await getThumbnail(file)
            return {
              file,
              identifier: type as PatientDocument,
              page: page + 1,
              thumbnail
            }
          }
        )
      )

      setPatientInfo({
        healthCard: patientInsurance.healthInsurance?.healthCard ?? '',
        healthCardFiles: fileDocument,
        company: patientInsurance.healthInsurance?.company,
        healthInsurance: patientInsurance.healthInsurance
          ? new HealthInsurance(
              patientInsurance.healthInsurance?.healthInsuranceCode ?? 0,
              patientInsurance.healthInsurance?.healthInsuranceName ?? '',
              patientInsurance.healthInsurance?.healthPlanCode ?? 0,
              patientInsurance.healthInsurance?.healthPlanName ?? ''
            )
          : undefined,
        documentHealthInsurance: patientInsurance.documentHealthInsurance?.map(
          (document) => ({
            document_id: document?.document_id ?? 0,
            type: document?.type ?? '',
            name: document?.name ?? '',
            documentUri: document?.documentUri ?? '',
            sendedAt: document?.sendedAt ?? ''
          })
        )
      })
      setIsLoading(false)
    }
    loadData()
  }, [])

  return (
    <>
      <UpdateInsuranceInfoLayout
        uploadInsuranceCard={uploadInsuranceCard}
        healthInsurances={uniqBy(healthInsurances, (val) => val.description)}
        updateInsuranceInfo={updateInsuranceInfo}
        patientInfo={patientInfo}
        updatePatientPersonalInfo={updatePatientPersonalInfo}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default WithLoading(ChangeSurgicalOrderInsuranceInfo)
