import React, { HTMLAttributes } from 'react'
import * as S from './styles'
import CameraIcon from 'presentation/assets/icons/camera-upload.svg'
import { getRandomProfileImageUrl } from 'presentation/utils/get-random-profile-image'

export type ProfilePicProps = {
  src?: string
  size?: 'small' | 'medium' | 'large' | 'xl'
  outlined?: boolean
  upload?: (e: React.ChangeEvent<HTMLInputElement>) => void
} & HTMLAttributes<HTMLImageElement>

export const ProfilePic = ({
  src,
  size = 'medium',
  outlined = false,
  upload,
  ...props
}: ProfilePicProps) => {
  return (
    <S.Wrapper upload={upload}>
      <div>
        <S.Image
          upload={upload}
          {...props}
          outlined={outlined}
          size={size}
          src={getRandomProfileImageUrl() || src}
          alt="profile pic"
        />
        {upload && (
          <label htmlFor="profile-pic">
            <img src={CameraIcon} alt="Icon camera" />
            <input type="file" id="profile-pic" onChange={upload} />
          </label>
        )}
      </div>
    </S.Wrapper>
  )
}

export default ProfilePic
