import React, { ReactNode } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Property } from 'csstype'

import SwiperClass from 'swiper/types/swiper-class'

import 'swiper/swiper-bundle.css'
import * as S from './styles'

import SwiperCore, { EffectFade, Pagination, SwiperOptions } from 'swiper'
import { PaginationOptions } from 'swiper/types/modules/pagination'
import { ThemeColorsOptions } from '../MUI'
import { FadeEffectOptions } from 'swiper/types'

export type CarouselProps = {
  children?: ReactNode[]
  slidesPerView?: number
  spaceBetween?: number
  touch?: boolean
  freeMode?: boolean
  state: CarouselState
  dynamicHeight?: boolean
  setState: React.Dispatch<React.SetStateAction<CarouselState>>
  justifyContent?: Property.JustifyContent
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  bulletColor?: ThemeColorsOptions
  bulletBottomPosition?: string | number
  changeSlideTo?: number
  pagination?: PaginationOptions | boolean
  fade?: FadeEffectOptions
  effect?:
    | 'fade'
    | 'slide'
    | 'cube'
    | 'coverflow'
    | 'flip'
    | 'creative'
    | 'cards'
  speed?: number
  breakpoints?: { [p: number]: SwiperOptions } | { [p: string]: SwiperOptions }
}

export type CarouselState = {
  slideNext: () => void
  slidePrev: () => void
  slideTo: (number: number) => void
  activeIndex: number
  isLast: boolean
}

const Carousel = ({
  slidesPerView = 1,
  spaceBetween = 0,
  touch = true,
  freeMode = false,
  children,
  state,
  setState,
  dynamicHeight,
  justifyContent,
  style,
  changeSlideTo,
  containerStyle,
  bulletColor,
  bulletBottomPosition,
  pagination = false,
  fade = undefined,
  effect = undefined,
  speed,
  breakpoints
}: CarouselProps) => {
  const updateState = (swiperRef: SwiperClass, hasSlideToChange?: number) => {
    setState({
      ...state,
      slideNext: () => swiperRef.slideNext(),
      slidePrev: () => swiperRef.slidePrev(),
      slideTo: (number: number) =>
        hasSlideToChange
          ? swiperRef.slideTo(hasSlideToChange)
          : swiperRef.slideTo(number),
      activeIndex: swiperRef.activeIndex,
      isLast:
        (children && swiperRef.activeIndex === children.length - 1) ?? false
    })
  }

  {
    pagination && SwiperCore.use([Pagination])
  }

  {
    fade && effect === 'fade' && SwiperCore.use([EffectFade])
  }

  return (
    <S.Wrapper backgroundColor={bulletColor} bottom={bulletBottomPosition}>
      <Swiper
        onAfterInit={(swiperRef) =>
          changeSlideTo ? updateState(swiperRef) : null
        }
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        allowTouchMove={touch}
        onSwiper={(swiperRef) => updateState(swiperRef)}
        onSlideChange={(swiperRef) => {
          updateState(swiperRef)
        }}
        freeMode={freeMode}
        observer
        autoHeight={dynamicHeight}
        pagination={pagination}
        style={containerStyle}
        fadeEffect={fade}
        effect={effect}
        speed={speed}
        breakpoints={breakpoints}
      >
        {children!.map((el, idx) => {
          if (el)
            return (
              <SwiperSlide
                style={{
                  ...style,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent
                }}
                key={idx}
              >
                {el}
              </SwiperSlide>
            )
        })}
      </Swiper>
    </S.Wrapper>
  )
}

export default Carousel
