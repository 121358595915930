export enum Profile {
  PATIENT = 'patient',
  DOCTOR = 'doctor',
  HOSPITAL = 'admin',
  SECRETARY = 'secretary',
  CRMO = 'crmo',
  HOSPITALIZATION = 'hospitalization',
  PRE_SCHEDULING = 'pre_scheduling',
  SCHEDULING = 'scheduling',
  OPME = 'opme',
  GUIDE_CENTER = 'crmo',
  BUDGET = 'budget',
  TRIAGE = 'triage',
  RECEPTIONIST = 'RECEPTIONIST'
}

export enum ProfileLocated {
  patient = 'Paciente',
  doctor = 'Médico(a)',
  admin = 'Admin',
  secretary = 'Secretária',
  crmo = 'Central de guias',
  hospitalization = 'Internação',
  pre_scheduling = 'Pré-agendamento',
  scheduling = 'Agendamento',
  opme = 'OPME',
  guide_center = 'Central de Guias',
  budget = 'Orçamento',
  triage = 'Triagem',
  RECEPTIONIST = 'Recepção'
}
