import React, { useEffect, useState } from 'react'

import AlertIcon from 'presentation/assets/icons/alert.svg'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import verifyEmptyObject from 'common/utils/verify-empty-object'

import { ListItem } from 'presentation/shared/components/List'

import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { useHistory } from 'react-router-dom'
import { useStores } from 'presentation/hooks/use-stores'
import { AddSurgicalOrder } from 'domain/usecases/surgical-order/add-surgical-order'
import { toast } from 'react-toastify'
import SaveSurgicalOrderLayout from 'presentation/patient/layouts/CreateSurgicalOrder/Save'
import { ItemText } from 'presentation/patient/layouts/CreateSurgicalOrder/Save/styles'
import { Patient } from 'domain/entities/patient-model'
import { useServices } from 'presentation/hooks/use-services'
import { PatientDocument } from 'common/enum/patient-document'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { generateHash } from 'common/utils/generateHash'
import { renameFile } from 'common/utils/file/renameFile'

type SaveSurgicalOrderProps = {
  loadPatientInfo: LoadPatientInfo
  createSurgicalOrder: AddSurgicalOrder
} & WithLoadingProps

function SaveSurgicalOrder({
  loadPatientInfo,
  createSurgicalOrder,
  setIsLoading
}: SaveSurgicalOrderProps) {
  const [patientInfo, setPatientInfo] = useState<Patient>({} as Patient)
  const [patientHealthInsurance, setPatientHealthInsurance] =
    useState<Patient['healthInsurance']>()
  const surgicalOrderFile = useStores().file
  const user = useStores().currentAccount.getCurrentAccount().user
  const history = useHistory()

  const currentHospital = useStores().currentHospital
  const hospital_id = currentHospital.getHospitalSelected()
  const service = useServices().surgicalOrder
  const patientService = useServices().patient

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true)
        const data = await loadPatientInfo.load([
          'name',
          'phone',
          'birthday',
          'phone',
          'cpf',
          'email',
          'gender',
          'patient_id',
          `healthInsurance {
            healthInsuranceCode,
            healthInsuranceName,
          }`
        ])

        const { healthInsurance, ...info } = data
        setPatientInfo(info as Patient)
        setPatientHealthInsurance(healthInsurance!)
      } catch (error) {
        toast.error(error.message)
      } finally {
        setIsLoading(false)
      }
    }
    loadData()
  }, [])

  const completeAttachment = () => {
    const hasSurgicalOrders =
      surgicalOrderFile.listFiles?.some(
        (file) => file.identifier === PatientDocument.SURGICAL_ORDER
      ) || false

    return hasSurgicalOrders
  }

  const items: ListItem[] = [
    {
      title: <ItemText>Dados pessoais</ItemText>,
      icon: !verifyEmptyObject(patientInfo) ? AlertIcon : CheckIcon,
      click: () => history.push('/pedido-cirurgico/save/dados-pessoais')
    },
    {
      title: <ItemText>Dados convênio</ItemText>,
      icon:
        !verifyEmptyObject(patientHealthInsurance) || !patientHealthInsurance
          ? AlertIcon
          : CheckIcon,
      click: () => history.push('/pedido-cirurgico/save/dados-convenio')
    },
    {
      title: <ItemText>Documentos</ItemText>,
      icon: !completeAttachment() ? AlertIcon : CheckIcon,
      click: () => history.push('/pedido-cirurgico/save/documentos')
    }
  ]

  async function uploadDocuments(group_id: string) {
    await Promise.all(
      (surgicalOrderFile.listFiles || []).map(async (listFile) => {
        try {
          const newFilename =
            listFile.identifier === PatientDocument.EXAM_REPORT
              ? 'Laudos 1'
              : listFile.identifier

          await service.uploadToMergeDocument({
            file: renameFile(listFile.file, String(newFilename)),
            type: listFile.identifier as string,
            group_id
          })
        } catch (error) {
          toast.error(error.message)
        }
      })
    )
  }

  async function uploadPatientDocsToSurgicalOrderDocs(group_id: string) {
    try {
      const patientDocuments = await patientService.loadPatientDocuments([
        'type',
        'document_id',
        'name'
      ])

      const revertedArray = patientDocuments.reverse()
      const frontFile = revertedArray.find(
        (doc) => doc.type === PatientDocument.HEALTH_CARD_FRONT
      )
      const backFile = revertedArray.find(
        (doc) => doc.type === PatientDocument.HEALTH_CARD_BACK
      )
      const uploadHealthCard = async (
        document_id: number,
        filename: string
      ) => {
        const fileDocument = await patientService.downloadPatientDocument(
          document_id
        )
        const fileUnnamed = new File([fileDocument.data], filename ?? '', {
          type: fileDocument.contentType
        })
        const file = renameFile(fileUnnamed, PatientDocument.HEALTH_CARD)
        await service.uploadToMergeDocument({
          file,
          type: PatientDocument.HEALTH_CARD,
          group_id
        })
      }
      if (frontFile && frontFile?.document_id) {
        await uploadHealthCard(frontFile?.document_id, frontFile.name || '')
      }
      if (backFile && backFile?.document_id) {
        await uploadHealthCard(backFile?.document_id, backFile.name || '')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const group_id = generateHash()
      await Promise.all([
        uploadDocuments(group_id),
        uploadPatientDocsToSurgicalOrderDocs(group_id)
      ])
      const surgicalOrder = await createSurgicalOrder.add({
        patient_id: patientInfo?.patient_id,
        hospital_id: hospital_id,
        patientName: user.name
      })
      if (surgicalOrder.surgical_order_id) {
        await service.confirmMergeDocuments({
          group_id,
          surgical_order_id: surgicalOrder.surgical_order_id
        })
        surgicalOrderFile.clear()
        history.push('/home')
        toast.success(
          'Pedido cirúrgico criado com sucesso, o pedido ja foi enviado para análise aguarde as próximas etapas!'
        )
      } else {
        toast.success('Erro ao criar pedido cirúrgico')
      }
    } catch (err: any) {
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (hospital_id === 0) {
      history.push('/pedido-cirurgico')
    }
  }, [hospital_id])

  return (
    <>
      <SaveSurgicalOrderLayout
        disabled={
          !verifyEmptyObject(patientHealthInsurance) ||
          !verifyEmptyObject(patientInfo) ||
          !completeAttachment()
        }
        handleSubmit={handleSubmit}
        items={items}
      />
    </>
  )
}

export default WithLoading(SaveSurgicalOrder)
