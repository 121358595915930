import { makeAutoObservable } from 'mobx'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { ConcludeSurgicalOrder } from 'domain/usecases/surgical-order/conclude-surgical-order'
import { DeleteSurgicalOrderSolicitation } from 'domain/usecases/surgical-order/delete-surgical-order-solicitation'
import { UploadSurgicalOrderDocumentsSameType } from 'domain/usecases/surgical-order/upload-surgical-order-documents-same-type'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { DeleteFavoritedSurgicalOrder } from 'domain/usecases/surgical-order/delete-favorited-surgical-order'
import { SurgicalOrderCheckin } from 'domain/usecases/surgical-order/checkin'
import { LoadFavoriteDoctorSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-doctor-order'
import { UploadToMergeDocument } from 'domain/usecases/surgical-order/upload-to-merge-document'
import { ConfirmMergeDocuments } from 'domain/usecases/surgical-order/confirm-merge-documents'
import { DeleteUploadedDocuments } from 'domain/usecases/surgical-order/delete-uploaded-documents'
import { CreateSurgicalComment } from 'domain/usecases/surgical-order/create-surgical-comment'
import { GetAgendaAllocation } from 'domain/usecases/agenda/get-agenda-allocation'
import { GetAgendaClassification } from 'domain/usecases/agenda/get-agenda-classification'
import { GetAllStatus } from 'domain/usecases/surgical-status/get-all-surgical-status'
import { FindAllOpmes } from 'domain/usecases/surgical-order/find-all-opmes'

type Services = {
  loadSurgicalOrder: LoadSurgicalOrder
  loadSurgicalOrderDocument: LoadSurgicalOrderDocument
  uploadSurgicalOrderDocument: UploadSurgicalOrderDocument
  loadCids: LoadCid
  concludeSurgicalOrder: ConcludeSurgicalOrder
  deleteSurgicalOrderSolicitation: DeleteSurgicalOrderSolicitation
  uploadSurgicalOrderDocumentsSameType: UploadSurgicalOrderDocumentsSameType
  loadFavoriteSurgicalOrders: LoadFavoriteSurgicalOrders
  loadFavoriteDoctorSurgicalOrders: LoadFavoriteDoctorSurgicalOrders
  deleteFavoritedSurgicalOrder: DeleteFavoritedSurgicalOrder
  checkin: SurgicalOrderCheckin
  uploadToMergeDocument: UploadToMergeDocument
  confirmMergeDocuments: ConfirmMergeDocuments
  deleteUploadedDocuments: DeleteUploadedDocuments
  createComment: CreateSurgicalComment
  getAgendaAllocation: GetAgendaAllocation
  getAgendaClassification: GetAgendaClassification
  getAllStatus: GetAllStatus
  findAllOpmes: FindAllOpmes
}

export class SurgicalOrderService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadSurgicalOrderDocument(
    params: LoadSurgicalOrderDocument.Params
  ): Promise<LoadSurgicalOrderDocument.Model> {
    return this.services.loadSurgicalOrderDocument.load(
      params.surgical_order_id,
      params.document_id
    )
  }

  async uploadSurgicalOrderDocument(
    params: UploadSurgicalOrderDocument.Params
  ): Promise<UploadSurgicalOrderDocument.Model> {
    return this.services.uploadSurgicalOrderDocument.upload(params)
  }

  async loadSurgicalOrder(
    id: number,
    params: LoadSurgicalOrder.Params
  ): Promise<LoadSurgicalOrder.Model> {
    return this.services.loadSurgicalOrder.load(id, params)
  }

  async loadCids(params?: LoadCid.Params): Promise<LoadCid.Model> {
    return this.services.loadCids.load(params)
  }

  async concludeSurgicalOrder(
    params: ConcludeSurgicalOrder.Params
  ): Promise<ConcludeSurgicalOrder.Model> {
    return this.services.concludeSurgicalOrder.conclude(params)
  }

  async deleteSurgicalOrderSolicitation(
    params: DeleteSurgicalOrderSolicitation.Params
  ): Promise<DeleteSurgicalOrderSolicitation.Model> {
    return this.services.deleteSurgicalOrderSolicitation.delete(params)
  }

  async uploadSurgicalOrderDocumentsSameType(
    params: UploadSurgicalOrderDocumentsSameType.Params
  ): Promise<UploadSurgicalOrderDocumentsSameType.Model> {
    return this.services.uploadSurgicalOrderDocumentsSameType.upload(params)
  }

  async uploadToMergeDocument(
    params: UploadToMergeDocument.Params
  ): Promise<UploadToMergeDocument.Model> {
    return this.services.uploadToMergeDocument.upload(params)
  }

  async loadFavoriteSurgicalOrders(
    params: LoadFavoriteSurgicalOrders.Params
  ): Promise<LoadFavoriteSurgicalOrders.Model> {
    return this.services.loadFavoriteSurgicalOrders.load(params)
  }

  async loadFavoriteDoctorSurgicalOrders(
    params: LoadFavoriteDoctorSurgicalOrders.Params
  ): Promise<LoadFavoriteDoctorSurgicalOrders.Model> {
    return this.services.loadFavoriteDoctorSurgicalOrders.load(params)
  }

  async deleteFavoritedSurgicalOrder(
    params: DeleteFavoritedSurgicalOrder.Params
  ): Promise<DeleteFavoritedSurgicalOrder.Model> {
    return this.services.deleteFavoritedSurgicalOrder.delete(params)
  }

  async checkin(
    params: SurgicalOrderCheckin.Params
  ): Promise<SurgicalOrderCheckin.Model> {
    return this.services.checkin.checkin(params)
  }

  async confirmMergeDocuments(
    params: ConfirmMergeDocuments.Params
  ): Promise<ConfirmMergeDocuments.Model> {
    return this.services.confirmMergeDocuments.confirm(params)
  }

  async deleteUploadedDocuments(
    params: DeleteUploadedDocuments.Params
  ): Promise<DeleteUploadedDocuments.Model> {
    return this.services.deleteUploadedDocuments.delete(params)
  }
  async createComment(
    params: CreateSurgicalComment.Params
  ): Promise<CreateSurgicalComment.Model> {
    return this.services.createComment.create(params)
  }

  async getAgendaAllocation(
    params: GetAgendaAllocation.Params
  ): Promise<GetAgendaAllocation.Model> {
    return this.services.getAgendaAllocation.get(params)
  }

  async getAgendaClassifications(
    params?: GetAgendaClassification.Params
  ): Promise<GetAgendaClassification.Model> {
    return this.services.getAgendaClassification.get(params)
  }

  async getAllStatus(
    params?: GetAllStatus.Params
  ): Promise<GetAllStatus.Model> {
    return this.services.getAllStatus.get(params)
  }

  async findAllOpmes(
    params?: FindAllOpmes.Params
  ): Promise<FindAllOpmes.Model> {
    return this.services.findAllOpmes.findAll(params)
  }
}

export default SurgicalOrderService
