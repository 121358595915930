import { lighten } from 'polished'
import styled from 'styled-components'

type ButtonProps = {
  showAttachmentIcon: boolean
}

export const Button = styled.div<ButtonProps>`
  display: grid;
  grid-template-columns: ${({ showAttachmentIcon }) =>
    showAttachmentIcon ? '40px auto 11px' : 'auto 11px'};
  width: 100%;
  min-height: 60px;
  margin: 20px 0;
  padding: 12px 24px;
  line-height: 21px;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};

  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  :hover {
    background-color: ${({ theme }) => lighten(0.7, theme.colors.primary)};
  }
`
