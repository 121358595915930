import React from 'react'
import ReactDOM from 'react-dom'
import 'reflect-metadata/Reflect.js'
import PatientRouter from 'main/routes/patient-routes'
import { ThemeProvider } from 'styled-components'
import theme from 'presentation/styles/theme'
import GlobalStyles from 'presentation/styles/global'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import ServiceWorkerWrapper from 'presentation/shared/components/PWAContainer'

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyles mobile />
        <PatientRouter />
        <ToastContainer />
      </ThemeProvider>
    </ServiceWorkerWrapper>
  </React.StrictMode>,
  document.getElementById('root')
)
