import React from 'react'
import * as S from './styles'

export default function PreSurgeryInfo() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.PreSurgery>
          <S.Title>Orientações Pré-Cirúrgicas</S.Title>

          <S.Description>
            <strong>ORIENTAÇÕES</strong> <br />
            Fique atento sobre as orientações de horário de entrada no hospital
            e início de jejum
            <br /> <br />
            <strong>IMPORTANTE</strong> <br />
            Entrada: Av. Álvaro Maia-BOULEVARD. <br />
            Setor: Internação Eletiva
            <br />
            Guichê: 01 e 02;
            <br /> <br />
            <strong>Documentos pessoais:</strong> <br />
            - Cartão do Convênio <br />
            - RG e CPF (Acompanhante também deve apresentar RG e CPF);
            <br /> <br />
            <br />
            <strong>OBRIGATÓRIO</strong> <br />
            <br />
            <strong>Orientações ao acompanhante</strong>
            <br />
            <br />
            <S.List>
              <li>
                - Proibido trajar: Shorts, saias curtas, bermudas, camisetas e
                sandálias abertas nos corredores do hospital. Retirar todo e
                qualquer tipo de adorno (Ex.: Brinco, colar, cordão, aliança,
                piercing, etc.)
              </li>
              <li>
                - Permitido apenas 1 acompanhante maior de 18 anos e menor 60
                anos;
              </li>
              <li>
                - Faça sua higiene antes de vir ao hospital, não molhe o cabelo;
              </li>
              <li>
                - Mantenha suas unhas curtas, limpas e sem esmaltes, retirar
                cílios e unhas postiças, não deve estar com maquiagem;
              </li>
              <li>
                - Não faça *TRICOTOMIA * (depilação) nos dias que antecedem a
                cirurgia (1 semana). Se houver necessidade, o corte dos pelos
                será feito pela equipe de enfermagem, antes da cirurgia, somente
                no local indicado pelo médico;
              </li>
              <li>
                - Não utilize cremes, pomadas ou qualquer produto que possa
                interferir com a limpeza ou integridade da pele;
              </li>
              <li>
                - Traga uma troca de roupa e materiais de higiene pessoal; O
                básico para o período de internação. Acompanhante pode trazer
                lençol, paciente usara pertences do hospital.
              </li>
              <li>
                <br />- Atenção ao uso de medicamentos: Alguns poderão ser
                suspensos ou mantidos antes da cirurgia, converse com o seu
                Médico sobre;
              </li>
            </S.List>
            <br />
            <br />
            <strong>Acomodações:</strong>
            <br /> - Apartamento (Acompanhante ambos os sexos)
            <br /> - Enfermaria (Acompanhante do mesmo sexo)
            <br /> <br /> <br />
            <strong>RESERVA DE SANGUE</strong> <br />
            Se seu médico fez Solicitação e Reserva de Sangue, paciente deverá
            comparecer a Agência Transfusional do Hospital Santa Júlia, 3 dias
            antes da cirurgia. <br />
            Contato: 92993297439 - Whatsapp <br />
            Horário de funcionamento:
            <br /> Todos os dias de 09:00 às 17:00
            <br /> (Acesso pela Av. Álvaro Maia) <br />
            <br /> <br /> <br />
            <strong>
              <span style={{ color: 'red', fontSize: '1.2rem' }}>‼️</span>
              ATENÇÃO: SOMENTE SE O MÉDICO TIVER SOLICITADO{' '}
            </strong>
            <br />
            <br />
            <strong>CHECK LIST</strong> <br />
            Deve apresentar no dia do procedimento: <br />
            - Ecocardiograma ou eletrocardiograma <br />
            - Hemograma + coagulograma (conforme pedido médico) <br />
            - Exames de imagem: Ultrassom, tomografia, ressonância, raio-x
            (conforme pedido medico) <br />
            - Termo anestésico <br />
            - Risco Cardiológico <br />
            - Termo de consentimento cirúrgico <br />- Termo de hemocomponentes
            (caso solicitado reserva de sangue) <br />
            <br /> <br />
            <strong>ATENÇÃO:</strong> Todos os exames de pré-operatório possuem
            um prazo de validade de 06 meses a contar da data de realização.
            <br /> <br />
          </S.Description>
        </S.PreSurgery>
      </S.Container>
    </S.Wrapper>
  )
}
