import { GetPatientOrigins } from 'domain/usecases/patient/get-patient-origins'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export class RemoteGetPatientOrigins implements GetPatientOrigins {
  constructor(private readonly patientRepository: PatientRepository) {}

  get(params: GetPatientOrigins.Params): Promise<GetPatientOrigins.Model> {
    return this.patientRepository.getPatientOrigins(params)
  }
}
