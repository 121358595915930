import React, { HTMLAttributes } from 'react'
import * as S from './styles'

export type SupportTextProps = {
  color?:
    | 'primary'
    | 'gray'
    | 'lightGray'
    | 'red'
    | 'primaryDarker'
    | 'darkGray'
} & HTMLAttributes<HTMLParagraphElement>

const SupportText = ({
  color = 'gray',
  children,
  ...props
}: SupportTextProps) => (
  <S.Wrapper color={color} {...props}>
    {children}
  </S.Wrapper>
)

export default SupportText
