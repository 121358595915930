import { DeleteDoctorDocument } from 'domain/usecases/document/delete-doctor-document'
import { DocumentRepository } from 'repository/interfaces/document-repository'

export class RemoteDeleteDoctorDocument implements DeleteDoctorDocument {
  constructor(private readonly documentRepository: DocumentRepository) {}

  async delete(
    params: DeleteDoctorDocument.Params
  ): Promise<DeleteDoctorDocument.Model> {
    await this.documentRepository.deleteDoctorDocument(params)
  }
}
