import * as yup from 'yup'
import moment from 'moment-timezone'
import { validateCpf } from 'presentation/utils/validators/cpf-validator'

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório'
  },
  string: {
    email: 'E-mail inválido'
  }
})

yup.addMethod<yup.StringSchema>(
  yup.string,
  'cpf',
  function (message = 'CPF inválido') {
    return this.test('cpf', message, function (value) {
      return validateCpf(value)
    })
  }
)

yup.addMethod(yup.date, 'format', function (formats, parseStrict) {
  return this.transform(function (_, originalValue) {
    try {
      const value = moment(originalValue, formats, parseStrict)
      if (value.isValid()) {
        return value.toDate()
      }
      return false
    } catch (e) {
      return false
    }
  })
})
