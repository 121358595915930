import React from 'react'

import { useHistory } from 'react-router'

import List from 'presentation/shared/components/List'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import ActualPage from 'presentation/shared/components/ActualPage'
import Header from 'presentation/shared/components/Header'
import { useStores } from 'presentation/hooks/use-stores'
import { Container } from 'presentation/shared/components/Container'
import { getProfileList } from './menuItems'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import * as S from './styles'

export default function PatientProfileLayout() {
  const history = useHistory()
  const user = useStores().currentAccount.getCurrentAccount().user

  return (
    <>
      <Header />
      <Container>
        <ActualPage text="Voltar" onClick={() => history.push('/home')} />
        <ProfilePic
          data-testid="profile pic"
          outlined
          size="large"
          src="https://source.unsplash.com/random"
          style={{ marginTop: '40px' }}
        />
        <p style={{ textAlign: 'center', marginBottom: '70px' }}>{user.name}</p>
        <S.List>
          <List
            items={getProfileList(user.role)}
            size="medium"
            spacingIcon
            arrow
          />
        </S.List>
      </Container>
      <BottomMenu />
    </>
  )
}
