import { Ability } from '@casl/ability'

export class PbacAdapter {
  private ability: Ability
  constructor(private policies: string[] = []) {
    this.ability = new Ability(
      policies.map((policy) => ({
        action: policy,
        subject: 'all'
      }))
    )
  }

  can(policy: string) {
    return this.ability.can(policy, 'all')
  }

  getAbilities(): string[] {
    return this.ability.rules.map((rule) => rule.action as string)
  }
}
