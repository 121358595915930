import React from 'react'
import { Authentication } from 'domain/usecases/authentication/authentication'
import LoginForm from 'presentation/shared/components/Forms/Login'
import Heading from 'presentation/shared/components/Heading'
import Logo from 'presentation/shared/components/Logo'
import SupportText from 'presentation/shared/components/SupportText'

import * as S from './styles'
import { VerifyLoginClient } from 'domain/usecases/authentication/verify-client'
import { Profile } from 'common/enum/profile'

type LoginProps = {
  useCase?: Authentication
  verifyClient?: VerifyLoginClient
  client?: Profile
}

export default function LoginLayout({
  useCase,
  verifyClient,
  client
}: LoginProps) {
  return (
    <S.Wrapper>
      <S.Background></S.Background>
      <S.Content>
        <S.Header>
          <Logo size="large" />
          <Heading as="h1" size="large" style={{ fontWeight: 700 }}>
            Bem-vindo(a) à <br /> <strong>Jornada Cirúrgica</strong>
          </Heading>
          <SupportText>
            Acesse a plataforma para acompanhar seus processos junto aos nossos
            hospitais.
          </SupportText>
        </S.Header>
        <LoginForm
          client={client}
          useCase={useCase}
          verifyClient={verifyClient}
        />
      </S.Content>
    </S.Wrapper>
  )
}
