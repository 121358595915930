import { Minimal } from "presentation/shared/components/Timeline/stories";
import styled from "styled-components";

export const Span = styled.span`
    margin-top: 10px;
    margin-left: 20px;
    display: block;
    text-align: start !important;
`

export const Ul = styled.ul`
    list-style: circle;
    padding: 1rem 2rem;
    li {
        margin-bottom: 5px;
    }
`