import styled, { css, DefaultTheme } from 'styled-components'
import { lighten } from 'polished'
import { SelectFieldProps } from '.'

type WrapperProps = Pick<SelectFieldProps, 'disabled'> & { error?: boolean }
type SelectProps = Pick<SelectFieldProps, 'bgColor' | 'outline'>
type LabelProps = Pick<SelectFieldProps, 'labelColor'>

export const Select = styled.select<SelectProps>`
  position: relative;
  color: ${({ theme }) => theme.colors?.gray};
  font-family: ${({ theme }) => theme.font?.family};
  font-size: ${({ theme }) => theme.font?.sizes.small};
  line-height: ${({ theme }) => theme.font?.sizes.large};
  height: 2.75rem;
  padding: 0 2.5rem 0 1rem;
  background: ${({ theme, bgColor }) => theme.colors?.[bgColor!]};
  border: ${({ outline }) => (outline ? '1px solid #9b9b9b' : 'none')};
  outline: none;
  border-radius: ${({ theme }) => theme.border?.radius.xsmall};
  width: 100%;
  min-width: fit-content;
  background-image: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;

  &:focus {
    border-color: ${({ theme }) => theme.colors?.primary};
  }

  ${({ outline, theme }) => !outline && wrapperModifiers['normal'](theme)}
  &:after {
    position: absolute;
    content: '▼';
    top: 20px;
    right: 60px;
    background-color: red;
  }
`

export const Option = styled.option`
  border-radius: ${({ theme }) => theme.border?.radius.small};
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.colors?.primary};
  }
`

export const Label = styled.label<LabelProps>`
  font-size: ${({ theme }) => theme.font?.sizes.small};
  font-weight: 400;
  color: ${({ theme, labelColor }) =>
    theme.colors?.[labelColor!] ?? theme.colors?.primary};
  cursor: pointer;
  margin: 0 0 12px 0;
  display: block;
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors?.lightRed};
  font-size: ${({ theme }) => theme.font?.sizes.xsmall};
`

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${Select} {
      border: 1px solid ${theme.colors?.lightRed};
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${Select} {
      cursor: not-allowed;
      color: ${theme.colors?.gray};

      &::placeholder {
        color: currentColor;
      }
    }

    ${Select} {
      background-color: ${lighten(0.3, theme.colors?.lightGray)};
    }
  `,

  normal: (theme: DefaultTheme) => css`
    &:focus {
      outline: solid;
      outline-width: 2px;
      outline-color: ${theme.colors?.primary} !important;
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`

export const Asterisk = styled.span`
  font-size: ${({ theme }) => theme.font?.sizes.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors?.lightRed};
  cursor: pointer;
`
