import Heading from 'presentation/shared/components/Heading'
import React from 'react'
import DoctorsImage from '../../../assets/doctors.png'
import * as S from '../styled'
import { SurgeryJourney } from '../index'
import theme from 'presentation/styles/theme'

export function SurgeryDay({ goBack, goNext }: SurgeryJourney) {
  return (
    <S.Wrapper>
      <S.Header>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goBack}
        >
          <path
            d="M8 2L3 9L8 16"
            stroke={theme.colors.primary}
            strokeOpacity="0.5"
            strokeWidth="4"
          />
        </svg>
        <Heading as="h1" size="large">
          Dia da Cirurgia
        </Heading>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goNext}
        >
          <path
            d="M2 16L7 9L2 2"
            stroke={theme.colors.primary}
            strokeWidth="4"
          />
        </svg>
      </S.Header>

      <p>
        O dia da cirurgia marca o ápice do processo, envolvendo a admissão
        hospitalar e a realização do procedimento. Durante a admissão, o
        paciente é preparado para a cirurgia, passando por procedimentos
        burocráticos e de vestimenta hospitalar. A interação com a equipe de
        anestesia é crucial, onde são discutidas as opções de anestesia. A
        cirurgia em si é conduzida de acordo com o plano previamente
        estabelecido.
      </p>
      <br />
      <p>
        O encerramento desta fase marca a transição do paciente para a
        recuperação pós-anestesia.
      </p>
      <br />

      <S.Image src={DoctorsImage} alt="Médico fazendo anotações" />
    </S.Wrapper>
  )
}
