import React from 'react'

import AddPainAlertForm from 'presentation/patient/components/Forms/AddPainAlert'
import { Header } from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { AddPainAlert } from 'domain/usecases/patient/add-pain-alert'

type Props = {
  surgicalOrderId: number
  addPainAlert?: AddPainAlert
}

export default function AddPainAlertLayout({
  surgicalOrderId,
  addPainAlert
}: Props) {
  return (
    <>
      <Header />
      <Container>
        <AddPainAlertForm
          surgicalOrderId={surgicalOrderId}
          addPainAlert={addPainAlert}
        />
      </Container>
    </>
  )
}
