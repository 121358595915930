import { Document } from './document'
import { User } from 'domain/entities/user-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { Patient } from './patient-model'

export class SurgicalPendency {
  constructor(
    surgical_pendency_id: number,
    surgical_order_id: number,
    mv_surgical_pendency_id: number,
    requestedById: number,
    observation: string,
    status: SurgicalPendencyStatus,
    createdAt: string,
    answeredAt: string,
    documents: Document[],
    patient: Patient,
    type?: PendencyType,
    doctorName?: string,
    approvedAt?: string
  ) {
    this._surgical_pendency_id = surgical_pendency_id
    this._surgical_order_id = surgical_order_id
    this._mv_surgical_pendency_id = mv_surgical_pendency_id
    this._requestedById = requestedById
    this._observation = observation
    this._status = status
    this._createdAt = createdAt
    this._answeredAt = answeredAt
    this._documents = documents
    this._patient = patient
    this._type = type
    this._doctorName = doctorName
    this._approvedAt = approvedAt
  }
  private _surgical_pendency_id: number
  private _surgical_order_id: number
  private _mv_surgical_pendency_id: number
  private _doctor_id?: number
  private _patient_id?: number
  private _requestedById: number
  private _answeredById?: number
  private _approvedById?: number
  private _observation: string
  private _answer?: string
  private _status: SurgicalPendencyStatus
  private _createdAt: string
  private _answeredAt?: string
  private _subPendencyFrom?: number
  private _documents: Document[]
  private _requestedBy?: User
  private _surgicalOrder?: SurgicalOrderModel
  private _patient: Patient
  private _type?: PendencyType
  private _doctorName?: string
  private _errors?: string[]
  private _approvedAt?: string

  get surgical_pendency_id(): number {
    return this._surgical_pendency_id
  }

  set surgical_pendency_id(value: number) {
    this._surgical_pendency_id = value
  }

  get surgical_order_id(): number {
    return this._surgical_order_id
  }

  set surgical_order_id(value: number) {
    this._surgical_order_id = value
  }

  get mv_surgical_pendency_id(): number {
    return this._mv_surgical_pendency_id
  }

  set mv_surgical_pendency_id(value: number) {
    this._mv_surgical_pendency_id = value
  }

  get doctor_id(): number | undefined {
    return this._doctor_id
  }

  set doctor_id(value: number | undefined) {
    this._doctor_id = value
  }

  get patient_id(): number | undefined {
    return this._patient_id
  }

  set patient_id(value: number | undefined) {
    this._patient_id = value
  }

  get requestedById(): number {
    return this._requestedById
  }

  set requestedById(value: number) {
    this._requestedById = value
  }

  get answeredById(): number | undefined {
    return this._answeredById
  }

  set answeredById(value: number | undefined) {
    this._answeredById = value
  }

  get approvedById(): number | undefined {
    return this._approvedById
  }

  set approvedById(value: number | undefined) {
    this._approvedById = value
  }

  get observation(): string {
    return this._observation
  }

  set observation(value: string) {
    this._observation = value
  }

  get answer(): string | undefined {
    return this._answer
  }

  set answer(value: string | undefined) {
    this._answer = value
  }

  get status(): SurgicalPendencyStatus {
    return this._status
  }

  set status(value: SurgicalPendencyStatus) {
    this._status = value
  }

  get createdAt(): string {
    return this._createdAt
  }

  set createdAt(value: string) {
    this._createdAt = value
  }

  get answeredAt(): string | undefined {
    return this._answeredAt
  }

  set answeredAt(value: string | undefined) {
    this._answeredAt = value
  }

  get subPendencyFrom(): number | undefined {
    return this._subPendencyFrom
  }

  set subPendencyFrom(value: number | undefined) {
    this._subPendencyFrom = value
  }

  get documents(): Document[] {
    return this._documents
  }

  set documents(value: Document[]) {
    this._documents = value
  }

  get requestedBy(): User | undefined {
    return this._requestedBy
  }

  set requestedBy(value: User | undefined) {
    this._requestedBy = value
  }

  get surgicalOrder(): SurgicalOrderModel | undefined {
    return this._surgicalOrder
  }

  set surgicalOrder(value: SurgicalOrderModel | undefined) {
    this._surgicalOrder = value
  }

  get patient(): Patient | undefined {
    return this._patient
  }

  set patient(value: Patient | undefined) {
    this.patient = value
  }

  get type(): PendencyType | undefined {
    return this._type
  }

  set type(value: PendencyType | undefined) {
    this.type = value
  }

  get errors(): string[] | undefined {
    return this._errors
  }

  set errors(value: string[] | undefined) {
    this._errors = value
  }

  get doctorName(): string | undefined {
    return this._doctorName
  }

  set doctorName(value: string | undefined) {
    this._doctorName = value
  }

  get approvedAt(): string | undefined {
    return this._approvedAt
  }

  set approvedAt(value: string | undefined) {
    this._approvedAt = value
  }
}

export enum SurgicalPendencyStatus {
  PENDENT = 'PENDENT',
  UNRESOLVED = 'UNRESOLVED',
  RESOLVED = 'RESOLVED'
}

export const pendencyTranslator = {
  [SurgicalPendencyStatus.PENDENT]: 'Pendente',
  [SurgicalPendencyStatus.RESOLVED]: 'Resolvido',
  [SurgicalPendencyStatus.UNRESOLVED]: 'Não resolvido'
}

export const pendencyHexColor = {
  [SurgicalPendencyStatus.PENDENT]: '#9B9B9B',
  [SurgicalPendencyStatus.RESOLVED]: '#A5D8AC',
  [SurgicalPendencyStatus.UNRESOLVED]: '#FA7676'
}

export enum PendencyType {
  ASSISTANCE = 'ASSISTANCE',
  AUTHORIZATION = 'AUTHORIZATION'
}

export const pendencyTypeTranslator = {
  [PendencyType.ASSISTANCE]: 'Assistencial',
  [PendencyType.AUTHORIZATION]: 'Autorização'
}
