import { GetAllStatus } from 'domain/usecases/surgical-status/get-all-surgical-status'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteGetAllStatus implements GetAllStatus {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  get(params?: GetAllStatus.Params): Promise<GetAllStatus.Model> {
    return this.surgicalOrderRepository.getAllStatus(params)
  }
}
