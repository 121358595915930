import React from 'react'
import * as S from './styles'
import ActualPage from 'presentation/shared/components/ActualPage'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import { Switch, Route, useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import SurgeryDocumentList from 'presentation/shared/components/SurgeryDocumentList'
import SurgeryInfo from 'presentation/shared/components/SurgeryInfo'
import { ListItem } from 'presentation/shared/components/List'
import { TimelineStatusItem } from 'presentation/shared/components/Timeline'
import SupportText from 'presentation/shared/components/SupportText'
import { MedicalTeam } from 'domain/entities/surgery-model'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import { Accompanying } from 'domain/entities/accompanying-model'
import SurgicalOrderTimeline from 'presentation/shared/components/Timeline/surgical-order-timeline'

export type SurgeryDetailsLayoutProps = {
  date?: string
  expectedDate?: string
  title?: string
  hospital?: string
  surgery_id?: number
  documents?: Array<ListItem & { document_id: number; type: string }>
  statusList?: TimelineStatusItem[]
  medicalTeam?: MedicalTeam[]
  mainAccompanying?: Accompanying
  isSolicitation?: boolean
  isUrgent?: boolean
}

export default function SurgeryDetailsLayout({
  documents,
  statusList,
  medicalTeam,
  date,
  hospital,
  title,
  surgery_id,
  mainAccompanying,
  isSolicitation,
  expectedDate,
  isUrgent
}: SurgeryDetailsLayoutProps) {
  const history = useHistory()
  return (
    <>
      <Header />
      <Container noPadding>
        <S.Header>
          <ActualPage onClick={() => history.goBack()} text="Cirurgia" />
          <S.HeadInfo>
            <Heading color="primary" as="h1" size="large">
              {title}
            </Heading>
            <S.LocaleInfoBox>
              <S.LocaleInfo>
                {date && (
                  <>
                    <label>Data da cirurgia:</label>
                    <strong>{date}</strong>
                  </>
                )}
                {/* {!date && (
                  <>
                    <label>
                      {isUrgent ? 'Data de Urgência:' : 'Data prevista:'}
                    </label>
                    <strong>
                      {expectedDate ? expectedDate : 'Aguardando data'}
                    </strong>
                  </>
                )} */}
              </S.LocaleInfo>
              <S.LocaleInfo>
                <label>
                  Unidade: <strong>{hospital}</strong>
                </label>
              </S.LocaleInfo>
              <S.LocaleInfo>
                <label>
                  Número do aviso: <strong>{surgery_id}</strong>
                </label>
              </S.LocaleInfo>
            </S.LocaleInfoBox>
          </S.HeadInfo>
          <S.InfoList>
            <NavLink
              exact
              to={{ pathname: '/cirurgia', state: { id: surgery_id } }}
              activeClassName="selected"
            >
              Informações
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/cirurgia/status', state: { id: surgery_id } }}
              activeClassName="selected"
            >
              Status
            </NavLink>
            <NavLink
              exact
              to={{
                pathname: '/cirurgia/documentos',
                state: { id: surgery_id }
              }}
              activeClassName="selected"
            >
              Documentos
            </NavLink>
          </S.InfoList>
        </S.Header>
        <S.Content>
          <Switch>
            <Route
              exact
              path={`/cirurgia`}
              render={(props) => (
                <SurgeryInfo
                  medicalTeam={medicalTeam}
                  surgery_id={surgery_id}
                  mainAccompanying={mainAccompanying}
                  hospital={hospital}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={`/cirurgia/status`}
              render={(props) => (
                <S.StatusContainer>
                  <SupportText color="lightGray">
                    Acompanha o progresso da aprovação e agendamento do seu
                    procedimento.
                  </SupportText>
                  <Heading as="h2">Status da cirurgia</Heading>
                  <SurgicalOrderTimeline items={statusList} {...props} />
                </S.StatusContainer>
              )}
            />
            <Route
              exact
              path={`/cirurgia/documentos`}
              render={(props) => (
                <SurgeryDocumentList {...props} documents={documents ?? []} />
              )}
            />
          </Switch>
        </S.Content>
      </Container>
      <BottomMenu />
    </>
  )
}
