import Heading from 'presentation/shared/components/Heading'
import React, { useEffect } from 'react'
import PatientRecoveryImage from '../../../assets/patientRecovering.png'
import * as S from '../styled'
import { SurgeryJourney } from '../index'
import theme from 'presentation/styles/theme'

export function HomeRecovery({ goBack, goNext }: SurgeryJourney) {
  useEffect(() => {
    document.getElementById('container-content').scrollTop = 0
  })
  return (
    <S.Wrapper>
      <S.Header>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goBack}
        >
          <path
            d="M8 2L3 9L8 16"
            stroke={theme.colors.primary}
            strokeOpacity="0.5"
            strokeWidth="4"
          />
        </svg>
        <Heading as="h1" size="large">
          Recuperação em Casa
        </Heading>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goNext}
        ></svg>
      </S.Header>

      <p>
        A fase de recuperação em casa é caracterizada pela implementação das
        orientações médicas recebidas durante a alta hospitalar. O paciente
        segue o plano de cuidados, incluindo a administração de medicamentos, o
        repouso adequado e, se aplicável, a continuação da fisioterapia.
      </p>
      <br />
      <p>
        Durante essa etapa, é crucial seguir as instruções para prevenir
        complicações, monitorando atentamente qualquer sinal de alerta e
        mantendo as consultas de acompanhamento conforme recomendado. O suporte
        emocional e a comunicação contínua com a equipe médica desempenham um
        papel essencial na recuperação em casa, assegurando que o paciente se
        sinta apoiado e que qualquer preocupação seja prontamente abordada. Essa
        fase visa restabelecer gradualmente a normalidade, promovendo uma
        recuperação completa e sustentável.
      </p>
      <br />

      <S.Image src={PatientRecoveryImage} alt="Médico fazendo anotações" />
    </S.Wrapper>
  )
}
