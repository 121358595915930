import React, { useEffect, useState } from 'react'

import Button from 'presentation/shared/components/Button'
import TextButton from 'presentation/shared/components/TextButton'
import Header from 'presentation/shared/components/Header'

import SelectField from 'presentation/shared/components/SelectField'
import { useStores } from 'presentation/hooks/use-stores'
import { Hospital } from 'domain/entities/hospital-model'
import { Container } from 'presentation/shared/components/Container'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'

export type CreateSurgicalOrderIntroTypes = {
  title: string
  description: string
  orderFn: () => void
  goBack: () => void
} & WithLoadingProps

function CreateSurgicalOrderIntro({
  title,
  description,
  orderFn,
  goBack,
  setIsLoading
}: CreateSurgicalOrderIntroTypes) {
  const [selectedHospital, setSelectedHospital] = useState<number>()
  const [hospitals, setHospitals] = useState<Hospital[]>([])

  const currentHospital = useStores().currentHospital

  const loadHospitals = async () => {
    await currentHospital.load()
    setHospitals(currentHospital.hospitalList)
  }

  const handleSelectHospital = (selectedHospital: number) => {
    orderFn()
    currentHospital.setHospitalSelected(selectedHospital)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        await loadHospitals()
      } catch (e) {
        toast.error('Erro ao buscar os hospitais')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <Header />
      <Container
        title={title}
        subtitle={description}
        titleColor={'primaryDarker'}
        primaryButton={
          <Button
            onClick={() => handleSelectHospital(selectedHospital ?? 0)}
            fullWidth
            disabled={!selectedHospital}
          >
            Iniciar
          </Button>
        }
        secondaryButton={
          <TextButton
            style={{ padding: '10px 0' }}
            onClick={goBack}
            size="medium"
          >
            Cancelar
          </TextButton>
        }
        justifyContent="center"
      >
        <SelectField
          name="hospital"
          label="Hospital"
          items={hospitals?.map((hospital) => ({
            label: hospital.nickname,
            value: hospital.hospital_id
          }))}
          onInputChange={(e) => setSelectedHospital(Number(e))}
          value={selectedHospital || ''}
          required
        />
      </Container>
    </>
  )
}

export default WithLoading(CreateSurgicalOrderIntro)
