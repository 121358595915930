import React from 'react'

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Heading from 'presentation/shared/components/Heading'
import { useHistory } from 'react-router'
import * as S from './styles'
import { medicalTeamTypeDictionary } from 'presentation/utils/professional'

type MedicalTeamReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function MedicalTeamReview({
  surgicalOrder,
  visualizationMode
}: MedicalTeamReviewProps) {
  const history = useHistory()

  return (
    <S.Container>
      <h6 style={{ marginTop: '32px' }}>Cirurgia conjugada:</h6>
      <span style={{ marginLeft: '8px' }}>
        {surgicalOrder.medicalTeamName ? 'Sim' : 'Não'}
      </span>
      {surgicalOrder.medicalTeamName ? (
        <>
          <br />
          <h6 style={{ marginTop: '10px' }}>Nome da equipe médica:</h6>
          <br />
          <span style={{ margin: '10px 0px' }}>
            {surgicalOrder.medicalTeamName || '-'}
          </span>
        </>
      ) : null}
      <header>
        <Heading style={{ margin: '10px 0' }} as="h2" weight={500}>
          Equipe Médica
        </Heading>
        {!visualizationMode && (
          <small
            onClick={() =>
              history.push('/pedido-cirurgico/equipe-medica', {
                surgical_order_id: surgicalOrder.surgical_order_id,
                surgicalOrder
              })
            }
          >
            Editar
          </small>
        )}
      </header>
      {!surgicalOrder.medicalTeam?.length ? (
        <span> Não há equipe médica cadastrada</span>
      ) : null}
      <S.TableContainer>
        {surgicalOrder.medicalTeam?.map((medicalTeam, index) => {
          return (
            <>
              <S.MedicalTeamRow key={index}>
                <div>{medicalTeam.name}</div>
                <label>{medicalTeamTypeDictionary[medicalTeam.type]}</label>
              </S.MedicalTeamRow>
              {index < surgicalOrder.medicalTeam.length - 1 ? <hr /> : null}
            </>
          )
        })}
      </S.TableContainer>

      <header>
        <Heading style={{ margin: '20px 0 10px' }} as="h2" weight={500}>
          {surgicalOrder.anesthetistTeam
            ? 'Anestesista Externo'
            : 'Anestesista de Casa'}
        </Heading>
        {!visualizationMode && (
          <small
            onClick={() =>
              history.push('/pedido-cirurgico/equipe-medica', {
                surgical_order_id: surgicalOrder.surgical_order_id,
                surgicalOrder
              })
            }
          >
            Editar
          </small>
        )}
      </header>
      <S.TableContainer>
        {surgicalOrder.anesthetistTeam?.map((anesthetistTeam, index) => {
          return (
            <>
              <S.AnesthetistTeamRow key={index}>
                <label>{anesthetistTeam.name}</label>
                <div>
                  <div>
                    <strong>CRM</strong>
                    <p>{anesthetistTeam.crm}</p>
                  </div>
                  <div>
                    <strong>Telefone</strong>
                    <p>{anesthetistTeam.phone}</p>
                  </div>
                  <div>
                    <strong>Tipo de anestesia</strong>
                    <p>{anesthetistTeam.anesthesiaType}</p>
                  </div>
                </div>
              </S.AnesthetistTeamRow>
              {index < surgicalOrder.anesthetistTeam.length - 1 ? <hr /> : null}
            </>
          )
        })}
      </S.TableContainer>
    </S.Container>
  )
}
