import styled from 'styled-components'
import theme from 'presentation/styles/theme'
import { lighten } from 'polished'

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  height: 100%;
`
export const ServiceOption = styled.div`
  padding: 10px 5px 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const Icon = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  margin-right: 5px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${lighten(0.2, theme.colors.lightSecondary)};
  img {
    svg {
      path {
        fill: red;
      }
    }
  }
`
export const Title = styled.div`
  margin-left: 10px;
`
export const DividingLine = styled.hr`
  width: 100%;
  border: 0;
  height: 1px;
  background: ${theme.colors.lightestGray};
`
