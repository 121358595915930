import React, { useState } from 'react'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import OnboardingPage from 'presentation/shared/pages/Onboarding'
import FirstSlide from 'presentation/assets/onboarding-images/welcome.svg'
import SecondSlide from 'presentation/assets/onboarding-images/schedule.svg'
import ThirdSlide from 'presentation/assets/onboarding-images/exams.svg'
import FourthSlide from 'presentation/assets/onboarding-images/attendance.svg'
import FifthSlide from 'presentation/assets/onboarding-images/journey.svg'
import SixthSlide from 'presentation/assets/onboarding-images/attendance-confirmation.svg'
import { FinishAppOnboarding } from 'domain/usecases/user/finish-app-onboarding'
import * as S from './styles'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

type OnboardingLayoutProps = {
  finishAppOnboarding: FinishAppOnboarding
}
export default function OnboardingLayout({
  finishAppOnboarding
}: OnboardingLayoutProps) {
  const [carouselState, setCarouselState] = useState({} as CarouselState)
  const history = useHistory()

  const handleGoBack = () => carouselState.slidePrev()
  const handleNext = () => carouselState.slideNext()
  const handleClickOnButton = () =>
    finishAppOnboarding
      .finishAppOnboarding()
      .then(() => history.push('/home'))
      .catch((error) => toast.error(error.message))

  return (
    <S.Wrapper>
      <Carousel
        state={carouselState}
        setState={setCarouselState}
        slidesPerView={1}
        touch={false}
        pagination={
          !carouselState.isLast
            ? {
                clickable: true
              }
            : false
        }
        fade={{ crossFade: true }}
        effect={'fade'}
        speed={1000}
        containerStyle={{ background: '#fff' }}
      >
        <OnboardingPage
          title={'Seja bem-vindo(a)!'}
          body={
            <S.TextContainer>
              <p>
                Agora você faz parte do <span>Jornada Cirúrgica</span>, a nossa
                linha de cuidado!
              </p>
              <p>
                Conheça algumas facilidades que você terá ao utilizar essa
                plataforma!
              </p>
            </S.TextContainer>
          }
          image={FirstSlide}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Agendar consultas e exames'}
          body={
            <S.TextContainer>
              <p>
                Você poderá <span>agendar</span> suas consultas e exames de
                maneira <span>simplificada</span> e via <span>Whatsapp.</span>
              </p>
            </S.TextContainer>
          }
          image={SecondSlide}
          goBackAction={handleGoBack}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Atendimentos agendados'}
          body={
            <S.TextContainer>
              <p>
                Você também conseguirá visualizar todos os{' '}
                <span>agendamentos</span> das suas consultas e os{' '}
                <span>detalhes</span> de cada uma delas.
              </p>
            </S.TextContainer>
          }
          image={FourthSlide}
          goBackAction={handleGoBack}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Jornada cirúrgica 100% digital'}
          body={
            <S.TextContainer>
              <p>
                Em poucos minutos é possível <span>realizar a solicitação</span>{' '}
                do pedido cirúrgico, acompanhar em <span>tempo real</span> o{' '}
                <span>status</span> do seu pedido e conferir a documentação para{' '}
                <span>autorização</span> do seu procedimento.
              </p>
            </S.TextContainer>
          }
          image={FifthSlide}
          goBackAction={handleGoBack}
          slideNext={handleNext}
          buttonAction={handleClickOnButton}
        />
        <OnboardingPage
          title={'Confirmação de presença'}
          body={
            <S.TextContainer>
              <p>
                Para <span>agilizar</span> ainda mais o seu atendimento, você
                poderá <span>nos avisar</span> da sua{' '}
                <span>chegada ao hospital</span> via check-in.
              </p>
            </S.TextContainer>
          }
          image={SixthSlide}
          goBackAction={handleGoBack}
          showButton
          buttonAction={handleClickOnButton}
        />
      </Carousel>
    </S.Wrapper>
  )
}
