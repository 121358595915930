import React, { AnchorHTMLAttributes } from 'react'
import * as S from './styles'
import theme from '../../../styles/theme'

type HeadingTypes = AnchorHTMLAttributes<HTMLAnchorElement>

export type HeadingProps = {
  children: React.ReactNode
  color?:
    | 'primary'
    | 'gray'
    | 'lightGray'
    | 'primaryDarker'
    | keyof typeof theme.colors
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'huge'
  flexStart?: boolean
  as?: React.ElementType
  required?: boolean
  weight?: number
} & HeadingTypes

const Heading = ({
  children,
  required,
  color = 'gray',
  size = 'medium',
  weight = 700,
  ...props
}: HeadingProps) => (
  <S.Wrapper
    {...props}
    color={color}
    size={size}
    required={required}
    weight={weight}
  >
    {children}
    {required && <span>*</span>}
  </S.Wrapper>
)

export default Heading
