import React from 'react'

import { useHistory } from 'react-router-dom'

import { Container } from 'presentation/shared/components/Container'
import Button from 'presentation/shared/components/Button'
import * as S from './styles'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { SurgicalPendencyHeader } from 'presentation/doctor/components/SurgicalPendency/SurgicalPendencyHeader'
import { useFormikContext } from 'formik'
import SupportText from 'presentation/shared/components/SupportText'
import AdvancedButton from 'presentation/shared/components/AdvancedButton'
import Header from 'presentation/shared/components/Header'

export type Props = {
  pendency: SurgicalPendency
  appendMode: () => void
  hasDocuments?: boolean
}

export const AnswerSurgicalPendencyLayout = ({
  pendency,
  appendMode,
  hasDocuments
}: Props) => {
  const history = useHistory()
  const { submitForm, values, handleChange } = useFormikContext<{
    answer: ''
  }>()
  return (
    <>
      <Header />
      <Container
        title="Pendência cirúrgica"
        primaryButton={
          <Button
            fullWidth
            disabled={!values.answer || !!pendency.answeredAt}
            type="button"
            onClick={submitForm}
          >
            Enviar
          </Button>
        }
        actualPageText="Voltar"
        actualPageOnClick={history.goBack}
      >
        <SurgicalPendencyHeader pendency={pendency} />
        <S.TextArea
          label="Pendência"
          disabled
          rows={3}
          style={{ marginBottom: '20px' }}
          value={pendency.observation}
        />
        <S.TextArea
          label="Retorno"
          rows={3}
          name="answer"
          id="answer"
          onChange={handleChange}
          value={values.answer}
          disabled={!!pendency.answeredAt}
        />
        {!pendency.answeredAt && (
          <AdvancedButton
            showAttachmentIcon={hasDocuments}
            onClick={appendMode}
          />
        )}
        {!!pendency.answeredAt && (
          <SupportText
            color="primary"
            style={{ margin: 'auto 0', textAlign: 'center' }}
          >
            Pendência já respondida
          </SupportText>
        )}
      </Container>
    </>
  )
}
