import React, { useEffect, useState } from 'react'

import { LoadPatientDocuments } from 'domain/usecases/patient/load-patient-documents'

import SurgeryDetailsLayout, {
  SurgeryDetailsLayoutProps
} from 'presentation/shared/layouts/SurgeryDetails'
import { ListItem } from 'presentation/shared/components/List'
import {
  patientDocumentList,
  patientDocumentSolicitationList
} from './documents'
import { useHistory } from 'react-router'
import { LoadSurgeryDetails } from 'domain/usecases/surgery/load-surgery-details'
import moment from 'moment-timezone'
import { setDocListIcon } from 'presentation/utils/set-doclist-icon'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import { useLocation } from 'react-router-dom'
import { Hospital } from 'domain/entities/hospital-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'
import downloadFileFromBlob from '../../../../common/utils/downloadFileFromBlob'
import { getDocumentsLabelLocationByEnum } from '../../../utils/document-types-location'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'

type SurgeryDetailsProps = {
  loadPatientDocuments: LoadPatientDocuments
  loadSurgeryDetails: LoadSurgeryDetails
  loadSurgicalOrder: LoadSurgicalOrder
  loadSurgicalOrderStatus: LoadSurgicalOrderStatus
}

const SurgeryDetails = WithLoading(
  ({ setIsLoading }: SurgeryDetailsProps & WithLoadingProps) => {
    const [patientDocuments, setPatientDocuments] = useState(
      [] as Array<ListItem & { document_id: number; type: string }>
    )
    const [surgeryInfo, setSurgeryInfo] = useState<SurgeryDetailsLayoutProps>()
    const location = useLocation<{ id: number }>()
    const history = useHistory()
    const surgicalOrderService = useServices().surgicalOrder
    const currentUser = useStores().currentAccount
    const isPatient =
      currentUser.getCurrentAccount()?.user?.role === Profile.PATIENT

    useEffect(() => {
      const loadData = async () => {
        try {
          setIsLoading(true)

          const loadSurgery = async () => {
            const surgicalOrderDetails =
              await surgicalOrderService.loadSurgicalOrder(location.state.id, [
                'hospital{name, hospital_id, nickname}',
                'procedure{description}',
                'surgical_order_id',
                'surgeryDate',
                'expectedDate',
                'status{status}',
                'hospitalizationType',
                'patient {mainAccompanying {name}}',
                'documents {document_id, type, name, documentUri, isMerged, group_id}',
                'isSolicitation'
              ])

            setSurgeryInfo({
              medicalTeam: [],
              hospital: new Hospital(
                surgicalOrderDetails.hospital?.hospital_id ?? 0,
                surgicalOrderDetails.hospital?.nickname ?? '',
                surgicalOrderDetails.hospital?.name ?? ''
              ).name,
              title:
                surgicalOrderDetails.procedure &&
                surgicalOrderDetails.procedure[0].description.replaceAll(
                  '"',
                  ''
                ),
              mainAccompanying: surgicalOrderDetails.patient?.mainAccompanying,
              surgery_id: location.state.id,
              statusList: surgicalOrderDetails?.status,
              documents: (surgicalOrderDetails?.documents as any[]) ?? [],
              date: surgicalOrderDetails.surgeryDate,
              expectedDate: surgicalOrderDetails.expectedDate[0],
              isSolicitation: surgicalOrderDetails.isSolicitation,
              isUrgent: surgicalOrderDetails.hospitalizationType === 'urgency'
            })
          }

          await loadSurgery()
        } catch (err) {
          // history.push('/home')
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    const getDocumentList = () => {
      if (isPatient && surgeryInfo?.isSolicitation)
        return patientDocumentSolicitationList
      return patientDocumentList
    }

    const formatDocumentsList = () => {
      const formattedDocumentList = getDocumentList().map((item) => ({
        title: item.name,
        icon: setDocListIcon(
          item.type,
          item.upload,
          surgeryInfo?.documents ?? []
        ),
        click: () => {
          if (item.upload) {
            history.push(item.url, {
              surgicalOrderId: location.state.id,
              documents: surgeryInfo?.documents,
              isSolicitation: surgeryInfo?.isSolicitation
            })
          } else {
            const document_id = Number(
              surgeryInfo?.documents?.reverse().find((doc) => {
                return item.type
                  .map((val) => val.toLowerCase())
                  .includes(doc.type.toLowerCase())
              })?.document_id
            )
            if (document_id) {
              surgicalOrderService
                .loadSurgicalOrderDocument({
                  surgical_order_id: Number(surgeryInfo?.surgery_id),
                  document_id
                })
                .then((res) => {
                  downloadFileFromBlob(
                    res.data,
                    res.contentType,
                    getDocumentsLabelLocationByEnum(item.type[0])
                  )
                })
            }
          }
        }
      }))

      setPatientDocuments(
        formattedDocumentList as unknown as Array<
          ListItem & { document_id: number; type: string }
        >
      )
    }

    useEffect(() => {
      formatDocumentsList()
    }, [surgeryInfo])

    return (
      <SurgeryDetailsLayout
        statusList={surgeryInfo?.statusList}
        documents={patientDocuments}
        mainAccompanying={surgeryInfo?.mainAccompanying}
        medicalTeam={surgeryInfo?.medicalTeam}
        hospital={surgeryInfo?.hospital}
        title={surgeryInfo?.title}
        date={
          surgeryInfo?.date &&
          moment(surgeryInfo.date).utc(true).format('DD/MM/YYYY HH:mm')
        }
        expectedDate={
          surgeryInfo?.expectedDate.length &&
          moment(surgeryInfo.expectedDate[0]).utc(true).format('DD/MM/YYYY')
        }
        surgery_id={surgeryInfo?.surgery_id}
        isSolicitation={surgeryInfo?.isSolicitation}
        isUrgent={surgeryInfo?.isUrgent}
      />
    )
  }
)

export default SurgeryDetails
