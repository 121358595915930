import { lighten } from 'polished'
import styled, { css, DefaultTheme } from 'styled-components'
import { TimelineProps } from '.'

export const Wrapper = styled.div<TimelineProps>`
  display: flex;
  justify-content: space-between;
  ${({ theme, minified }) =>
    !minified && wrapperModifiers.minified.container(theme)}
`

type DotProps = {
  color?: string
  done: boolean
} & TimelineProps

export const Dot = styled.div<DotProps>`
  width: 6px;
  height: 6px;
  position: relative;
  justify-self: center;
  background-color: ${({ theme, color, done }) =>
    !!color && !!done ? color : theme.colors.lightGray};
  border-radius: ${({ theme }) => theme.border.radius.rounded};
  flex-shrink: 0;

  margin-right: 1rem;

  ${({ theme, minified, done, color }) =>
    !minified && wrapperModifiers.minified.dot(theme, done, color)}
`

export const StatusLabel = styled.strong`
  letter-spacing: -0.5px;
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  line-height: 16px;
`

type TimelineItemProps = {
  done: boolean
  actual: boolean
} & TimelineProps

export const TimelineItem = styled.div<TimelineItemProps>`
  display: flex;
  align-items: center;
  width: 100%;

  &:first-child {
    width: 6px;
  }

  &:not(:first-child) ${Dot}::before {
    position: absolute;
    content: none;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px
      ${({ theme, done }) =>
        done
          ? 'solid' + lighten(0.2, theme.colors.gray)
          : 'dashed' + lighten(0.2, theme.colors.lightGray)};
    width: 2px;
    height: 48px;
  }

  ${StatusLabel} {
    color: ${({ theme, done }) =>
      done ? theme.colors.gray : theme.colors.lightGray};
  }

  ${({ theme, actual }) => actual && wrapperModifiers.actual(theme)}
  ${({ minified }) => !minified && wrapperModifiers.minified.timelineItem()}
`

export const SupportButton = styled.div``

export const DescriptionContainer = styled.aside`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 5px;
  align-items: center;
`

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 14px;
`

export const DoneDate = styled.span`
  letter-spacing: -0.5px;
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  color: #c4c4c4;
  text-align: left !important;
`

export const Bar = styled.hr<Pick<TimelineItemProps, 'done'>>`
  border: none;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => lighten(0.2, theme.colors.lightGray)};
  ${({ theme, done }) => done && wrapperModifiers.minified.bar(theme)}
`

const wrapperModifiers = {
  minified: {
    container: (theme: DefaultTheme) => css`
      flex-direction: column;
      background-color: white;
      padding: 34px 20px 0;
      padding-left: 10px;
      border-radius: ${theme.border.radius.small};
    `,
    timelineItem: () => css`
      display: flex;
      align-items: center;
      padding: 0 1rem;
      width: 100%;
      align-items: flex-start;
      &:first-child {
        width: 100%;
      }
      /* &:not(:first-child) ${Dot}::before {
        content: '';
      } */
    `,
    dot: (theme: DefaultTheme, done: boolean, color?: string) => css`
      width: 12px;
      height: 12px;
      margin-top: 2px;

      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid ${!!color && done ? color : theme.colors.lightGray};
        width: 16px;
        height: 16px;
        border-radius: ${theme.border.radius.rounded};
      }
    `,
    bar: (theme: DefaultTheme) => css`
      background-color: ${theme.colors.primary};
    `
  },
  actual: (theme: DefaultTheme) => css`
    ${StatusLabel} {
      font-weight: ${theme.font.black};
    }
  `
}

export const ActualStatusTag = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.primaryDarker};
    display: inline;
    padding: 2px 5px;
    border-radius: ${theme.border.radius.small};
    font-size: ${theme.font.sizes.xxsmall} !important;
    margin-left: 1rem;
  `}
`
