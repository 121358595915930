import React, { useEffect, useState } from 'react'

import * as S from './styles'
import Modal from 'presentation/shared/components/Modal'
import { CircularProgress } from '@material-ui/core'

type Props = {
  show: boolean
}

export default function LoadingModal({ show }: Props) {
  const [dots, setDots] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots.length === 3 ? '' : dots + '.'))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Modal
      show={show}
      radius="10px"
      style={{
        zIndex: 99999
      }}
      animate={false}
    >
      <S.Wrapper>
        <S.LoadingText
          style={{ color: '#4A4A4A', fontWeight: 600, fontSize: '22px' }}
        >
          Carregando{dots}
        </S.LoadingText>
        <S.LoadingContainer>
          <CircularProgress variant={'indeterminate'} size={'5rem'} />
        </S.LoadingContainer>
      </S.Wrapper>
    </Modal>
  )
}
