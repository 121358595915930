import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'

import * as S from './styles'

type Items = {
  label: string
  value: string | ReadonlyArray<string> | number
  isChecked?: boolean
}

export type MultipleSelectProps = {
  label?: string
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string | false | string[]
  items?: Items[]
  initialValue?: any[]
} & InputHTMLAttributes<HTMLInputElement>

export type MultipleSelectRefProps = {
  resetMultipleSelect?: () => void
}

const MultipleSelect: React.ForwardRefRenderFunction<
  MultipleSelectRefProps,
  MultipleSelectProps
> = (
  { label, onCheck, items, error, initialValue, ...props }: MultipleSelectProps,
  forwardedRef
) => {
  const [checkedItems, setCheckedItems] = useState(
    initialValue ?? ([] as string[])
  )

  useEffect(() => {
    if (initialValue) {
      if (checkedItems.length === 0) {
        setCheckedItems(initialValue)
      }
    }
  }, [initialValue])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, e.target.value])
    } else {
      setCheckedItems([
        ...checkedItems.filter((val) => !(val == e.target.value))
      ])
    }
    !!onCheck && onCheck(e)
  }

  const checked = (value: string | ReadonlyArray<string> | number) =>
    !!checkedItems.find((val) => val == value)

  const resetMultipleSelect = () => {
    setCheckedItems([])
  }

  useImperativeHandle(forwardedRef, () => ({
    resetMultipleSelect
  }))

  return (
    <S.Wrapper>
      {!!label && (
        <S.Label>
          {label} {props.required ? <S.Asterisk>*</S.Asterisk> : ''}
        </S.Label>
      )}
      <S.InputsContainer>
        {items?.map((item) => (
          <React.Fragment key={item.label}>
            <S.Input
              type="checkbox"
              name={props.name}
              onChange={onChange}
              checked={checked(item.value)}
              value={item.value}
              id={item.label}
              {...props}
            />
            <S.InputLabel checked={checked(item.value)} htmlFor={item.label}>
              {item.label}
            </S.InputLabel>
          </React.Fragment>
        ))}
      </S.InputsContainer>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}

export default forwardRef(MultipleSelect)
