import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { HospitalRepository as IHospitalRepository } from 'repository/interfaces/hospital-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { loadHospitalsQuery } from 'repository/graphql/queries/index'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { ChangeHospital } from 'domain/usecases/hospital/change-hospital'
import { changeSelectedHospitalMutation } from 'repository/graphql/mutations'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'

export class HospitalRepository implements IHospitalRepository {
  constructor(private readonly apiRepository: IApiRepository) {}
  async changeHospital(
    params: ChangeHospital.Params
  ): Promise<ChangeHospital.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ChangeHospital.Model>

    const query = changeSelectedHospitalMutation(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.hospital_id, 'hospital_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ChangeHospital.Model
    }
  }

  async loadHospitals(
    params: LoadHospitals.Params
  ): Promise<LoadHospitals.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadHospitals.Model>

    const query = loadHospitalsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadHospitals.Model
    }
  }
}
