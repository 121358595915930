import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import SurgicalStatusContext from 'presentation/contexts/surgical-status/surgical-status-context'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteGetAllStatus } from 'service/usecases/get-all-status/remote-get-all-status'

export default function makeSurgicalStatus() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const getAllStatus = new RemoteGetAllStatus(surgicalOrderRepository)

  return new SurgicalStatusContext(getAllStatus)
}
