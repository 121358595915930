import styled, { css, DefaultTheme } from 'styled-components'
import { SupportTextProps } from '.'

export const Wrapper = styled.p<SupportTextProps>`
  ${({ theme, color }) => css`
    line-height: 24px;
    letter-spacing: -0.4px;
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.small};
    ${!!color && wrapperModifiers[color](theme)}
    font-weight: 400;
  `}
`

const wrapperModifiers = {
  primary: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary} !important;
  `,
  gray: (theme: DefaultTheme) => css`
    color: ${theme.colors.gray} !important;
  `,
  lightGray: (theme: DefaultTheme) => css`
    color: ${theme.colors.lightGray} !important;
  `,
  red: (theme: DefaultTheme) => css`
    color: ${theme.colors.red} !important;
  `,
  primaryDarker: (theme: DefaultTheme) => css`
    color: ${theme.colors.primaryDarker} !important;
  `,
  darkGray: (theme: DefaultTheme) => css`
    color: ${theme.colors.darkGray} !important;
  `
}
