import React, { HTMLAttributes } from 'react'

import * as S from './styles'
import { getUrlDomain } from 'common/utils/getUrlDomain'

export type LogoProps = {
  size?: 'normal' | 'large' | 'huge'
} & HTMLAttributes<HTMLDivElement>

const Logo = ({ size = 'normal', ...props }: LogoProps) => {
  const domain = getUrlDomain()

  return (
    <S.Wrapper size={size} {...props}>
      <img
        src={`https://storage.googleapis.com/gh-jc-public-test/logo/${domain}.svg`}
        alt={`Logo ${domain}`}
        width="100%"
        height="100%"
      />
    </S.Wrapper>
  )
}

export default Logo
