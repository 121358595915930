import React, { useEffect, useState } from 'react'

import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import UpdatePersonalInfoLayout from 'presentation/patient/layouts/UpdatePersonalInfo'
import { Patient } from 'domain/entities/patient-model'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'

type Props = {
  updatePatientInfo: UpdatePatientInfo
  loadPatient: LoadPatientInfo
}

export default function UpdatePersonalInfo({
  updatePatientInfo,
  loadPatient
}: Props) {
  const [patient, setPatient] = useState<Patient>()

  useEffect(() => {
    async function loadData() {
      setPatient(
        await loadPatient.load([
          'name',
          'lastName',
          'birthday',
          'maritalStatus',
          'children',
          'gender',
          'weight',
          'height',
          'phone',
          'email',
          'schooling',
          'company',
          'religion',
          'needAttention',
          'additionalObservation',
          'communicationParticularity',
          'profession'
        ])
      )
    }

    loadData()
  }, [])
  // if (!state?.surgicalOrderId) {
  //   history.goBack()
  // }

  return (
    <UpdatePersonalInfoLayout
      updatePersonalInfo={updatePatientInfo}
      patient={patient ?? ({} as Patient)}
    />
  )
}
