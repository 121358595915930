/* eslint-disable */

import React from 'react'
// import { PreSurgicalOrientation } from './preSurgicalOrientation'
// import { OPMEOrientation } from './OPMEOrientation'
// import { HospitalAnalysisOrientation } from './hospitalAnalysis'
// import { HealthInsuranceAnalysis } from './healtInsuranceAnalysi'
// import { SurgicalAuthorization } from './authorizationSurgical'
// import { ShceduleOrientation } from './shcedule'
// import { CheckInOrientation } from './checkInOrientation'
// import { PatientSurgency } from './patientSurgency'
// // import { PatientRecovering } from './patientRecoveringAnesthesia'
// import { DischargeOrientation } from './dischargeOrientation'
// import { ServiceAvaliationOrientation } from './serviceAvaliationOrientation'
// import { FastpassOrientation } from './fastpass'
// import { PatientRecovering } from './patientRecoveringAnesthesia'
import {
  HomeRecovery,
  HospitalDischarge,
  HospitalRecovery,
  ImmediatePostoperative,
  PreoperativeConsultationsAndExams,
  SurgeryDay
} from './new'
import { CarouselState } from 'presentation/shared/components/Carousel'

export const orientationSteps = (carousel: CarouselState) => [
  <PreoperativeConsultationsAndExams
    goBack={carousel.slidePrev}
    goNext={carousel.slideNext}
  />,
  <SurgeryDay goBack={carousel.slidePrev} goNext={carousel.slideNext} />,
  <ImmediatePostoperative
    goBack={carousel.slidePrev}
    goNext={carousel.slideNext}
  />,
  <HospitalRecovery goBack={carousel.slidePrev} goNext={carousel.slideNext} />,
  <HospitalDischarge goBack={carousel.slidePrev} goNext={carousel.slideNext} />,
  <HomeRecovery goBack={carousel.slidePrev} goNext={carousel.slideNext} />
  // <PreSurgicalOrientation
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <OPMEOrientation goBack={carousel.slidePrev} goNext={carousel.slideNext} />,
  // <HospitalAnalysisOrientation
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <HealthInsuranceAnalysis
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <SurgicalAuthorization
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <ShceduleOrientation
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <CheckInOrientation
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <FastpassOrientation
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <PatientSurgency goBack={carousel.slidePrev} goNext={carousel.slideNext} />,
  // <PatientRecovering goBack={carousel.slidePrev} goNext={carousel.slideNext} />,
  // <DischargeOrientation
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />,
  // <ServiceAvaliationOrientation
  //   goBack={carousel.slidePrev}
  //   goNext={carousel.slideNext}
  // />
]

export type SurgeryJourney = {
  goBack: () => void
  goNext: () => void
}
