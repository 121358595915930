import styled, { css } from 'styled-components'
import background from '../../../assets/images/login-background.png'

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1.5fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

export const Content = styled.div`
  max-width: 31.25rem;
  margin: 0 auto;
  padding: 1.25rem 1.5rem;
`

export const Background = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;

  @media (max-width: 1200px) {
    display: none;
  }
`

export const Header = styled.div`
  ${({ theme }) => css`
    div ~ svg {
      display: inline-block;
    }
    h1 {
      margin: 2.5rem 0 1rem 0;
      strong {
        color: ${theme.colors.primary};
      }
    }
    p {
      max-width: 22rem;
    }
  `}
`
