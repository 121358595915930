import styled, { css, DefaultTheme } from 'styled-components'

import { HeadingProps } from '.'

export const wrapperModifiers = {
  xsmall: (theme: DefaultTheme) => css`
    font-size: ${theme.font?.sizes.xsmall};
  `,

  small: (theme: DefaultTheme) => css`
    font-size: ${theme.font?.sizes.small};
  `,

  medium: (theme: DefaultTheme) => css`
    font-size: ${theme.font?.sizes.medium};
  `,

  large: (theme: DefaultTheme) => css`
    font-size: ${theme.font?.sizes.large};
  `,

  xlarge: (theme: DefaultTheme) => css`
    font-size: ${theme.font?.sizes.xlarge};
  `,

  huge: (theme: DefaultTheme) => css`
    font-size: ${theme.font?.sizes.xxlarge};
  `
}

export const Wrapper = styled.h2<HeadingProps>`
  ${({ theme, color, size, weight }) => css`
    color: ${theme.colors?.[color!]};
    font-weight: ${weight};

    ${!!size && wrapperModifiers[size](theme)}
    span {
      color: ${({ theme }) => theme.colors?.red};
    }
  `}

  ${({ flexStart }) =>
    flexStart &&
    css`
      display: flex;
      align-self: flex-start;
    `}
`
