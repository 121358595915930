import { LoadDoctorSurgicalOrders } from 'domain/usecases/doctor/load-doctor-surgical-orders'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadDoctorSurgicalOrders
  implements LoadDoctorSurgicalOrders
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    params: LoadDoctorSurgicalOrders.Params
  ): Promise<LoadDoctorSurgicalOrders.Model> {
    return this.doctorRepository.loadDoctorSurgicalOrders(params)
  }
}

export type LoadDoctorSurgicalOrdersModel = LoadDoctorSurgicalOrders.Model
