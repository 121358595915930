import React from 'react'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Heading from 'presentation/shared/components/Heading'

import * as S from '../styles'
import { addPlural } from 'presentation/utils/add-plural'
import { useHistory } from 'react-router-dom'

type ReservationInfoReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function ReservationInfoReview({
  surgicalOrder,
  visualizationMode
}: ReservationInfoReviewProps) {
  const history = useHistory()

  return (
    <>
      <S.ReviewHeader>
        <Heading weight={500}>Caráter da Reserva</Heading>
        {!visualizationMode && (
          <small
            onClick={() =>
              history.push('/pedido-cirurgico/carater-reserva', {
                surgical_order_id: surgicalOrder.surgical_order_id,
                surgicalOrder
              })
            }
          >
            Editar
          </small>
        )}
      </S.ReviewHeader>
      <S.InfoColumnContainer>
        <h6>Caráter</h6>
        <span>{getCharacter(surgicalOrder.hospitalizationType) || '-'}</span>
        {/* <h6>Datas de preferência</h6>
        <span>
          Sugestão 1 - {formatDate(surgicalOrder.expectedDate?.[0]) || '-'}
        </span>
        <span>
          Sugestão 2 - {formatDate(surgicalOrder.expectedDate?.[1]) || '-'}
        </span>
        <span>
          Sugestão 3 - {formatDate(surgicalOrder.expectedDate?.[2]) || '-'}
        </span> */}
        <h6>Duração prevista</h6>
        <span>{surgicalOrder.expectedDuration || '-'}</span>
        <h6>Uso de UTI</h6>
        <span>{surgicalOrder.requestedUtiDiaries ? 'Sim' : 'Não'}</span>
        <h6>Quantidades de dias de UTI</h6>
        <span>{surgicalOrder.requestedUtiDiaries || '-'}</span>
        <h6>Regime hospitalar</h6>
        <span>
          {getHospitalRegime(surgicalOrder.hospitalizationMode) || '-'}
        </span>
        <h6>Paciente já internado</h6>
        <span>{surgicalOrder.patientHospitalized ? 'Sim' : 'Não'}</span>
        <h6>Tempo médio de internação</h6>
        <span>
          {surgicalOrder.hospitalizationAvgTimeInDays || '-'}
          {addPlural('\tdia', surgicalOrder.hospitalizationAvgTimeInDays)}
        </span>
      </S.InfoColumnContainer>
    </>
  )
}

export const getCharacter = (val: string) => {
  switch (val) {
    case 'elective':
      return 'Eletivo'
    case 'urgency':
      return 'Urgência'
    case 'emergency':
      return 'Emergência'
  }
}

export const getHospitalRegime = (val: string) => {
  switch (val) {
    case 'hospital':
      return 'Convencional'
    case 'day hospital':
      return 'Hospital dia'
    case 'emergency room':
      return 'Pronto-socorro'
    case 'external':
      return 'Externo'
    case 'interned':
      return 'Internado'
    case 'ambulatory':
      return 'Ambulatório'
  }
}
