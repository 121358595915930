import { DoctorNavPermissions } from 'common/enum/doctor-nav-permissions'
import { AccountModel } from 'domain/entities/account-model'
import { LoadUserRole } from 'domain/usecases/authentication/load-user-role'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { PbacAdapter } from 'infra/pbac-adapter/pbac-adapter'
import { makeAutoObservable } from 'mobx'
import { Permissions } from './permissions'
import { Doctor } from 'domain/entities/doctor-model'

export class DoctorPermissions implements Permissions {
  constructor(
    private pbacAdapter: PbacAdapter,
    private loadUserRole: LoadUserRole,
    private getCurrentUser: () => AccountModel,
    private loadDoctor: LoadDoctor
  ) {
    makeAutoObservable(this)
  }

  set(params: string[]) {
    return (this.pbacAdapter = new PbacAdapter(params))
  }

  getAbilities() {
    return this.pbacAdapter.getAbilities()
  }

  can(permission: string) {
    return this.pbacAdapter.can(permission)
  }

  async load() {
    if (
      this.getCurrentUser()?.accessToken &&
      this.getCurrentUser()?.user.role
    ) {
      const abilities = await this.loadUserRole.load(['abilities'])
      const doctor = await this.loadDoctor?.load([
        'keySignature'
        // 'doctorRegister { status }'
      ])

      this.set(abilities.abilities)
      const redirectPermission =
        this.getRedirectPermissionByDoctorStatus(doctor)
      if (redirectPermission) {
        this.set([...abilities.abilities, redirectPermission])
      }
    }
  }

  private getRedirectPermissionByDoctorStatus = (
    doctor: Doctor
  ): DoctorNavPermissions | undefined => {
    const hasSignature = !!doctor.keySignature

    if (!hasSignature) {
      return DoctorNavPermissions.GO_TO_ADD_SIGNATURE
    }
  }

  clean() {
    return (this.pbacAdapter = new PbacAdapter())
  }
}
