import { CreateSurgicalComment } from 'domain/usecases/surgical-order/create-surgical-comment'

import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteCreateSurgicalComment implements CreateSurgicalComment {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  create(
    params: CreateSurgicalComment.Params
  ): Promise<CreateSurgicalComment.Model> {
    return this.surgicalOrderRepository.createSurgicalComment(params)
  }
}
