import styled from 'styled-components'

export const Wrapper = styled.div`
  h1 {
    margin: 10px 0;
    font-weight: 700;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 10px;
`

export const Content = styled.div`
  padding-bottom: 100px;
  margin-top: 20px;
`

export const DocumentsArea = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div {
    margin-right: 20px;
    margin-bottom: 20px;
  }
`

export const DocumentItem = styled.div`
  height: 140px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(30, 30, 30, 0.6);
  border: 4px solid ${({ theme }) => theme.colors.gray};
  border-radius: 10px;
  background-size: cover;
  position: relative;

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  label {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    text-align: center;
    max-width: 70%;
    margin-left: 4px;
  }

  p {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ theme }) => theme.colors.white};
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 138px;
    padding: 4px 2px;
    background-color: ${({ theme }) => theme.colors.gray};
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`
