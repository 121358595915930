import React, { useState } from 'react'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import Modal from 'presentation/shared/components/Modal'

type Props = {
  surgicalOrder: SurgicalOrderModel
  onClose: () => void
}

export default function FavoriteSurgicalOrderModal({
  surgicalOrder,
  onClose
}: Props) {
  const doctorService = useServices().doctor

  const [favoriteModal, setFavoriteModal] = useState(true)
  const [handleFavoriteModal, setHandleFavoriteModal] = useState(false)

  const submitFavorite = async (value: string) => {
    try {
      await doctorService.addFavoriteSurgicalOrder({
        name: value,
        cid: surgicalOrder.cid || [],
        procedure: surgicalOrder.procedure || [],
        opme: surgicalOrder.opme
      })
      setHandleFavoriteModal(true)
      setFavoriteModal(false)
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  return (
    <>
      <Modal
        title="Nome do favorito"
        description="Favorite um procedimento para agilizar a construção dos próximos pedidos cirúrgicos"
        show={favoriteModal}
        preventAutomateClose
        type="favorite"
        hasCloseButton="Cancelar"
        hasInput={(value) => submitFavorite(value)}
        bgZIndex={12}
        actionLabel="Salvar"
        close={() => setFavoriteModal(!favoriteModal)}
        subtitleProps={{
          colorFont: 'black'
        }}
        style={{ padding: '24px', width: 300 }}
      />
      <Modal
        close={() => {
          setHandleFavoriteModal(false)
          onClose()
        }}
        show={handleFavoriteModal}
        title="Procedimento favoritado com sucesso!"
      />
    </>
  )
}
