import React, { InputHTMLAttributes } from 'react'

import { isNil } from 'lodash'

import * as S from './styles'

type RadioValue = boolean | string | ReadonlyArray<string> | number

export type Props = {
  onCheck?: (value?: any) => void
  label?: string
  labelColor?: 'white' | 'black'
  labelFor?: string
  value?: RadioValue
  dataTestId?: number | string
  customValue?: any
  variant?: 'rounded' | 'square'
} & InputHTMLAttributes<HTMLInputElement>

const Chip = ({
  label,
  onCheck,
  value,
  customValue,
  variant = 'rounded',
  ...props
}: Props) => {
  const onChange = () => {
    !!onCheck && !props.disabled && !!value && onCheck(value)
    !!onCheck && !props.disabled && !isNil(customValue) && onCheck(customValue)
  }

  return (
    <S.InputContainer onClick={onChange}>
      <S.Input
        value={value}
        name={props.name}
        onChange={onChange}
        type="radio"
        role="radio"
        {...props}
      />
      <S.InputLabel
        variant={variant}
        isChecked={!!(props.checked || props.defaultChecked)}
        htmlFor={label}
        disabled={props.disabled}
        data-testid={`chip-${props.dataTestId}`}
      >
        {label}
      </S.InputLabel>
    </S.InputContainer>
  )
}

export default Chip
