import styled, { css } from 'styled-components'
import { CardWrapper } from 'presentation/shared/components/Card'

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  .container-card {
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper {
      height: 110% !important;
    }
  }
`

export const Card = styled(CardWrapper)`
  ${({ theme }) => css`
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px 12px 21px 12px;
    border: none;
    min-height: 257px;
    svg {
      width: 100px;
      height: 100px;
      margin-top: 15px;
      margin-bottom: 8px;
    }
    span {
      color: ${theme.colors.secondary};
      font-size: ${theme.font.sizes.small};
      line-height: 24px;
    }

    h5 {
      line-height: 24px;
    }

    p {
      ${({ theme }) => css`
        color: ${theme.colors.lightGray};
        font-size: ${theme.font.sizes.xxsmall};
        line-height: 15px;
      `}
    }
  `}
`

export const Container = styled.div`
  padding: 0px 24px;
  padding-top: 1rem;

  flex: 1;
`

export const NotificationHome = styled.div`
  background: ${({ theme }) => theme.colors.lightPink};
  color: ${({ theme }) => theme.colors.alert};
  width: 100%;
  font-size: 14px;
  display: flex;
  text-align: center;
  padding: 24px;
  font-weight: ${({ theme }) => theme.font.semiBold};
`

export const JourneySection = styled.section`
  margin-top: 3rem;
`

export const CarouselContainer = styled.div`
  width: 100%;
`

export const JourneyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  :hover {
    cursor: pointer;
  }
`

export const SurgicalJourneyButton = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  padding: 1rem;
  width: 100%;
  cursor: pointer;

  div {
    height: 6rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    max-width: 50px;
    font-weight: ${({ theme }) => theme.font.semiBold} !important;
  }
`

export const PendencyAlert = styled.div`
  width: 100%;
  color: white;
  height: 53px;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: #ff8787;
`

export const ServicesWrapper = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  padding: 0 20px;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  div {
    display: flex;
    width: 60px;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};

    &:not(:first-of-type) {
      margin-left: 33px;
    }

    & > div {
      height: 60px;
      width: 60px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: white;
      border: ${({ theme }) => '1px solid' + theme.colors.secondary};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.secondary};
          stroke: ${({ theme }) => theme.colors.secondary};
        }
      }
    }
  }
`

export const Title = styled.h3`
  padding-bottom: 20px;
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.gray};
`

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  line-height: 21px;
  letter-spacing: 0.5px;
  padding-bottom: 30px;
`

export const EmptyAttendances = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  margin: auto;
  display: flex;
  text-align: center;
  height: 160px;
  align-items: center;
`
