import { IApiRepository } from 'service/protocols/api/api-repository'
import { SurgeryCenterRepository as ISurgeryCenterRepository } from 'repository/interfaces/surgery-center-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { loadSurgeryCenter } from 'repository/graphql/queries'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'

export class SurgeryCenterRepository implements ISurgeryCenterRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadSurgeryCenter(
    params: LoadSurgeryCenter.Params
  ): Promise<LoadSurgeryCenter.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgeryCenter.Model>

    const query = loadSurgeryCenter(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.hospital_id, 'hospital_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgeryCenter.Model
    }
  }
}
