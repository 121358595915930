import { transparentize } from 'polished'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.menu`
  height: 89px;
  width: 100%;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  backdrop-filter: blur(42px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  position: relative;
`

export const MenuItem = styled(NavLink)<{ nofill?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.lightGray};
  text-decoration: none;
  flex: 1 0 0%;
  min-width: 0;

  &.active-menu {
    & div {
      background-color: ${({ theme }) =>
        transparentize(0.7, theme.colors.secondary)};
    }

    & svg path {
      fill: ${({ theme, nofill }) =>
        !nofill ? theme.colors.secondary : 'none'};
      stroke: ${({ theme, nofill }) =>
        nofill ? theme.colors.secondary : 'none'};

      :nth-child(3) {
        stroke: ${({ theme }) => theme.colors.secondary};
      }
    }

    & label {
      color: ${({ theme }) => theme.colors.secondary};
    }

    &::before {
      content: '';
      height: 2px;
      width: 33.33%;
      top: 0;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &.active-menu#cart-icon svg path {
    :nth-child(1) {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }

  & div {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
  }

  & label {
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.semiBold};
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-align: center;
    width: calc(100% - 8px);
    text-overflow: ellipsis;
  }

  & svg path {
    fill: ${({ theme, nofill }) => (!nofill ? theme.colors.lightGray : 'none')};
  }

  img {
    height: 28px;
    width: 28px;
  }
`

export const OpenMenuButton = styled.button`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 8px 24px rgba(96, 36, 184, 0.2);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  cursor: pointer;
  outline: none;
  border: none;
  margin: auto;

  svg path {
    fill: white;
  }
`

export const ModalMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ModalItem = styled.li`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;

  & + & {
    margin-top: 30px;
  }

  div {
    height: 32px;
    margin-right: 16px;
  }
`

export const ModalIcon = styled.img`
  margin-right: 20px;
`

export const ModalDescription = styled.span``
