import { Profile } from 'common/enum/profile'
import UserLogo from 'presentation/assets/icons/user.svg'
import FileAndApple from 'presentation/assets/icons/file-and-apple.svg'
import SignatureIcon from 'presentation/assets/icons/signature-icon.svg'
import { useHistory } from 'react-router-dom'

export const getProfileList = (profile?: Profile) => {
  const history = useHistory()
  switch (profile) {
    case Profile.PATIENT:
      return [
        {
          title: 'Dados pessoais',
          icon: UserLogo,
          click: () => history.push('/informacoes-pessoais')
        },
        {
          title: 'Hábitos de vida e nutrição',
          icon: FileAndApple,
          click: () => history.push('/habitos')
        },
        {
          title: 'Medicamentos e doença',
          icon: UserLogo,
          click: () => history.push('/medicamentos-doencas')
        }
      ]
    case Profile.DOCTOR:
      return [
        {
          title: 'Dados pessoais',
          icon: UserLogo,
          click: () => history.push('/informacoes-pessoais')
        },
        {
          title: 'Assinatura',
          icon: SignatureIcon,
          click: () => history.push('/assinatura')
        }
      ]
    default:
      return []
  }
}
