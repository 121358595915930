import styled from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.menu<MenuFullProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  transition: left 0.3s ease-in-out;
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  svg {
    width: 32px;
  }
`

export const ClickInterceptor = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: ${({ theme }) => theme.layers.base};
`

const LogoWrapperModifier = media.lessThan('medium')`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

export const LogoWrapper = styled.div`
  ${LogoWrapperModifier}
`

export const HamburguerIconWrapper = styled.div`
  cursor: pointer;
  margin: 10px 24px;
  width: 24px;
  height: 16px;
`

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
`

const MenuNavModifier = media.greaterThan('medium')`
  margin-left: 10px;
`

export const MenuNav = styled.div`
  ${MenuNavModifier}
`

export const MenuLink = styled.a`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  text-decoration: none;
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg,
  div {
    display: flex;
    align-items: center;
    height: 32px;
    margin-right: 24px;
  }
  &:not(:last-child) {
    margin-bottom: 36px;
  }

  .colored-stroke {
    stroke: ${({ theme }) => theme.colors.gray};
  }
`

type MenuFullProps = {
  isOpen: boolean
}

export const MenuFull = styled.nav<MenuFullProps>`
  max-width: ${({ isOpen }) => (isOpen ? '300px' : 0)};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.mainBg};
  position: absolute;
  z-index: ${({ theme }) => theme.layers.alwaysOnTop};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  width: 80vw;
  border-bottom-right-radius: 60px;
  overflow-y: auto;
  ${MenuNav} {
    display: flex;
    align-items: flex-start;
    margin-left: 25px;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    @media (min-height: 560px) {
      margin-top: 2rem;
      justify-content: flex-start;
    }
  }
  ${MenuLink} {
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.sizes.small};
  }

  transition: max-width 0.5s ease-in-out;
`
