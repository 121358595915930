import {
  ApiClient,
  ApiRequest,
  ApiResponse
} from 'service/protocols/api/api-client'
import axios, { AxiosResponse, AxiosStatic } from 'axios'
import https from 'https'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import handleCustomError from 'repository/graphql/utils/handle-custom-error'
import { getUrlDomain } from 'common/utils/getUrlDomain'

const TIMEOUT_IN_MS = 10 * 60 * 1000 // 10 minutes

export class ApiClientImplementation implements ApiClient {
  private static readonly domainUrl = getUrlDomain()

  private makeHeaders(headers = {}) {
    return {
      'x-api-path': ApiClientImplementation.domainUrl || getUrlDomain(),
      ...headers
    }
  }

  async request(data: ApiRequest): Promise<ApiResponse> {
    let axiosResponse: AxiosResponse
    let error
    try {
      const headers = this.makeHeaders(data.headers)

      axiosResponse = await axios
        .request({
          url: data.url,
          method: data.method,
          data: data.body,
          headers: {
            ...headers
          },
          timeout: TIMEOUT_IN_MS,
          httpsAgent: new https.Agent({ rejectUnauthorized: false })
        })
        .then((response) => this.refreshToken(axios, response))

      if (axiosResponse?.data?.errors && !axiosResponse?.data?.data) {
        axiosResponse.status =
          axiosResponse?.data?.errors?.[0]?.statusCode || 500
        error = axiosResponse?.data?.errors?.[0]?.code
      }
    } catch (err: any) {
      axiosResponse = err.response
    }

    if (axiosResponse?.data) {
      const error = handleCustomError(axiosResponse?.data)
      if (error) {
        throw error
      }
    }

    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
      error: error
    }
  }

  async requestFile(data: ApiRequest): Promise<AxiosResponse> {
    let axiosResponse: AxiosResponse
    try {
      const headers = this.makeHeaders(data.headers)

      axiosResponse = await axios
        .request({
          url: data.url,
          method: data.method,
          data: data.body,
          headers,
          timeout: 180000,
          responseType: 'blob'
        })
        .then((response) => this.refreshToken(axios, response))

      if (axiosResponse.data.errors) {
        axiosResponse.status = axiosResponse.data.errors[0].statusCode || 500
      }
    } catch (err: any) {
      axiosResponse = err.response
    }
    return axiosResponse
  }

  async refreshToken(
    axios: AxiosStatic,
    response: AxiosResponse<any>
  ): Promise<AxiosResponse<any>> {
    const error = response.data?.errors?.[0]
    const localStorageAdapter = new LocalStorageAdapter()
    const account = localStorageAdapter.get('account')
    if (error?.statusCode === 401 && account?.accessToken) {
      const { accessToken } = account
      const uri = `${process.env.REACT_APP_API_URL + '/graphql'}`

      return axios
        .post(
          uri,
          {
            query:
              'mutation refreshToken($accessToken: String!) {' +
              'refreshToken(accessToken: $accessToken)' +
              '}',
            variables: { accessToken }
          },
          {
            headers: this.makeHeaders()
          }
        )
        .then((res) => {
          if (res?.data?.errors?.[0].statusCode) {
            this.killSession()
            return Promise.resolve(res)
          } else {
            localStorageAdapter.set('account', {
              ...account,
              accessToken: res.data.data.refreshToken
            })
            response.config.headers = this.makeHeaders({
              Authorization: 'Bearer ' + res.data.data.refreshToken
            })
            return axios({
              ...response.config,
              data: JSON.parse(response.config.data)
            })
          }
        })
    }
    return response
  }

  killSession() {
    if (process.env.NODE_ENV === 'production') {
      window.location.pathname = '/login'
    } else if (process.env.NODE_ENV === 'development') {
      const client = window.location.pathname.split('/')[1]
      window.location.pathname = client + '/login'
    }
    const localStorage = new LocalStorageAdapter()
    localStorage.set('account')
  }
}
