import theme from 'presentation/styles/theme'
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'
import * as S from './styles'

type TextButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLSpanElement>

export type TextButtonProps = {
  as?: 'button' | 'a'
  size?: 'small' | 'medium'
  fontWeight?: Exclude<keyof typeof theme.font, 'sizes'>
  underline?: boolean
} & TextButtonTypes

const TextButton = ({
  size = 'medium',
  underline = false,
  fontWeight = 'bold',
  ...props
}: TextButtonProps) => {
  return (
    <S.Wrapper
      fontWeight={fontWeight}
      size={size}
      underline={underline}
      {...props}
    >
      {!!props.children && props.children}
    </S.Wrapper>
  )
}

export default TextButton
