import React, { useLayoutEffect } from 'react'

import {
  RouteProps,
  Route,
  Redirect,
  useHistory,
  useLocation
} from 'react-router-dom'

import { useStores } from 'presentation/hooks/use-stores'
import { User } from 'domain/entities/user-model'
import { Profile } from 'common/enum/profile'
import { decodeJwt } from 'common/utils/decode-jwt'
import { DoctorNavPermissions } from 'common/enum/doctor-nav-permissions'

type Props = {
  profile?: Profile[]
} & RouteProps

const PrivateRoute: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const location = useLocation()

  const account = useStores().currentAccount.getCurrentAccount()
  let user = {} as User
  if (account?.accessToken) {
    user = decodeJwt<User>(account.accessToken)
  } else {
    history.push('/login')
  }

  const permissions = useStores().permissions

  useLayoutEffect(() => {
    let isMounted = true

    async function loadData() {
      if (props.profile?.includes(Profile.DOCTOR)) {
        try {
          await permissions.load()
        } catch {
          return history.push('/login')
        }

        handlePushToRoute(isMounted)
      }
    }

    loadData()
    return () => {
      isMounted = false
    }
  }, [location])

  const handlePushToRoute = (isMounted: boolean) => {
    if (!isMounted) return
    if (
      permissions.can(DoctorNavPermissions.GO_TO_RECOMMENDATION_REGISTER) &&
      location.pathname !== '/cadastro-por-indicacao'
    ) {
      history.push('/cadastro-por-indicacao')
      return
    }

    if (
      permissions.can(DoctorNavPermissions.GO_TO_REVIEW) &&
      !location.pathname.includes('/cadastro-por-indicacao')
    ) {
      history.push('/cadastro-por-indicacao/resumo')
      return
    }

    if (
      permissions.can(DoctorNavPermissions.GO_TO_ADD_SIGNATURE) &&
      location.pathname !== '/assinatura/save'
    ) {
      history.push('/assinatura/save')
      return
    }
  }

  return account?.accessToken && props.profile?.includes(user.role) ? (
    <Route {...props} />
  ) : (
    <Route {...props} component={() => <Redirect to="/login" />} />
  )
}

export default PrivateRoute
