import styled from 'styled-components'
import theme from 'presentation/styles/theme'

type ServiceMenuProps = {
  selected: boolean
}

export const ActualPage = styled.div`
  width: 100%;
  padding: 20px 0px 20px 24px;
`
export const MenuItem = styled.div<ServiceMenuProps>`
  cursor: pointer;
  white-space: nowrap;
  margin: 0 10px;
  font-weight: ${theme.font.bold};
  color: ${({ selected }) =>
    selected ? theme.colors.primary : theme.colors.lightGray};
  border-bottom: 3px solid
    ${({ selected }) => (selected ? theme.colors.primary : 'none')};
`
export const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid ${theme.colors.lightGray};
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
