import styled from 'styled-components'
import { ContainerTypes } from './index'

export const Wrapper = styled.div<{ noPadding?: boolean }>`
  padding-top: ${({ noPadding }) => (noPadding ? '0' : '24px')};
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  font-size: 16px;

  justify-content: flex-start;

  div span {
    text-align: center;
    font-weight: 400;
  }
`

export const Header = styled.header`
  h1 {
    font-size: ${({ theme }) => theme.font.sizes.large};
    margin-bottom: 8px;
  }
  p {
    color: ${({ theme }) => theme.colors.lightGray};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }

  display: flex;
  flex-direction: column;
`

export const ContainerStyle = styled.main<ContainerTypes>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  font-size: 16px;

  .back-button {
    padding: 0 14px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
`

export const Buttons = styled.div<Pick<ContainerTypes, 'noPadding'>>`
  margin-bottom: 10px;
  text-align: center;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 24px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    cursor: pointer;
    text-align: center;
    padding: 10px 0;
  }

  align-items: center;

  button + button {
    margin-left: 0;
    margin-top: 15px;
  }
`
