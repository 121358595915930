import styled, { css } from 'styled-components'
import ModalSheet from 'react-modal-sheet'

export const Sheet = styled(ModalSheet)<{
  backdrop: 'true' | 'false'
  marginbottom?: string
}>`
  max-width: 50rem;
  margin: 0 auto;

  ${({ theme, backdrop, marginbottom }) => css`
    font-family: ${({ theme }) => theme.font?.family};

    strong {
      color: ${theme.colors?.primary};
    }

    .react-modal-sheet-container {
      border-top-right-radius: 40px !important;
      border-top-left-radius: 40px !important;
      padding: 24px;
      background-color: ${({ theme }) => theme.colors.mainBg};
    }

    .react-modal-sheet-content {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      width: 100%;
    }

    ${backdrop === 'false' && wrapperModifiers.backdrop()}
    ${!!marginbottom && wrapperModifiers.marginbottom(marginbottom)}
  `}
`

const wrapperModifiers = {
  backdrop: () => css`
    .react-modal-sheet-backdrop {
      background-color: transparent !important;
    }
  `,
  marginbottom: (margin: string) => css`
    & {
      bottom: ${margin} !important;
    }

    .react-modal-sheet-backdrop {
      top: -${margin} !important;
    }
  `
}
