import { Hospital } from 'domain/entities/hospital-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { LoadPatientSurgery } from 'domain/usecases/patient/load-patient-surgery'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import moment from 'moment-timezone'
import { useStores } from 'presentation/hooks/use-stores'
import PatientHomeLayout from 'presentation/patient/layouts/Home'
import { ServiceOverviewProps } from 'presentation/shared/components/ServiceOverview'
import { getStatusInfo } from 'presentation/utils/service-status'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import { GetPatientNextAttendances } from 'domain/usecases/patient/get-patient-next-attendances'
import {
  Attendance,
  AttendanceType,
  AttendanceTypeTranslation
} from 'domain/entities/attendance-model'

type PatientHomeProps = {
  loadPatientSurgery: LoadPatientSurgery
  loadPatientInfo: LoadPatientInfo
  loadSurgicalOrderStatus: LoadSurgicalOrderStatus
  getPatientNextAttendances: GetPatientNextAttendances
}

type SurgeryProps = {
  unit: string
  status: string
  statusColor: string
  statusLabel: string
  doctorName: string
  doctorRole: string
  scheduleDate: string
  surgical_order_id: number
  title: string
}

const ATTENDANCES_PER_PAGE = 5

const PatientHome = ({
  loadPatientInfo,
  getPatientNextAttendances
}: PatientHomeProps) => {
  const [patientAttendances, setPatientAttendances] = useState<Attendance[]>([])
  const [patientSurgeries, setPatientSurgeries] = useState<SurgeryProps[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pendencies, setPendencies] = useState<number>()
  const history = useHistory()

  const { currentAccount } = useStores()
  const user = currentAccount.getCurrentAccount().user

  const patientService = useServices().patient

  const handlePatientInfo = async () => {
    const patientInfo = await loadPatientInfo.load([
      'surgicalOrders (filterSolicitations: true) {',
      'surgical_order_id',
      'procedure {description}',
      'hospital {name, hospital_id}',
      'surgeryDate',
      'scheduled',
      'status{status}',
      'doctor{name, role}',
      '}'
    ])
    setPatientSurgeries(
      mapSurgicalOrderToSurgeryProps(patientInfo?.surgicalOrders || [])
    )
  }

  const handlePatientPendencies = async () => {
    try {
      const totalPendencies = await patientService.loadPatientPendencies({
        query: { unresolved: true },
        pagination: {
          pageSize: 1,
          pageNumber: 1
        },
        params: [
          `pageInfo {
                currentPage
                itemsPerPage
                totalItems
                totalPages
              }`
        ]
      })
      setPendencies(totalPendencies?.pendencies?.pageInfo.totalItems)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handlePatientNextAttendance = async () => {
    const attendances = await getPatientNextAttendances.load({
      fields: [
        'identifier',
        'attendanceDate',
        'description',
        'hospitalName',
        'status',
        'type',
        'doctorName'
      ],
      limit: ATTENDANCES_PER_PAGE + 1
    })
    if (attendances) setPatientAttendances(attendances)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        await handlePatientInfo()
        handlePatientPendencies()
        await handlePatientNextAttendance()
      } catch (err: any) {
        toast.error(err.message)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  const adaptAttendanceToProp = (attendance: Attendance) => {
    const prop = {
      title: attendance.description,
      status: attendance.status,
      type: AttendanceTypeTranslation[attendance.type],
      unit: new Hospital(0, attendance.hospitalName, attendance.hospitalName)
        .name,
      date: attendance.attendanceDate
        ? moment(new Date(attendance.attendanceDate))
            .utc()
            .format('DD/MM/YYYY - HH:mm')
        : 'Aguardando agendamento',
      doctorName: attendance.doctorName
    }
    const attendanceFunction: Record<AttendanceType, () => void> = {
      [AttendanceType.SURGICAL]: () =>
        history.push('/cirurgia', {
          id: attendance.identifier
        }),
      [AttendanceType.AMBULATORY]: () =>
        history.push('/agendamento/status', {
          schedule: prop
        })
    }
    return {
      ...prop,
      onClick: attendanceFunction[attendance.type]
    }
  }

  const adaptedAttendances = patientAttendances
    .slice(0, ATTENDANCES_PER_PAGE)
    .map((attendance) => adaptAttendanceToProp(attendance))
  const hasMoreAttendances =
    patientAttendances.length === ATTENDANCES_PER_PAGE + 1

  return (
    <>
      <PatientHomeLayout
        name={user.name}
        surgeries={
          patientSurgeries
            ? mapSurgeryPropsToServiceOverviewProps(patientSurgeries)
            : undefined
        }
        isLoading={isLoading}
        redirect={history.push}
        pendencies={pendencies}
        hasMoreAttendances={hasMoreAttendances}
        attendances={adaptedAttendances}
      />
    </>
  )
}

export default PatientHome

function handleStatusColor(surgicalOrder: SurgicalOrderModel) {
  let color = '#888888'
  surgicalOrder.status?.forEach((status) => {
    if (status.status === SurgicalOrderStatus.CANCELLED) {
      return (color = '#F48989')
    } else if (status.status === SurgicalOrderStatus.SCHEDULED) {
      return (color = '#1BD15D')
    }
  })
  return color
}

function adaptSurgicalOrderToSurgeryProps(
  surgicalOrder: SurgicalOrderModel
): SurgeryProps {
  const statusInfo = getStatusInfo(
    surgicalOrder?.status?.slice(-1)?.[0]?.status ?? ''
  )
  return {
    doctorName: surgicalOrder?.doctor?.name ?? '-',
    doctorRole: 'Médico responsável',
    unit: new Hospital(
      surgicalOrder?.hospital?.hospital_id ?? 0,
      surgicalOrder?.hospital?.name ?? '',
      surgicalOrder?.hospital?.name ?? ''
    ).name,
    status: surgicalOrder?.status?.slice(-1)?.[0]?.status ?? '',
    statusColor: handleStatusColor(surgicalOrder) ?? '',
    statusLabel: statusInfo?.label ?? '',
    surgical_order_id: surgicalOrder?.surgical_order_id ?? 1,
    scheduleDate: surgicalOrder?.surgeryDate ?? '',
    title: surgicalOrder?.procedure?.[0].description ?? 'Procedimento cirúrgico'
  }
}

function adaptSurgeryPropsToServiceOverviewProps(
  surgeryProps: SurgeryProps
): ServiceOverviewProps {
  return {
    title: surgeryProps?.title ?? '',
    type: 'Cirurgia',
    unit: surgeryProps?.unit,
    color: surgeryProps?.statusColor,
    status: surgeryProps?.statusLabel ?? '',
    date: surgeryProps?.scheduleDate
      ? moment(new Date(surgeryProps?.scheduleDate))
          .utc()
          .format('DD/MM/YYYY - HH:mm')
      : '-',
    surgicalId: surgeryProps?.surgical_order_id
  }
}

function mapSurgicalOrderToSurgeryProps(
  surgicalOrders: SurgicalOrderModel[]
): SurgeryProps[] {
  return surgicalOrders.map((surgicalOrder) =>
    adaptSurgicalOrderToSurgeryProps(surgicalOrder)
  )
}

function mapSurgeryPropsToServiceOverviewProps(
  surgeryProps: SurgeryProps[]
): ServiceOverviewProps[] {
  return surgeryProps.map((surgeryProp) =>
    adaptSurgeryPropsToServiceOverviewProps(surgeryProp)
  )
}
