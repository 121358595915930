export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  STABLE_UNION = 'STABLE_UNION'
}

export const maritalStatus = [
  {
    label: 'Solteiro',
    value: MaritalStatus.SINGLE
  },
  { label: 'Casado', value: MaritalStatus.MARRIED },
  { label: 'Separado', value: MaritalStatus.SEPARATED },
  { label: 'Divorciado', value: MaritalStatus.DIVORCED },
  { label: 'Viúvo', value: MaritalStatus.WIDOWED },
  { label: 'União estável', value: MaritalStatus.STABLE_UNION }
]

export function getMaritalStatus(status: MaritalStatus) {
  return maritalStatus.find((item) => item.value === status)?.label
}
