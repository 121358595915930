import { IApiRepository } from 'service/protocols/api/api-repository'
import { SurgicalOrderRepository as IReportsRepository } from 'repository/interfaces/surgical-order-repository'
import { AddSurgicalOrder } from 'domain/usecases/surgical-order/add-surgical-order'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { LoadTuss } from 'domain/usecases/surgical-order/load-tuss'
import {
  countSurgicalOrdersByStatus,
  getCidQuery,
  getTussQuery,
  loadFavoriteDoctorOrdersQuery,
  loadFavoriteSurgicalOrdersQuery,
  loadSurgeriesQuery,
  loadSurgicalOrderQuery,
  loadSurgicalOrderStatusQuery,
  searchSurgicalOrdersQuery,
  countScheduledAndUnscheduledSurgicalOrders,
  getAgendaAllocations,
  getAgendaClassifications,
  getAllStatus,
  findAllOpmes
} from 'repository/graphql/queries'
import { RegisterSurgicalOrder } from 'domain/usecases/surgical-order/register-surgical-order'
import {
  addFavoriteMutation,
  checkinMutation,
  concludeSurgicalOrderMutation,
  confirmMergeDocumentsMutation,
  createPatientSurgicalOrderRequestMutation,
  createSurgicalCommentMutation,
  deleteFavoritedSurgicalOrderMutation,
  deleteSurgicalOrderSolicitationMutation,
  deleteUploadedDocumentsMutation,
  registerCrmoSurgicalOrderMutation,
  registerSecretarySurgicalOrderMutation,
  registerSurgicalOrderMutation,
  reorderSurgeryMutation,
  updateSurgeryStatusMutation,
  updateSurgicalOrderMutation,
  updateSurgicalOrderRegenerateGuidesMutation
} from 'repository/graphql/mutations'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { AddFavorite } from 'domain/usecases/surgical-order/add-favorite'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import { ChangeSurgicalOrderStatus } from 'domain/usecases/surgical-order/change-surgical-order-status'
import { ReorderSurgicalOrder } from 'domain/usecases/surgical-order/reorder-surgery'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { LoadSurgeries } from 'domain/usecases/surgery/load-surgeries'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { CountSurgicalOrdersByStatus } from 'domain/usecases/surgical-order/count-surgical-orders-by-status'
import { ConcludeSurgicalOrder } from 'domain/usecases/surgical-order/conclude-surgical-order'
import { DeleteSurgicalOrderSolicitation } from 'domain/usecases/surgical-order/delete-surgical-order-solicitation'
import { UploadSurgicalOrderDocumentsSameType } from 'domain/usecases/surgical-order/upload-surgical-order-documents-same-type'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { DeleteFavoritedSurgicalOrder } from 'domain/usecases/surgical-order/delete-favorited-surgical-order'
import { SurgicalOrderCheckin } from 'domain/usecases/surgical-order/checkin'
import { UpdateSurgicalOrderRegenerateGuides } from 'domain/usecases/surgical-order/update-surgical-order-regenerate-guides'
import { LoadFavoriteDoctorSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-doctor-order'
import { UploadToMergeDocument } from 'domain/usecases/surgical-order/upload-to-merge-document'
import { ConfirmMergeDocuments } from 'domain/usecases/surgical-order/confirm-merge-documents'
import { DeleteUploadedDocuments } from 'domain/usecases/surgical-order/delete-uploaded-documents'
import { CountScheduledAndUnscheduledSurgicalOrders } from 'domain/usecases/surgical-order/scheduled-and-unscheduled-counter'
import { SurgicalCommentModel } from 'domain/entities/surgical-comment-model'
import { CreateSurgicalComment } from 'domain/usecases/surgical-order/create-surgical-comment'
import { GetAgendaAllocation } from 'domain/usecases/agenda/get-agenda-allocation'
import { GetAgendaClassification } from 'domain/usecases/agenda/get-agenda-classification'
import { GetAllStatus } from 'domain/usecases/surgical-status/get-all-surgical-status'
import moment from 'moment'
import { FindAllOpmes } from 'domain/usecases/surgical-order/find-all-opmes'

export class SurgicalOrderRepository implements IReportsRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addPatientSurgicalOrder(
    params: AddSurgicalOrder.Params
  ): Promise<AddSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddSurgicalOrder.Model>

    const query = createPatientSurgicalOrderRequestMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddSurgicalOrder.Model
    }
  }

  async loadCid(params: LoadCid.Params): Promise<LoadCid.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadCid.Model>

    const query = getCidQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination'),
          ...makeGraphQLVariable(params.query, 'query')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadCid.Model
    }
  }

  async loadTuss(params: LoadTuss.Params): Promise<LoadTuss.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadTuss.Model>

    const query = getTussQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination'),
          ...makeGraphQLVariable(params.name, 'name'),
          ...makeGraphQLVariable(params.code, 'code')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadTuss.Model
    }
  }

  async loadSurgicalOrder(
    id: number,
    params: LoadSurgicalOrder.Params
  ): Promise<LoadSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrder.Model>

    const query = loadSurgicalOrderQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(id, 'surgical_order_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgicalOrder.Model
    }
  }

  async loadSurgicalOrderStatus(
    params: LoadSurgicalOrderStatus.Params
  ): Promise<LoadSurgicalOrderStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrderStatus.Model>

    const query = loadSurgicalOrderStatusQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          params.surgical_order_id,
          'surgical_order_id'
        )
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgicalOrderStatus.Model
    }
  }

  async searchSurgicalOrders(
    params: SearchSurgicalOrders.Params
  ): Promise<SearchSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchSurgicalOrders.Model>

    const query = searchSurgicalOrdersQuery(params)
    delete params.params

    const { pagination, ...filters } = params

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(filters, 'search'),
          ...makeGraphQLVariable(pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchSurgicalOrders.Model
    }
  }

  async registerSurgicalOrder(
    params: RegisterSurgicalOrder.Params
  ): Promise<RegisterSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RegisterSurgicalOrder.Model>

    const query = registerSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RegisterSurgicalOrder.Model
    }
  }

  async addFavorite(params: AddFavorite.Params): Promise<AddFavorite.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddFavorite.Model>

    const query = addFavoriteMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddFavorite.Model
    }
  }

  async uploadSurgicalOrderDocument(
    params: UploadSurgicalOrderDocument.Params
  ): Promise<UploadSurgicalOrderDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadSurgicalOrderDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/surgical-order/upload-document',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadSurgicalOrderDocument.Model
    }
  }

  async loadSurgicalOrderDocument(
    surgical_order_id: number | string,
    document_id: number
  ): Promise<LoadSurgicalOrderDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrderDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/surgical-order/${surgical_order_id}/document/${document_id}`
      },
      true
    )

    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    return {
      contentType,
      data
    }
  }

  async registerCrmoSurgicalOrder(
    params: RegisterSurgicalOrder.Params
  ): Promise<RegisterSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RegisterSurgicalOrder.Model>

    const query = registerCrmoSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RegisterSurgicalOrder.Model
    }
  }

  async changeSurgicalOrderStatus(
    params: ChangeSurgicalOrderStatus.Params
  ): Promise<ChangeSurgicalOrderStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ChangeSurgicalOrderStatus.Model>

    const query = updateSurgeryStatusMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          surgical_order_id: params.surgical_order_id,
          ...makeGraphQLVariable(params.data)
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ChangeSurgicalOrderStatus.Model
    }
  }

  async reorderSurgicalOrder(
    params: ReorderSurgicalOrder.Params
  ): Promise<ReorderSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ReorderSurgicalOrder.Model>

    const query = reorderSurgeryMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ReorderSurgicalOrder.Model
    }
  }

  async updateSurgicalOrder(
    params: UpdateSurgicalOrder.Params
  ): Promise<UpdateSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateSurgicalOrder.Model>

    const query = updateSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body!
    }
  }

  async loadSurgeries(
    params: LoadSurgeries.Params
  ): Promise<LoadSurgeries.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgeries.Model>

    const query = loadSurgeriesQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination'),
          ...makeGraphQLVariable(params.name, 'name')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgeries.Model
    }
  }

  async countSurgicalOrdersByStatus(
    params: CountSurgicalOrdersByStatus.Params
  ): Promise<CountSurgicalOrdersByStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CountSurgicalOrdersByStatus.Model>

    const httpResponse: any = await apiRepository.get({
      url:
        `/surgical-order/count` +
        `?startDate=${moment(params.startDate).format('YYYY-MM-DD')}` +
        `&endDate=${moment(params.endDate).format('YYYY-MM-DD')}`
    })

    if (
      httpResponse?.statusCode &&
      httpResponse?.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CountSurgicalOrdersByStatus.Model
    }
  }

  async concludeSurgicalOrder(
    params: ConcludeSurgicalOrder.Params
  ): Promise<ConcludeSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ConcludeSurgicalOrder.Model>

    const query = concludeSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.data)
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ConcludeSurgicalOrder.Model
    }
  }

  async deleteSurgicalOrderSolicitation(
    params: DeleteSurgicalOrderSolicitation.Params
  ): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteSurgicalOrderSolicitation.Model>

    const query = deleteSurgicalOrderSolicitationMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.justification, 'justification'),
          ...makeGraphQLVariable(params.surgical_order_id, 'surgical_order_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteSurgicalOrderSolicitation.Model
    }
  }

  async checkin(
    params: SurgicalOrderCheckin.Params
  ): Promise<SurgicalOrderCheckin.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SurgicalOrderCheckin.Model>

    const query = checkinMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.surgical_order_id, 'surgical_order_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SurgicalOrderCheckin.Model
    }
  }

  async uploadSurgicalOrderDocumentsSameType(
    params: UploadSurgicalOrderDocumentsSameType.Params
  ): Promise<UploadSurgicalOrderDocumentsSameType.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadSurgicalOrderDocumentsSameType.Model>

    const httpResponse = await apiRepository.post({
      url: '/surgical-order/upload-same-type-documents',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadSurgicalOrderDocumentsSameType.Model
    }
  }

  async updateSurgicalOrderRegenerateGuides(
    params: UpdateSurgicalOrderRegenerateGuides.Params
  ): Promise<UpdateSurgicalOrderRegenerateGuides.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateSurgicalOrderRegenerateGuides.Model>

    const query = updateSurgicalOrderRegenerateGuidesMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            {
              healthInsurance: {
                ...params.healthInsurance,
                health_insurance_id: params.healthInsurance.healthInsuranceCode
              },
              surgical_order_id: params.surgical_order_id,
              patient: {
                name: params.patient.name,
                gender: params.patient.gender,
                birthday: params.patient.birthday,
                email: params.patient.email,
                phone: params.patient.phone,
                landlinePhone: params.patient.landlinePhone
              }
            },
            'input'
          )
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateSurgicalOrderRegenerateGuides.Model
    }
  }

  async registerSecretarySurgicalOrder(
    params: RegisterSurgicalOrder.Params
  ): Promise<RegisterSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RegisterSurgicalOrder.Model>
    const query = registerSecretarySurgicalOrderMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })
    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RegisterSurgicalOrder.Model
    }
  }

  async loadFavoriteSurgicalOrders(
    params: LoadFavoriteSurgicalOrders.Params
  ): Promise<LoadFavoriteSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadFavoriteSurgicalOrders.Model>

    const query = loadFavoriteSurgicalOrdersQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadFavoriteSurgicalOrders.Model
    }
  }

  async loadFavoriteDoctorSurgicalOrders(
    params: LoadFavoriteDoctorSurgicalOrders.Params
  ): Promise<LoadFavoriteDoctorSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadFavoriteDoctorSurgicalOrders.Model>

    const query = loadFavoriteDoctorOrdersQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadFavoriteSurgicalOrders.Model
    }
  }

  async deleteFavoritedSurgicalOrder(
    params: DeleteFavoritedSurgicalOrder.Params
  ): Promise<DeleteFavoritedSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteFavoritedSurgicalOrder.Model>

    const query = deleteFavoritedSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.data, 'data')
      },
      query: query.name
    })

    if (!httpResponse.body) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteFavoritedSurgicalOrder.Model
    }
  }

  async uploadToMergeDocument(
    params: UploadToMergeDocument.Params
  ): Promise<UploadToMergeDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadToMergeDocument.Model>
    const form = new FormData()
    form.append('file', params.file)
    form.append('group_id', params.group_id)
    form.append('type', params.type)
    const httpResponse = await apiRepository.post({
      url: '/surgical-order/upload-document-to-merge',
      body: form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadToMergeDocument.Model
    }
  }

  async confirmMergeDocuments(
    params: ConfirmMergeDocuments.Params
  ): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<ConfirmMergeDocuments.Model>

    const query = confirmMergeDocumentsMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.group_id, 'group_id'),
          ...makeGraphQLVariable(params.surgical_order_id, 'surgical_order_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ConfirmMergeDocuments.Model
    }
  }

  async deleteUploadedDocuments(
    params: DeleteUploadedDocuments.Params
  ): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<DeleteUploadedDocuments.Model>

    const query = deleteUploadedDocumentsMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.document_id, 'document_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteUploadedDocuments.Model
    }
  }

  async countScheduledAndUnscheduledSurgicalOrders(
    params: CountScheduledAndUnscheduledSurgicalOrders.Params
  ): Promise<CountScheduledAndUnscheduledSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CountScheduledAndUnscheduledSurgicalOrders.Model>

    const query = countScheduledAndUnscheduledSurgicalOrders(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            {
              startDate: params.startDate,
              endDate: params.endDate
            },
            'period'
          )
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CountScheduledAndUnscheduledSurgicalOrders.Model
    }
  }

  async createSurgicalComment(
    params: CreateSurgicalComment.Params
  ): Promise<SurgicalCommentModel> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateSurgicalComment.Model>

    const query = createSurgicalCommentMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateSurgicalComment.Model
    }
  }

  async getAgendaAllocation(
    params: GetAgendaAllocation.Params
  ): Promise<GetAgendaAllocation.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAgendaAllocation.Model>

    const query = getAgendaAllocations()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            { startDate: params.beginDate, endDate: params.endDate },
            'period'
          ),
          ...makeGraphQLVariable(params.hospitalId, 'hospitalId')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetAgendaAllocation.Model
    }
  }

  async getAgendaClassifications(
    params: GetAgendaClassification.Params
  ): Promise<GetAgendaClassification.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAgendaClassification.Model>

    const query = getAgendaClassifications()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.hospital_id, 'hospitalId')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetAgendaClassification.Model
    }
  }

  async getAllStatus(
    params?: GetAllStatus.Params
  ): Promise<GetAllStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAllStatus.Model>

    const query = getAllStatus()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetAllStatus.Model
    }
  }
  async findAllOpmes(params: FindAllOpmes.Params): Promise<FindAllOpmes.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FindAllOpmes.Model>

    const query = findAllOpmes()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.query, 'description'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (!httpResponse.body) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FindAllOpmes.Model
    }
  }
}
