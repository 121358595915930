import React from 'react'

import SuggestionsOrCriticismsForm from 'presentation/patient/components/Forms/SuggestionsOrCriticisms'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import { CreateSuggestionOrCriticism } from 'domain/usecases/patient/create-suggestion-or-criticism'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'

type Props = {
  createSuggestionOrCriticism?: CreateSuggestionOrCriticism
  loadHospitals?: LoadHospitals
}

export default function SuggestionsOrCriticismsLayout({
  createSuggestionOrCriticism,
  loadHospitals
}: Props) {
  return (
    <>
      <Header />
      <Container hasHeader>
        <SuggestionsOrCriticismsForm
          createSuggestionOrCriticism={createSuggestionOrCriticism}
          loadHospitals={loadHospitals}
        />
      </Container>
    </>
  )
}
