import React from 'react'
import CarouselPersonalInfoForm, {
  PatientPersonalInfoForm
} from 'presentation/patient/components/Forms/CarouselPersonalInfo'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { dateInputFormat } from 'presentation/utils/date-format'

type UpdatePersonalInfoLayout = {
  useCase?: UpdatePatientInfo
  patientInfo: PatientPersonalInfoForm
}

export default function UpdatePersonalInfoLayout({
  patientInfo,
  useCase
}: UpdatePersonalInfoLayout) {
  return (
    <CarouselPersonalInfoForm
      useCase={useCase}
      initialValues={
        {
          ...patientInfo,
          birthday: dateInputFormat(patientInfo.birthday)
        } as PatientPersonalInfoForm
      }
    />
  )
}
