import React from 'react'
import { NavLink, useHistory, Switch, Route } from 'react-router-dom'
import Header from 'presentation/shared/components/Header'
import ActualPage from 'presentation/shared/components/ActualPage'
import { Container } from 'presentation/shared/components/Container'
import PreSurgeryInfo from './PreSurgeryInfo'
import PostSurgeryInfo from './PostSurgeryInfo'
import BottomMenu from '../../BottomMenu'
import { useStores } from 'presentation/hooks/use-stores'

import * as S from './styles'

export default function SurgeryInfoTabs() {
  const history = useHistory()
  const currentSurgery = useStores().currentSurgery

  return (
    <>
      <Header />
      <Container noPadding>
        <S.Header>
          <ActualPage onClick={() => history.push('/home')} text="Cirurgia" />
          <S.InfoList>
            <NavLink
              exact
              to={{
                pathname: '/orientacoes/cirurgicas/pre-cirurgica',
                state: { id: currentSurgery.getSurgeryIdSelected() }
              }}
              activeClassName="selected"
            >
              Pré-Cirúrgica
            </NavLink>
            <NavLink
              exact
              to={{
                pathname: '/orientacoes/cirurgicas/pos-cirurgica',
                state: { id: currentSurgery.getSurgeryIdSelected() }
              }}
              activeClassName="selected"
            >
              Pós-Cirúrgica
            </NavLink>
          </S.InfoList>
        </S.Header>
        <S.Content>
          <Switch>
            <Route
              exact
              path={`/orientacoes/cirurgicas/pre-cirurgica`}
              render={() => <PreSurgeryInfo />}
            />
            <Route
              exact
              path={`/orientacoes/cirurgicas/pos-cirurgica`}
              render={() => <PostSurgeryInfo />}
            />
          </Switch>
        </S.Content>
      </Container>
      <BottomMenu />
    </>
  )
}
