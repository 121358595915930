import { CommunicationParticularity } from 'common/enum/communication-particularity'

export const communicationParticularities = [
  {
    label: 'Idioma',
    value: CommunicationParticularity.LANGUAGE
  },
  { label: 'Idade', value: CommunicationParticularity.AGE },
  { label: 'Escolaridade', value: CommunicationParticularity.SCHOOLING },
  { label: 'Visual', value: CommunicationParticularity.VISUAL },
  { label: 'Auditiva', value: CommunicationParticularity.HEARING },
  { label: 'Cognitiva', value: CommunicationParticularity.COGNITIVE },
  { label: 'Psíquica', value: CommunicationParticularity.PSYCHIC },
  { label: 'Comunicativa', value: CommunicationParticularity.COMMUNICATIVE },
  { label: 'Emocional', value: CommunicationParticularity.EMOTIONAL }
]
