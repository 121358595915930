import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'

import Carousel, { CarouselState } from '../../Carousel'

import * as S from './styles'
import TextField from '../../TextField'
import Button from '../../Button'
import { phoneMask } from 'presentation/utils/masks'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import { toast } from 'react-toastify'
import TextButton from '../../TextButton'
import ActualPage from '../../ActualPage'
import PasswordField from '../../PasswordField'
import { codeFields } from './fields'
import {
  GetPasswordCodeFormValues,
  SetPasswordFormValues
} from 'presentation/shared/pages/PasswordRecovery'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { useHistory } from 'react-router'
import Modal from '../../Modal'
import { Profile } from 'common/enum/profile'
import { profileBind } from 'common/utils/bindRolesToEnum'
import { FakePasswordInput, FakeUserInput } from '../../FakeInput'

type PasswordRecoveryFormProps = {
  type: 'phone' | 'email'
  header?: boolean
  getCodeUseCase?: GetPasswordRecoveryCode
  changePasswordUseCase?: ChangePassword
  getCodeValues?: GetPasswordCodeFormValues
  setPasswordValues?: SetPasswordFormValues
  client?: Profile
}

export default function PasswordRecoveryForm({
  type,
  getCodeUseCase,
  changePasswordUseCase,
  header = true,
  getCodeValues = {} as GetPasswordCodeFormValues,
  setPasswordValues = {} as SetPasswordFormValues,
  client
}: PasswordRecoveryFormProps) {
  const [swiper, updateSwiper] = useState({} as CarouselState)
  const [elRefs, setElRefs] = React.useState([] as HTMLInputElement[])
  const [showGettedCodeModal, setShowGettedCodeModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const history = useHistory()

  React.useEffect(() => {
    setElRefs((elRefs) =>
      [...Array(codeFields.length)].map((_, i) => elRefs[i])
    )
  }, [codeFields.length])

  const getCodeForm = useFormik({
    initialValues: getCodeValues,
    validateOnMount: true,
    validationSchema:
      type === 'phone' ? PhoneValidationSchema : EmailValidationSchema,
    onSubmit: async (values) => {
      try {
        await getCodeUseCase?.getPasswordRecoveryCode({
          type: values.type,
          field:
            type === 'email' ? values.field : values.field.replace(/\D+/g, ''),
          role: client ? profileBind[client] : undefined
        })
        setShowGettedCodeModal(true)
        setTimeout(() => {
          swiper.slideNext()
        }, 1000)
      } catch (err: any) {
        toast.error(err.message)
      }
    }
  })

  const setPasswordForm = useFormik({
    initialValues: setPasswordValues,
    validationSchema: setPasswordValidationSchema,
    onSubmit: async (values) => {
      try {
        await changePasswordUseCase?.changePassword({
          code: `${values.codeChar1}${values.codeChar2}${values.codeChar3}${values.codeChar4}`,
          password: values.password,
          recover: {
            type: type,
            field:
              type === 'email'
                ? getCodeForm.values.field
                : getCodeForm.values?.field,
            role: client ? profileBind[client] : undefined
          }
        })
        setShowConfirmationModal(true)
      } catch (err: any) {
        swiper.slideTo(1)
        toast.error(err.message)
      }
    }
  })

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 1) {
      setPasswordForm.setFieldValue(e.target.name, e.target.value)
    }
  }

  const changeFocus = (e: React.KeyboardEvent, index: number) => {
    if (
      index < elRefs.length - 1 &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete'
    ) {
      elRefs[index + 1].focus()
    }
  }

  return (
    <S.Wrapper role="form" onSubmit={setPasswordForm.handleSubmit}>
      {header && (
        <S.Controls>
          <ActualPage
            text="Alterar senha"
            onClick={() => {
              if (swiper.activeIndex === 0) history.goBack()
              else swiper.slidePrev()
            }}
          />
          <TextButton
            onClick={() => history.goBack()}
            underline
            size="small"
            type="button"
          >
            Cancelar
          </TextButton>
        </S.Controls>
      )}
      <Carousel
        state={swiper}
        setState={updateSwiper}
        slidesPerView={1}
        touch={false}
      >
        <S.Step>
          <S.Content>
            {type === 'phone' ? (
              <TextField
                name="field"
                label="Seu telefone:"
                mask={phoneMask}
                defaultValue={'+55'}
                onInputChange={getCodeForm.handleChange('field')}
                onBlur={getCodeForm.handleBlur('field')}
                error={
                  getCodeForm.touched.field
                    ? getCodeForm.errors.field
                    : undefined
                }
              />
            ) : (
              <TextField
                name="field"
                label="Seu e-mail:"
                onInputChange={getCodeForm.handleChange('field')}
                onBlur={getCodeForm.handleBlur('field')}
                error={
                  getCodeForm.touched.field
                    ? getCodeForm.errors.field
                    : undefined
                }
              />
            )}
          </S.Content>
          <Button
            disabled={!!getCodeForm.errors.field}
            fullWidth
            data-testid="btn-get-code"
            type="button"
            onClick={getCodeForm.submitForm}
          >
            Prosseguir
          </Button>
        </S.Step>
        <S.Step>
          <S.Content>
            <S.CodeInputWrapper>
              <TextButton size="small" style={{ alignSelf: 'flex-start' }}>
                Código
              </TextButton>
              <S.CodeInput>
                {codeFields.map((input, index) => (
                  <TextField
                    key={input.name}
                    name={input.name}
                    autoComplete="off"
                    className={input.name}
                    ref={(ref) => {
                      if (ref) {
                        elRefs[index] = ref
                      }
                    }}
                    data-testid={input.name}
                    value={setPasswordForm.values[input.name] || ''}
                    onChange={onCodeChange}
                    onBlur={setPasswordForm.handleBlur(input.name)}
                    onKeyUp={(e) => changeFocus(e, index)}
                  />
                ))}
              </S.CodeInput>
              <FakeUserInput />
              <FakePasswordInput />
              <TextButton
                style={{ alignSelf: 'flex-end' }}
                underline
                size="small"
                onClick={swiper.slidePrev}
              >
                Reenviar Código
              </TextButton>
            </S.CodeInputWrapper>
          </S.Content>
          <Button fullWidth type="button" onClick={swiper.slideNext}>
            Prosseguir
          </Button>
        </S.Step>
        <S.Step>
          <S.Content>
            <PasswordField
              style={{
                marginBottom: '24px'
              }}
              label="Crie uma senha:"
              name="password"
              initialValue={setPasswordForm.values.password}
              onInputChange={setPasswordForm.handleChange('password')}
              onBlur={setPasswordForm.handleBlur('password')}
              error={
                setPasswordForm.touched.password
                  ? setPasswordForm.errors.password
                  : undefined
              }
            />
            <PasswordField
              label="Confirme sua senha:"
              name="passwordConfirmation"
              initialValue={setPasswordForm.values.passwordConfirmation}
              onInputChange={setPasswordForm.handleChange(
                'passwordConfirmation'
              )}
              onBlur={setPasswordForm.handleBlur('passwordConfirmation')}
              error={
                setPasswordForm.touched.passwordConfirmation
                  ? setPasswordForm.errors.passwordConfirmation
                  : undefined
              }
            />
          </S.Content>
          <Button
            disabled={!setPasswordForm.isValid}
            fullWidth
            type="button"
            data-testid="btn-change-password"
            onClick={setPasswordForm.submitForm}
          >
            Salvar
          </Button>
        </S.Step>
      </Carousel>
      <Modal
        title="Código enviado com sucesso!"
        show={showGettedCodeModal}
        close={() => setShowGettedCodeModal(false)}
      />
      <Modal
        title="Senha definida com sucesso!"
        show={showConfirmationModal}
        close={() => history.push('/login')}
      />
    </S.Wrapper>
  )
}

const EmailValidationSchema = yup.object().shape({
  field: yup.string().email().required()
})

const PhoneValidationSchema = yup.object().shape({
  field: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, 'Telefone inválido')
    .required()
})

const setPasswordValidationSchema = yup.object().shape({
  codeChar1: yup.string().required(),
  codeChar2: yup.string().required(),
  codeChar3: yup.string().required(),
  codeChar4: yup.string().required(),
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação diferente da senha')
    .required()
})
