import { ReactComponent as AccompanyingIcon } from 'presentation/assets/icons/accompanying.svg'
import { ReactComponent as OrientationIcon } from 'presentation/assets/icons/orientation.svg'
import { ReactComponent as DocumentsIcon } from 'presentation/assets/icons/documents.svg'
import { ReactComponent as NewSurgicalOrderIcon } from 'presentation/assets/icons/surgery-icon.svg'
import React, { ReactNode } from 'react'

type ButtonJourneyProps = {
  icon: ReactNode | string
  label: string
  path: string
  needSurgery: boolean
}

export const journeyButtons: ButtonJourneyProps[] = [
  {
    path: '/pedido-cirurgico',
    label: 'Novo Pedido Cirúrgico',
    needSurgery: false,
    icon: <NewSurgicalOrderIcon />
  },
  {
    path: '/orientacoes/cirurgicas/pre-cirurgica',
    label: 'Orientações',
    needSurgery: false,
    icon: <OrientationIcon />
  },
  {
    path: '/cirurgia/documentos',
    icon: <DocumentsIcon />,
    label: 'Documentos',
    needSurgery: true
  },
  {
    path: '/acompanhantes',
    icon: <AccompanyingIcon />,
    label: 'Acompanhantes',
    needSurgery: true
  }
]
