import React, { useEffect, useState } from 'react'
import { CompletePatientSignup as ICompletePatientSignup } from 'domain/usecases/patient/complete-patient-signup'
import { CompletePatientSignupLayout } from 'presentation/patient/layouts/CompletePatientSignup'
import { LoadUserByInviteToken } from 'domain/usecases/authentication/get-user-by-invite-token'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import Modal from 'presentation/shared/components/Modal'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

type Props = {
  patientCompleteSignup: ICompletePatientSignup
  loadUserByInviteToken: LoadUserByInviteToken
}

export const CompletePatientSignup = WithLoading(
  ({
    patientCompleteSignup,
    loadUserByInviteToken,
    setIsLoading
  }: Props & WithLoadingProps) => {
    const [userName, setUserName] = useState<string>('')
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const params = useParams<{ hash: string }>()
    const history = useHistory()

    useEffect(() => {
      async function loadData() {
        try {
          setIsLoading(true)
          const user = await loadUserByInviteToken.load(params.hash, ['name'])
          setUserName(user.name)
        } catch (e) {
          setShowErrorModal(true)
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])
    return (
      <>
        <CompletePatientSignupLayout
          patientCompleteSignup={patientCompleteSignup}
          patientName={userName}
          inviteToken={params.hash}
        />
        <Modal
          type="alert"
          title="Token inválido!"
          show={showErrorModal}
          close={() => history.push('/login')}
        />
      </>
    )
  }
)
