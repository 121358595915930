import React from 'react'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import Accompanyings from 'presentation/patient/pages/Accompanyings'
import { RemoteDeleteAccompanying } from 'service/usecases/delete-accompanying/remote-delete-accompanying'
import { AccompanyingRepository } from 'repository/repositories/accompanying/accompanying-repository'
import { RemoteUpdateAccompanying } from 'service/usecases/update-accompanying/remote-update-accompanying'
import { makeApiRepository } from 'main/factories/repositories/api-repository-factory'
import { makePatientRepository } from '../make-patient-repository-factory'

export default function makePatientAccompanyings() {
  const apiRepository = makeApiRepository()
  const patientRepository = makePatientRepository()

  const accompanyingRepository = new AccompanyingRepository(apiRepository)
  const loadPatient = new RemoteLoadPatientInfo(patientRepository)
  const deleteAccompanying = new RemoteDeleteAccompanying(
    accompanyingRepository
  )
  const updateAccompanying = new RemoteUpdateAccompanying(
    accompanyingRepository
  )

  return (
    <Accompanyings
      updateAccompanying={updateAccompanying}
      loadPatientInfo={loadPatient}
      deleteAccompanying={deleteAccompanying}
    />
  )
}
