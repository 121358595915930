import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  AppendSurgicalOrderDocumentLayout,
  SurgicalOrderListFileType
} from 'presentation/shared/layouts/AppendSurgicalOrderDocument'
import { PatientDocument } from 'common/enum/patient-document'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { getThumbnail } from 'presentation/shared/components/UploadSection/Functions'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'

interface DocumentLocation {
  name: string
  type: PatientDocument
  document_id: number
  documentUri?: string
  group_id?: string
  isMerged?: boolean
}

export const AppendSurgicalOrderDocument = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const [defaultFiles, setDefaultFiles] = useState<
      ListFile<SurgicalOrderListFileType>[]
    >([])
    const { type } = useParams<{ type: string }>()
    const { state } = useLocation<{
      surgicalOrderId: number
      isSolicitation?: boolean
      initialValues?: DocumentLocation[]
      documents?: DocumentLocation[]
      title?: string
    }>()
    const service = useServices().surgicalOrder
    const currentUser = useStores().currentAccount
    const selectedDocumentType = documentTypes[type]
    const isPatient =
      currentUser.getCurrentAccount()?.user?.role === Profile.PATIENT

    useEffect(() => {
      async function loadData() {
        const documentsState = state?.initialValues
          ? state?.initialValues
          : (state?.documents || []).filter(
              (document) => documentTypes[type].type === document.type
            )
        try {
          setIsLoading(true)
          const downloadedFiles: ListFile<SurgicalOrderListFileType>[] = []
          let index = 0

          for (const doc of documentsState || []) {
            const response = await service.loadSurgicalOrderDocument({
              surgical_order_id:
                isPatient && doc.group_id
                  ? doc.group_id
                  : state.surgicalOrderId,
              document_id: doc.document_id
            })
            const page = ++index
            let fileName = `${doc.name} (${page})`
            if (doc.documentUri) {
              const extension = doc.documentUri.split('.').pop()
              fileName += `.${extension}`
            }
            const file = new File([response.data], fileName, {
              type: response.contentType
            })
            downloadedFiles.push({
              file,
              thumbnail: await getThumbnail(file),
              page,
              identifier: {
                document_id: doc.document_id,
                type: doc.type
              }
            })
          }
          setDefaultFiles(downloadedFiles)
        } catch (error: any) {
          toast.error(error?.message)
        } finally {
          setIsLoading(false)
        }
      }
      loadData()
    }, [])

    return (
      <AppendSurgicalOrderDocumentLayout
        type={selectedDocumentType.type}
        title={state.title}
        surgicalOrderId={state.surgicalOrderId}
        initialValues={defaultFiles}
        isSolicitation={state.isSolicitation}
        isPatient={isPatient}
      />
    )
  }
)

type DocumentTypes = {
  type: PatientDocument
}

const documentTypes: Record<string, DocumentTypes> = {
  pessoais: {
    type: PatientDocument.IDENTITY_CARD
  },
  convenio: {
    type: PatientDocument.HEALTH_CARD
  },
  'pedido-medico': {
    type: PatientDocument.MEDICAL_ORDER
  },
  'relatorio-medico': {
    type: PatientDocument.MEDICAL_REPORT
  },
  'consent-cirurgico': {
    type: PatientDocument.SURGICAL_CONSENT
  },
  'consent-anestesico': {
    type: PatientDocument.ANESTHETIC_CONSENT
  },
  'pre-anestesico': {
    type: PatientDocument.PRE_ANESTHETIC_EVALUATION
  },
  'laudos-exames': {
    type: PatientDocument.EXAM_REPORT
  },
  'risco-cirurgico': {
    type: PatientDocument.SURGICAL_RISK
  },
  'documento-exame': {
    type: PatientDocument.EXAM_DOCUMENT
  },
  'pedido-cirurgico': {
    type: PatientDocument.SURGICAL_ORDER
  }
}
