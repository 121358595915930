import { Profile } from 'common/enum/profile'
import { AccountModel } from 'domain/entities/account-model'
import { LoadUserRole } from 'domain/usecases/authentication/load-user-role'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { PbacAdapter } from 'infra/pbac-adapter/pbac-adapter'
import { DoctorPermissions } from './doctor-permissions-context'
import { Permissions } from './permissions'
import { DefaultPermissions } from './permissions-context'

export class PermissionsFactory {
  constructor(
    private pbacAdapter: PbacAdapter,
    private loadUserRole: LoadUserRole,
    private getCurrentUser: () => AccountModel,
    private loadDoctor: LoadDoctor
  ) {}
  build(role: Profile): Permissions {
    if (role === Profile.DOCTOR) {
      return new DoctorPermissions(
        this.pbacAdapter,
        this.loadUserRole,
        this.getCurrentUser,
        this.loadDoctor
      )
    }
    return new DefaultPermissions(
      this.pbacAdapter,
      this.loadUserRole,
      this.getCurrentUser
    )
  }
}
