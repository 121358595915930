import React from 'react'

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Heading from 'presentation/shared/components/Heading'

import * as S from '../styles'
import { useHistory } from 'react-router-dom'

type EquipmentsReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function EquipmentsReview({
  surgicalOrder,
  visualizationMode
}: EquipmentsReviewProps) {
  const history = useHistory()

  return (
    <>
      <S.ReviewHeader>
        <Heading weight={500}>Equipamentos</Heading>
        {!visualizationMode && (
          <small
            onClick={() =>
              history.push('/pedido-cirurgico/equipamentos', {
                surgical_order_id: surgicalOrder.surgical_order_id,
                surgicalOrder
              })
            }
          >
            Editar
          </small>
        )}
      </S.ReviewHeader>
      {surgicalOrder.equipment?.length ? (
        <S.InfoColumnContainer>
          {surgicalOrder.equipment?.map((equipment) => (
            <>
              <h6>{equipment.name}</h6>
              <span>{equipment.specification}</span>
            </>
          ))}
        </S.InfoColumnContainer>
      ) : (
        <span>Não há equipamentos</span>
      )}
    </>
  )
}
