import React from 'react'

import SuggestionsOrCriticismsLayout from 'presentation/patient/layouts/SuggestionsOrCriticisms'
import { CreateSuggestionOrCriticism } from 'domain/usecases/patient/create-suggestion-or-criticism'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'

type Props = {
  createSuggestionOrCriticism: CreateSuggestionOrCriticism
  loadHospitals?: LoadHospitals
}

export default function SuggestionsOrCriticisms({
  createSuggestionOrCriticism,
  loadHospitals
}: Props) {
  return (
    <SuggestionsOrCriticismsLayout
      createSuggestionOrCriticism={createSuggestionOrCriticism}
      loadHospitals={loadHospitals}
    />
  )
}
