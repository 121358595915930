import React, { useState, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import * as S from './styles'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import Header from 'presentation/shared/components/Header'
import InternationDetails from 'presentation/shared/components/InternationDetails'
import ActualPage from 'presentation/shared/components/ActualPage'
import { Container } from 'presentation/shared/components/Container'
import { useStores } from 'presentation/hooks/use-stores'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

type Location = {
  hospital?: number
}

type Props = {
  loadSurgicalOrder: LoadSurgicalOrder
}
export default function HospitalizationLayout({ loadSurgicalOrder }: Props) {
  const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>({})
  const { state } = useLocation<Location>()

  const { hospital } = state || {}

  const history = useHistory()

  const surgerySelected = useStores().currentSurgery.getSurgeryIdSelected()

  const cards: { label: string; uri: string; data?: any }[] = [
    // { label: 'Meus Horários', uri: '/horarios' },
    { label: 'Como estou me sentindo', uri: '/como-estou' },
    // { label: 'Meu diário de internação', uri: '/diario' },
    // { label: 'Status da internação', uri: '/internacao-status' },
    // { label: 'Alergias', uri: '/alergia' },
    {
      label: 'Informações e acessos',
      uri: `/acessos`,
      data: { hospital }
    },
    { label: 'Fale com os profissionais', uri: '/chat' },
    { label: 'Solicitação de serviço', uri: '/solicitacao' },
    {
      label: 'Elogios, sugestões ou reclamações',
      uri: '/sugestoes-criticas',
      data: surgicalOrder
    }
  ]

  async function loadSurgicalOrderAndPatientData(surgicalId: number) {
    const surgicalOrderData = await loadSurgicalOrder.load(surgicalId, [
      'surgical_order_id',
      'patient {patient_id, name, phone, email, birthday, cpf, healthInsurance {healthInsuranceName, healthInsuranceCode}}',
      'procedure {code, description, quantity, doctor_name, isMain, surgery_id}',
      'opme {solicitations {description, quantity}, providers}',
      'freeze',
      'cid {code, description}',
      'hospitalizationMode',
      'hospitalizationType',
      'healthInsurance {healthInsuranceName, healthInsuranceCode}',
      'allergy',
      'bloodTransfusion',
      'hospital {name}',
      'clinicalHistory',
      'patientHospitalized'
    ])
    setSurgicalOrder(surgicalOrderData)
  }

  useEffect(() => {
    if (surgerySelected !== 0) {
      loadSurgicalOrderAndPatientData(surgerySelected)
    }
  }, [surgerySelected])
  return (
    <>
      <Header />
      <Container>
        <S.Header>
          <ActualPage text="Minha internação" onClick={history.goBack} />
        </S.Header>
        <InternationDetails vitalData surgicalOrder={surgicalOrder} />
        <S.CardsContainer>
          {cards.map((item) => (
            <S.CardItem
              key={item.uri}
              onClick={() => history.push(item.uri, { data: item.data })}
            >
              <span>{item.label}</span>
            </S.CardItem>
          ))}
        </S.CardsContainer>
      </Container>
      <BottomMenu />
    </>
  )
}
