import React from 'react'
import { CompletePatientSignup as ICompletePatientSignup } from 'domain/usecases/patient/complete-patient-signup'
import { CompletePatientSignupForm } from 'presentation/patient/components/Forms/CompletePatientSignup'

type Props = {
  patientCompleteSignup: ICompletePatientSignup
  patientName: string
  inviteToken: string
}

export const CompletePatientSignupLayout = ({
  patientCompleteSignup,
  patientName,
  inviteToken
}: Props) => {
  return (
    <CompletePatientSignupForm
      patientCompleteSignup={patientCompleteSignup}
      patientName={patientName}
      inviteToken={inviteToken}
    />
  )
}
