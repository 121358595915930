import Heading from 'presentation/shared/components/Heading'
import React from 'react'
import OpmeImage from '../../../assets/opme.png'
import * as S from '../styled'
import { SurgeryJourney } from '../index'
import theme from 'presentation/styles/theme'

export function HospitalDischarge({ goBack, goNext }: SurgeryJourney) {
  return (
    <S.Wrapper>
      <S.Header>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goBack}
        >
          <path
            d="M8 2L3 9L8 16"
            stroke={theme.colors.primary}
            strokeOpacity="0.5"
            strokeWidth="4"
          />
        </svg>
        <Heading as="h1" size="large">
          Alta Hospitalar
        </Heading>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goNext}
        >
          <path
            d="M2 16L7 9L2 2"
            stroke={theme.colors.primary}
            strokeWidth="4"
          />
        </svg>
      </S.Header>

      <p>
        A alta hospitalar marca a transição do paciente para a recuperação em
        casa. Durante esse processo, o paciente recebe instruções detalhadas
        sobre medicações, restrições e cuidados. É fornecido um plano de
        acompanhamento, incluindo consultas médicas regulares, para garantir uma
        recuperação eficaz e segura.
      </p>
      <br />

      <S.Image src={OpmeImage} alt="Alta do hospital" />
    </S.Wrapper>
  )
}
