import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from 'react-router-dom'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'

import PrivateRoute from 'presentation/shared/components/PrivateRoute'
import makePatientHome from 'main/factories/pages/patient/home/patient-home-factory'
import makePatientLogin from 'main/factories/pages/patient/login/patient-login-factory'
import PasswordRecovery from 'main/factories/pages/patient/password-recovery/patient-password-recovery-factory'
import makeAddPainAlert from 'main/factories/pages/patient/add-pain-alert/patient-add-pain-alert-factory'
import makePatientSignup from 'main/factories/pages/patient/signup/patient-signup-factory'
import makePatientProfile from 'main/factories/pages/patient/profile/patient-profile-factory'
import makePatientAllergies from 'main/factories/pages/patient/allergy/patient-allergy-factory'
import makePatientLifeHabits from 'main/factories/pages/patient/life-habits/patient-life-habits-factory'
import makePatienSurgeryInfo from 'main/factories/pages/patient/surgery-info/patient-surgery-info-factory'
import makePatientSolicitation from 'main/factories/pages/patient/solicitation/patient-solicitation-factory'
import makePatientAccompanyings from 'main/factories/pages/patient/accompanyings/patient-accompanyings-factory'
import makeDoctorAnswerPendency from 'main/factories/pages/doctor/pendency/doctor-pendency-factory'
import makePatientCompleteSignup from 'main/factories/pages/patient/complete-signup/patient-complete-signup-factory'
import makePatientSurgeryDetails from 'main/factories/pages/patient/surgery-details/patient-surgery-details-factory'
import makePatientHospitalization from 'main/factories/pages/patient/hospitalization/patient-hospitalization-factory'
import makeAddPatientAccompanyings from 'main/factories/pages/patient/add-accompanying/add-accompanying-factory'
import makePatientSaveSurgicalOrder from 'main/factories/pages/patient/create-surgical-order/save/save-surgical-order-factory'
import makePatientUpdatePersonalInfo from 'main/factories/pages/patient/update-personal-info/patient-update-personal-info-factory'
import makePatientMedicineAndDiseases from 'main/factories/pages/patient/medicines-and-diseases/patient-medicines-and-diseases-factory'
import makePatientSuggestionOrCriticism from 'main/factories/pages/patient/suggestion-or-criticism/patient-suggestion-or-criticism-factory'
import makePatientSaveSurgicalOrderPersonalInfo from 'main/factories/pages/patient/create-surgical-order/save-personal-info/save-surgical-order-personal-info'
import makePatientSaveSurgicalOrderInsuranceInfo from 'main/factories/pages/patient/create-surgical-order/save-insurance-info'
import Help from 'presentation/patient/pages/Help'
import Checkin from 'presentation/patient/pages/Checkin'
import Services from 'presentation/patient/pages/Services'
import Attendances from 'presentation/patient/pages/Attendances'
import AddDocuments from 'presentation/patient/pages/CreateSurgicalOrder/AddDocuments'
import InfoAndAccess from 'presentation/patient/layouts/InfoAndAccess'
import AddSurgicalOrder from 'presentation/patient/pages/CreateSurgicalOrder/AppendSurgicalOrder'
import RegisterValidate from 'presentation/patient/pages/RegisterValidate'
import SurgeryStatusDetail from 'presentation/shared/pages/SurgeryStatusDetail'
import InvalidRegisterLayout from 'presentation/patient/layouts/InvalidRegister'
import CreateSurgicalOrderIntro from 'presentation/patient/pages/CreateSurgicalOrder/Intro'
import { MedicalReports } from 'presentation/shared/pages/MedicalReports'
import { PatientPendencies } from 'presentation/patient/pages/Pendencies'
import { SurgicalJourneyOrientations } from 'presentation/patient/layouts/surgicalJourney/orientations'
import { AppendSurgicalOrderDocument } from 'presentation/shared/pages/AppendSurgicalOrderDocument'
import makeOnboarding from 'main/factories/pages/patient/onboarding/onboarding-factory'
import makePatientReviewSurgicalOrder from 'main/factories/pages/patient/review-surgical-order/patient-review-surgical-order-factory'

export const ScrollToTop = withRouter(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
})

export default function PatientRouter() {
  const stores = useStores()
  const currentUser = stores.currentAccount
  const surgicalstatus = stores.surgicalstatus

  useEffect(() => {
    async function loadData() {
      if (!window.location.pathname.match('login')) {
        await surgicalstatus.load()
      }
    }
    loadData()
  }, [])

  return (
    <BrowserRouter
      basename={process.env.NODE_ENV === 'development' ? '/patient' : ''}
    >
      <ScrollToTop />
      <Switch>
        <Route
          path="/"
          exact
          component={() => {
            return (
              <>
                <Redirect
                  to={
                    currentUser.getCurrentAccount()?.accessToken
                      ? '/home'
                      : '/login'
                  }
                />
              </>
            )
          }}
        />
        <Route path="/cadastro" exact component={makePatientSignup} />
        <Route
          path="/cadastro/:hash"
          exact
          component={makePatientCompleteSignup}
        />
        <Route
          path="/login"
          exact
          component={() => makePatientLogin(Profile.PATIENT)}
        />
        <Route
          path="/recuperar-senha/:type"
          exact
          component={() => PasswordRecovery({ client: Profile.PATIENT })}
        />
        <PrivateRoute
          path="/pedido-cirurgico"
          exact
          component={CreateSurgicalOrderIntro}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/pendencias"
          exact
          component={PatientPendencies}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/pendencia"
          exact
          component={makeDoctorAnswerPendency}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/save"
          exact
          component={makePatientSaveSurgicalOrder}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/save/dados-pessoais"
          exact
          component={makePatientSaveSurgicalOrderPersonalInfo}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/save/dados-convenio"
          exact
          component={makePatientSaveSurgicalOrderInsuranceInfo}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/save/documentos"
          exact
          component={AddDocuments}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/pedido-cirurgico/save/documentos/anexo"
          exact
          component={AddSurgicalOrder}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/home"
          exact
          component={makePatientHome}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/onboarding"
          exact
          component={makeOnboarding}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/cirurgia"
          component={makePatientSurgeryDetails}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/orientacoes/cirurgicas"
          component={makePatienSurgeryInfo}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/ajuda"
          component={Help}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/servicos"
          component={Services}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/atendimentos"
          component={Attendances}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/internacao"
          component={makePatientHospitalization}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/upload/documentos/:type"
          exact
          component={AppendSurgicalOrderDocument}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/upload/laudos"
          exact
          component={MedicalReports}
          profile={[Profile.PATIENT]}
        />
        {/* <PrivateRoute
          path="/detalhes-status"
          exact
          component={SurgeryStatusDetail}
          profile={[Profile.PATIENT]}
        /> */}
        <PrivateRoute
          path="/acompanhantes"
          exact
          component={makePatientAccompanyings}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/acompanhantes/save"
          exact
          component={makeAddPatientAccompanyings}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/checkin"
          exact
          component={Checkin}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/como-estou"
          exact
          component={makeAddPainAlert}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/solicitacao"
          exact
          component={makePatientSolicitation}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/habitos"
          exact
          component={makePatientLifeHabits}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/sugestoes-criticas"
          exact
          component={makePatientSuggestionOrCriticism}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/medicamentos-doencas"
          exact
          component={makePatientMedicineAndDiseases}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/informacoes-pessoais"
          exact
          component={makePatientUpdatePersonalInfo}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/alergia"
          exact
          component={makePatientAllergies}
          profile={[Profile.PATIENT]}
        />
        <PrivateRoute
          path="/minha-jornada"
          exact
          component={SurgicalJourneyOrientations}
          profile={[Profile.PATIENT]}
        />

        <PrivateRoute
          path="/acessos"
          exact
          component={InfoAndAccess}
          profile={[Profile.PATIENT]}
        />

        <PrivateRoute
          path="/perfil"
          exact
          component={makePatientProfile}
          profile={[Profile.PATIENT]}
        />

        <PrivateRoute
          path="/pedido-cirurgico/resumo"
          exact
          component={makePatientReviewSurgicalOrder}
          profile={[Profile.PATIENT]}
        />

        <Route path="/cadastro-validacao" exact component={RegisterValidate} />
        <Route
          path="/invalid-register"
          exact
          component={InvalidRegisterLayout}
        />
      </Switch>
    </BrowserRouter>
  )
}
