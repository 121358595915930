import React, { useState } from 'react'

import { useFormik } from 'formik'

import * as yup from 'yup'
import 'main/config/yup'

import * as S from './styles'
import { CompletePatientSignup as ICompletePatientSignup } from 'domain/usecases/patient/complete-patient-signup'
import PasswordField from 'presentation/shared/components/PasswordField'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import SheetModal from 'presentation/shared/components/SheetModal'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import TermsContainer from 'presentation/shared/components/TermsContainer'
import PatientTerms from 'presentation/assets/terms/patient-terms'
import { Container } from 'presentation/shared/components/Container'

type Props = {
  patientCompleteSignup: ICompletePatientSignup
  patientName: string
  inviteToken: string
}

export const CompletePatientSignupForm = ({
  patientCompleteSignup,
  patientName,
  inviteToken
}: Props) => {
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [carousel, setCarousel] = useState<CarouselState>({} as CarouselState)
  const history = useHistory()

  const goToLogin = () => {
    history.push('/login')
  }

  const formik = useFormik<CompletePatientSignupFormValues>({
    initialValues: {
      password: '',
      passwordConfirmation: '',
      terms: false
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await patientCompleteSignup.add({
          inviteToken,
          password: values.password
        })
        setShowSuccessModal(true)
      } catch (e) {
        toast.error('Ocorreu um erro ao finalizar seu cadastro!')
      }
    }
  })

  const validateStep: Record<number, { isValid: boolean }> = {
    0: {
      isValid: !formik.errors.password && !formik.errors.passwordConfirmation
    },
    1: {
      isValid: formik.values.terms
    }
  }

  return (
    <S.Wrapper onSubmit={formik.handleSubmit}>
      <Container
        title={
          <Heading as="h4">
            Bem-vindo(a) à <strong>Jornada Cirúrgica</strong>
          </Heading>
        }
        subtitle={`Olá ${patientName}, insira sua senha para finalizar seu cadastro`}
        primaryButton={
          <Button
            fullWidth
            type={carousel.isLast ? 'submit' : 'button'}
            disabled={
              !validateStep[carousel.activeIndex]?.isValid ||
              formik.isSubmitting
            }
            onClick={carousel.isLast ? formik.submitForm : carousel.slideNext}
          >
            Salvar
          </Button>
        }
      >
        <Carousel state={carousel} setState={setCarousel}>
          <S.Step>
            <S.Content>
              <PasswordField
                label="Senha:"
                name="password"
                id="password"
                error={
                  formik.touched.password ? formik.errors.password : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <PasswordField
                label="Confirmar senha:"
                name="passwordConfirmation"
                id="passwordConfirmation"
                error={
                  formik.touched.passwordConfirmation
                    ? formik.errors.passwordConfirmation
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ marginTop: '10px' }}
                value={formik.values.passwordConfirmation}
              />
            </S.Content>
          </S.Step>
          <S.Step>
            <S.Content>
              <Heading style={{ marginTop: '8px' }}>Termo de Uso Geral e Política de Privacidade da Plataforma</Heading>
              <TermsContainer
                style={{ height: 'auto', margin: '0', flex: '1' }}
                onChange={(val) => formik.setFieldValue('terms', val)}
              >
                <PatientTerms />
              </TermsContainer>
            </S.Content>
          </S.Step>
        </Carousel>
      </Container>
      <SheetModal
        isOpen={showSuccessModal}
        onClose={goToLogin}
        isDraggable={false}
        size={250}
      >
        <S.ConfirmationModal>
          <Heading>
            Cadastro efetuado com <strong>sucesso</strong>
          </Heading>
          <SupportText>
            Agora você pode utilizar todos os recursos do{' '}
            <strong>Jornada Cirúrgica</strong>
          </SupportText>
          <Button fullWidth onClick={goToLogin}>
            Fazer Login
          </Button>
        </S.ConfirmationModal>
      </SheetModal>
    </S.Wrapper>
  )
}

type CompletePatientSignupFormValues = {
  password: string
  passwordConfirmation: string
  terms: boolean
}

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação diferente da senha')
    .required(),
  terms: yup.boolean().oneOf([true]).required()
})
