import React, { HTMLAttributes } from 'react'

import { DefaultTheme } from 'styled-components'

import * as S from './styles'

export type ErrorProps = {
  color: keyof DefaultTheme['colors']
  size: keyof DefaultTheme['font']['sizes']
  children: string
} & HTMLAttributes<HTMLSpanElement>

export default function Error({ color, size, children, ...props }: ErrorProps) {
  return (
    <S.ErrorSpan color={color} size={size} {...props}>
      {children}
    </S.ErrorSpan>
  )
}
