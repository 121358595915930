import React from 'react'
import * as S from './styles'

export default function PatientTerms() {
  return (
    <p>
      <br />
      Este é um termo firmado entre você, de agora em diante denominado como usuário, e a RedFox Soluções Digitais LTDA, empresa cadastrada no CNPJ sob nº 27.208.515/0001-38 com sede no município de São Paulo, capital do estado, a Av. Ipiranga, nº 508, Sala 71, bairro República, CEP 01046-010, e de agora em diante denominada simplesmente de RedFox. Este “Termo de Uso de Aplicativo” rege o uso da plataforma Jornada Cirúrgica.
      <br />
      <br />
      <strong>Do objeto</strong><br />
      <br />
      A plataforma caracteriza-se pela prestação do serviço visando facilitar a interação entre as instituições hospital, médico cirurgião/ secretárias com seus respectivos pacientes durante a jornada cirúrgica possibilitando a melhor visibilidade desde o cadastro do pedido cirúrgico até sua aprovação.
      É centralizado as informações do pedido cirúrgico do paciente e o acompanhamento do avanço de todas as etapas com tratamento de pendências entre todos os atores da jornada até que o pedido da cirurgia seja liberado pelo hospital.
      <br />
      <br />
      <strong>Da aceitação</strong><br />
      <br />
      O presente Termo estabelece obrigações de livre e espontânea vontade, por tempo indeterminado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do aplicativo. <br />
      Ao utilizar a plataforma o usuário aceita integralmente as presentes diretrizes e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis.
      A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de quaisquer serviços fornecidos na plataforma. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los.
      <br />
      <br />
      <strong>Do acesso dos usuários</strong><br />
      <br />
      Serão utilizadas soluções técnicas visando permitir o acesso ao serviço que a plataforma fornece aos usuários. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento.
      <br />
      <br />
      <strong>Do cadastro</strong> <br />
      <br />
      O acesso às funcionalidades da plataforma exigirá a realização de um cadastro para o Hospital como Administrador da conta que fará a liberação dos cadastros dos usuários Médicos, Secretárias e Paciente de acordo com seu atendimento referente a jornada cirúrgica. <br />
      Durante a confirmação do cadastro pelo usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se compromete com a veracidade dos dados fornecidos. Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços e produtos.<br />
      Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais. Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a Instituição que você é colaborador, em caso de uso indevido da respectiva senha.<br />
      Não é permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que é pessoal e intransferível.  Caberá ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilize a utilização da plataforma e dos serviços.<br />
      O usuário terá direito a apenas um cadastro e será o único responsável pelas operações efetuadas em seu perfil. Você não deverá informar a terceiros os dados de seu perfil, responsabilizando-se civil e criminalmente pelo uso indevido e por quaisquer práticas ilícitas que interfiram, manipulem ou prejudiquem o Aplicativo ou as informações nele contidas.<br />
      O usuário, ao aceitar o Termo e Política de Privacidade, autoriza expressamente a plataforma a coletar, armazenar e tratar as informações derivadas do uso dos serviços, da plataforma.<br />
      <br />
      <strong>Dos serviços</strong> <br />
      <br />
      A RedFox emprega sistemas de segurança e procedimentos técnicos, físicos e gerenciais usualmente adotados pelo mercado para proteger seus Dados Pessoais. Não há como garantir possíveis dificuldades ou falhas de conexão com os servidores, pois o aplicativo está sujeito à interferência de fatores externos.
      O cadastro do pedido cirúrgico pode ser feito pelo Hospital, Médico, Secretária ou pelo próprio paciente. Cada instituição/ persona consegue acompanhar o avanço das etapas e consultar o resumo do pedido até a aprovação da cirurgia pelo Hospital.
      <br />
      Se você acessar o site de parceiros, patrocinadores, Instituições/Anunciantes e demais sites que estejam disponíveis na plataforma, é possível que haja solicitação de informações financeiras ou pessoais. Entretanto, ao enviar estas informações, você enviará diretamente ao solicitante e não para a Platatorma. Portanto, a RedFox não tem qualquer responsabilidade pela utilização e manejo dessas informações.
      <br />
      <br />
      <strong>Do cancelamento</strong> <br />
      <br />
      O usuário poderá solicitar o cancelamento do uso da plataforma diretamente para a instituição na qual trabalha. E caso ocorra alguma violação do Termo de Uso, os serviços poderão ser cessados imediatamente por parte da instituição ou da RedFox.
      Do suporte
      Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário poderá entrar em contato com a instituição que você é colaborador.
      <br />
      <br />
      <strong>Das responsabilidades</strong> <br />
      <br />
      É de responsabilidade do usuário:
      <br />
      <br />
      <S.Span>
        a) a correta utilização da plataforma, dos serviços oferecidos, prezando pela boa convivência, pelo respeito e cordialidade entre os usuários;
      </S.Span>
      <S.Span>
        b) pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condições Geral de Uso, na respectiva Política de Privacidade e na legislação nacional e internacional;
      </S.Span>
      <S.Span>
        c) pela proteção aos dados de acesso à sua conta/perfil (login e senha);
      </S.Span>
      <br />
      Nota: Você reconhece que a RedFox não é parte de nenhuma transação, nem possui controle algum sobre a qualidade, segurança ou legalidade dos serviços dos usuários, sobre a veracidade ou exatidão dos pedidos cirúrgicos.A RedFox não assegura, em hipótese alguma, o êxito de qualquer transação.
      < br />
      < br />
      É de responsabilidade da RedFox:
      <br />
      <S.Span>
        a) indicar as características do serviço;
      </S.Span>
      <S.Span>
        b) os defeitos encontrados no serviço oferecido desde que lhe tenha dado causa;
      </S.Span>
      <S.Span>
        c) as informações que foram por ele divulgadas, sendo que os comentários ou informações divulgadas por usuários são de inteira responsabilidade dos próprios usuários;
      </S.Span>
      <S.Span>
        d) não utilizar os dados para contato de outros Usuários com outro propósito que não seja relacionado ao aqui exposto.
      </S.Span>
      <S.Span>
        e) não incluir links externos ou páginas que sirvam para fins comerciais ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.
      </S.Span>
      < br />
      NOTA:  A RedFox não se responsabiliza:
      <br />
      <S.Span>
        a) por qualquer dano ou prejuízo que você possa sofrer devido às negociações realizadas ou não realizadas através da Plataforma e decorrentes da conduta de outros Usuários.Este Termo não gera nenhum contrato de sociedade, de mandato, franquia ou relação de trabalho entre você e a RedFox.
      </S.Span>
      <S.Span>
        b) Pelas informações divulgadas no perfil dos usuários e não assume responsabilidade alguma por consequências que possam advir de qualquer relação entre você e algum outro usuário, seja ela direta ou indireta.
      </S.Span>
      <S.Span>
        c) pelas obrigações trabalhistas, previdenciárias ou tributárias que recaiam nas atividades entre Usuários e Instituições / Anunciantes.Assim, ao prestar algum serviço, você deverá exigir nota fiscal da Instituição / Anunciante, a menos que este esteja legalmente dispensado de fornecê - la.
      </S.Span>

      <br />
      <br />
      <strong>Dos direitos autorais</strong><br />
      <br />
      O presente Termo de Uso concede aos usuários uma licença não exclusiva, não transferível e não sublicenciável, para acessar e fazer uso da plataforma e dos serviços por ela disponibilizados. <br />
      A estrutura da plataforma, marca, logotipo, nome comercial, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da razão social RedFox Soluções Digitais LTDA, observados os termos da Lei da Propriedade Industrial(Lei nº 9.279 / 96), Lei de Direitos Autorais(Lei nº 9.610 / 98) e Lei do Software(Lei nº 9.609 / 98), estão devidamente reservados.<br />
      Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário, exceto pela licença limitada ora concedida.<br />
      O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não - comercial.Tais usos consistirão em violação dos direitos de propriedade intelectual da razão social RedFox Soluções Digitais, puníveis nos termos da legislação aplicável.
      A propriedade intelectual de todo o material apresentando na Plataforma é de titularidade da respectiva Instituição / Anunciante, incluindo marcas, logotipos, produtos, denominações de serviços, programas, bases de dados, imagens, arquivos ou materiais de qualquer outra espécie, que têm contratualmente autorizadas as suas veiculações neste Plataforma.<br />
      É proibida a reprodução de qualquer informação ou material da Plataforma, entendendo - se por reprodução todas as formas possíveis de cópia e distribuição / veiculação, sem a prévia autorização por escrito da respectiva Instituição / Anunciante.<br />
      As marcas comerciais apresentadas na Plataforma são de propriedade das respectivas Instituições / Anunciantes ou de seus clientes, parceiros ou fornecedores.A simples apresentação das marcas não poderá ser interpretada como concessão, sendo igualmente proibida a utilização ou o uso indevido de propriedade intelectual ou de marcas comerciais apresentadas no aplicativo será caracterizado como violação das leis sobre direitos autorais e de marcas comerciais e sujeitará o infrator às sanções de propriedade intelectual e / ou puníveis nos termos da legislação aplicável.<br />
      A RedFox pode modificar este Termo, caso as alterações a este Termo sejam significativas, solicitaremos um novo consentimento como condição à sua contínua utilização do Aplicativo.
      <br />
      <br />
      <strong>Das sanções</strong><br />
      <br />

      Sem prejuízo das demais medidas legais cabíveis, a Instituição do colaborador e / ou a RedFox poderá, a qualquer momento, advertir, suspender ou cancelar a conta do usuário:
      <S.Span>a) que violar qualquer dispositivo do presente Termo;</S.Span>
      <S.Span>b) que descumprir os seus deveres de usuário;</S.Span>
      <S.Span>c) que tiver qualquer comportamento fraudulento, doloso ou que ofenda a terceiros.</S.Span>

      <br />
      <strong>Das alterações</strong><br />
      <br />
      Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a qualquer tempo, por parte de RedFox, para adequar ou modificar os serviços, bem como para atender novas exigências legais.Caso as alterações a este Termo sejam significativas, solicitaremos um novo consentimento como condição à sua contínua utilização do Aplicativo.

      <br />
      <br />
      <strong>Da política de privacidade</strong><br />
      <br />
      A RedFox compreende a importância da privacidade das informações das partes interessadas, desta forma queremos que você entenda como procedemos no armazenamento, utilização e proteção, entre outros. Enquanto controlador de dados pessoais, a RedFox irá tratar seus Dados Pessoais em conformidade com esta Política de Privacidade e conforme a legislação, lei nº 13.709/2018 – LGPD prevê.
      <br />
      <br />
      A RedFox poderá compartilhar as informações, nas seguintes hipóteses:
      <br />
      <S.Ul>
        <li>
          Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para a solicitação.
        </li>
        <li>
          Provedores de serviço que auxiliem nos reparos e na publicidade do site, entre outros. Estes parceiros também estão comprometidos com a proteção das informações por eles acessadas.
        </li>
        <li>
          No caso de uma reorganização societária, fusão ou venda, poderemos transferir seus Dados Pessoais a terceiros que sejam relevantes nesses processos.
        </li>
        <li>
          Outras partes, mediante autorização do usuário e/ou da Instituição.
        </li>
      </S.Ul>
      As informações coletadas serão salvaguardadas na Nuvem por empresa terceira, nas condições: <br />
      <S.Ul>
        <li>
          O controle é restrito ao acesso dos dados somente para pessoas autorizadas.
        </li>
        <li>
          Aqueles que acessarem as informações deverão comprometer-se a manter sigilo. A quebra do sigilo acarretará responsabilidade civil e o responsável será penalizado conforme prevê a legislação brasileira.
        </li>
        <li>
          A RedFox adota os melhores esforços, no sentido de preservar a privacidade das informações das partes interessadas. Entretanto, nenhum site é totalmente seguro, e a RedFox não pode garantir integralmente que todas as informações que trafegam nas páginas não sejam alvo de acessos não autorizados através de métodos desenvolvidos para obter informações de forma indevida.
        </li>
      </S.Ul>
      Não manteremos informações por mais tempo do que o necessário para fins pelos quais foram coletadas. Isso significa que as informações serão destruídas ou apagadas quando não forem mais necessárias. <br />
      Em cumprimento à regulação aplicável, no que diz respeito ao tratamento de dados pessoais, a RedFox respeita e garante ao usuário, a possibilidade de apresentação de solicitações baseadas nas seguintes condições:
      <br />
      <S.Ul>
        <li>
          A confirmação da existência de tratamento.
        </li>
        <li>
          O acesso aos dados.
        </li>
        <li>
          A correção de dados incompletos, inexatos ou desatualizados.
        </li>
        <li>
          A anonimização, bloqueio ou eliminação dos dados desnecessários excessivos ou tratados em desconformidades.
        </li>
        <li>
          A eliminação dos dados tratados com consentimento do usuário.
        </li>
        <li>
          A obtenção de informações sobre as entidades públicas ou privadas com as quais a RedFox compartilhou seus dados.
        </li>
        <li>
          A informação sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as consequências, em caso de negativa.
        </li>
        <li>
          A revogação do consentimento.
        </li>
      </S.Ul>
      Os direitos aqui indicados podem ser solicitados para a Instituição ou para a RedFox, através dos canais de contato.
      <br />
      <br />
      <strong>Do foro</strong>
      <br />
      Estes Termos são regidos pelas leis brasileiras e se submetem ao foro Central da Comarca de São Paulo/SP para resolver qualquer controvérsia, renunciando a qualquer outro foro, por mais privilegiado que seja.
      <br />
      <br />
      <br />
      Este Termo foi atualizado no dia 14 de dezembro de 2023.
      <br />
      <br />
    </p >
  )
}
