import { AcceptUserTerm } from 'domain/usecases/user/acceptUserTerm'
import { ILoadUserContacts } from 'domain/usecases/user/load-contacts'
import { LoadUser } from 'domain/usecases/user/load-user'
import { UpdatePassword } from 'domain/usecases/user/update-password'
import { UpdateProfilePic } from 'domain/usecases/user/update-profile-pic'
import { UpdateUser } from 'domain/usecases/user/update-user'
import { UserAlreadyRegistered } from 'domain/usecases/user/user-already-registered'
import { toast } from 'react-toastify'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  acceptUserTerm,
  finishAppOnboardingMutation,
  updatePasswordMutation,
  updateUserMutation
} from 'repository/graphql/mutations'
import {
  loadUserContactsQuery,
  loadUserQuery,
  userAlreadyRegistered
} from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { IUserRepositoryRepository } from 'repository/interfaces/user-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { FinishAppOnboarding } from 'domain/usecases/user/finish-app-onboarding'

export class UserRepository implements IUserRepositoryRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadContacts(
    params: ILoadUserContacts.Params
  ): Promise<ILoadUserContacts.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ILoadUserContacts.Model>

    const query = loadUserContactsQuery(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ILoadUserContacts.Model
    }
  }

  async updatePassword(
    params: UpdatePassword.Params
  ): Promise<UpdatePassword.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePassword.Model>

    const query = updatePasswordMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePassword.Model
    }
  }

  async updateUser(params: UpdateUser.Params): Promise<UpdateUser.Model> {
    const apiRepository = this.apiRepository as IApiRepository<UpdateUser.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateUserMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: updateUserMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateUser.Model
    }
  }

  async load(params: LoadUser.Params): Promise<LoadUser.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadUser.Model>

    const query = loadUserQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadUser.Model
    }
  }

  async updateProfilePic(params: UpdateProfilePic.Params): Promise<void> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateProfilePic.Model>

    const httpResponse = await apiRepository.post({
      url: '/user/upload-photo',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      toast.info('foto de perfil atualizada')
      return httpResponse.body as UpdateProfilePic.Model
    }
  }

  async acceptTerm(
    params: AcceptUserTerm.Params
  ): Promise<AcceptUserTerm.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AcceptUserTerm.Model>

    const query = acceptUserTerm
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AcceptUserTerm.Model
    }
  }

  async userAlreadyRegistered(
    params: UserAlreadyRegistered.Params
  ): Promise<boolean> {
    const apiRepository = this.apiRepository as IApiRepository<boolean>

    const query = userAlreadyRegistered()
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.cpf, 'cpf'),
          ...makeGraphQLVariable(params.role, 'role')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as boolean
    }
  }

  async finishAppOnboarding(): Promise<FinishAppOnboarding.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishAppOnboarding.Model>

    const query = finishAppOnboardingMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishAppOnboarding.Model
    }
  }
}
