import React, { useState } from 'react'
import { useHistory } from 'react-router'

import Modal from 'presentation/shared/components/Modal'
import BottomMenu from 'presentation/shared/components/BottomMenu'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import WhatsAppButton from 'presentation/shared/components/Button/WhatsAppButton'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import Heading from 'presentation/shared/components/Heading'
import ServiceOverview, {
  ServiceOverviewProps
} from 'presentation/shared/components/ServiceOverview'
import SupportText from 'presentation/shared/components/SupportText'
import * as S from './styles'
import { journeyButtons } from './buttons-list'
import { openPhoneApp } from 'presentation/utils/mobile-actions/'
import LoadingGif from 'presentation/shared/components/LoadingGif'
import { ReactComponent as SurgeryJourneyBanner } from 'presentation/assets/banners/conheca-sua-jornada.svg'
import { Grid } from '@material-ui/core'

type PatientHomeLayoutProps = {
  name?: string
  attendances?: ServiceOverviewProps[]
  hasMoreAttendances: boolean
  surgeries?: ServiceOverviewProps[]
  redirect?: (url: string, state?: any) => void
  pendencies?: number
  isLoading?: boolean
}

export default function PatientHomeLayout({
  name,
  redirect,
  surgeries,
  attendances,
  hasMoreAttendances,
  pendencies,
  isLoading
}: PatientHomeLayoutProps) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const history = useHistory()
  const push = (url: string, state?: any) => {
    !!redirect && redirect(url, state)
  }
  const [appointmentModal, setAppointmentModal] = useState<boolean>(false)

  const CarouselChildren: JSX.Element[] = []

  if (attendances) {
    attendances.forEach((attendance) => {
      CarouselChildren.push(
        <ServiceOverview {...attendance} key={`${attendance.title}`} />
      )
    })
  }

  const getJourneyButtons = (): typeof journeyButtons => {
    if ((surgeries || []).length === 0) {
      return journeyButtons.filter((button) => !button.needSurgery)
    }
    return journeyButtons
  }

  return (
    <S.Wrapper>
      <Header />
      {pendencies ? (
        <S.PendencyAlert onClick={() => history.push('/pendencias')}>
          Você possui {pendencies} pendência
          {pendencies > 1 ? 's' : undefined}
        </S.PendencyAlert>
      ) : null}
      <Container>
        <Heading style={{ fontWeight: 700 }} size="medium" as="h3">
          Olá, {name}
        </Heading>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginTop: '.5rem',
            marginBottom: '16px'
          }}
          data-testid="box-my-attendances"
        >
          <Heading
            style={{ fontWeight: 400 }}
            size="small"
            as="h4"
            color="primary"
          >
            Meus atendimentos
          </Heading>
          {hasMoreAttendances ? (
            <SupportText
              onClick={() => history.push('/atendimentos')}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: '14px'
              }}
            >
              Ver mais
            </SupportText>
          ) : null}
        </div>
        {isLoading ? (
          <LoadingGif />
        ) : (
          <div className="container-card">
            {CarouselChildren.length === 0 && !isLoading ? (
              <S.EmptyAttendances>
                Você não possui nenhum atendimento no momento, se você já
                cadastrou a sua solicitação aguarde a análise do hospital.
              </S.EmptyAttendances>
            ) : (
              <Carousel
                state={carousel}
                setState={setCarousel}
                slidesPerView={1}
                spaceBetween={50}
                dynamicHeight={true}
                pagination={{ type: 'bullets' }}
                bulletBottomPosition={'0'}
              >
                {[...CarouselChildren.slice(0, 5)]}
              </Carousel>
            )}
          </div>
        )}
        <S.JourneySection>
          <S.CarouselContainer>
            <S.JourneyDiv onClick={() => push('/minha-jornada')}>
              <SurgeryJourneyBanner width={'100%'} height={'100%'} />
            </S.JourneyDiv>
          </S.CarouselContainer>
          <Grid
            container
            style={{
              marginTop: '1rem'
            }}
            spacing={2}
          >
            {getJourneyButtons().map(({ label, icon, path }) => (
              <Grid item xs={6} sm={4} key={label}>
                <S.SurgicalJourneyButton
                  onClick={() =>
                    push(path, {
                      id: surgeries?.[0]?.surgicalId
                    })
                  }
                >
                  <div>{icon}</div>
                  <span>{label}</span>
                </S.SurgicalJourneyButton>
              </Grid>
            ))}
          </Grid>
        </S.JourneySection>
      </Container>
      <BottomMenu
        onAppointment={(label) => {
          label === 'Marcar consulta' && setAppointmentModal(!appointmentModal)
        }}
      />
      <Modal
        show={appointmentModal}
        style={{ paddingTop: 20 }}
        close={() => setAppointmentModal(false)}
        preventAutomateClose
      >
        <S.Title>Quase lá!</S.Title>
        <S.Subtitle>
          Você está sendo direcionado para o nosso atendimento virtual no
          WhatsApp!
        </S.Subtitle>
        <WhatsAppButton
          handleRedirect={() => openPhoneApp('whatsApp', '553133399800')}
        />
      </Modal>
    </S.Wrapper>
  )
}
