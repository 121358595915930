import { PatientDocument } from 'common/enum/patient-document'

export type DocumentsList = {
  type: string[]
  url: string
  name: string
  upload: boolean
}

export const documentList: {
  type: string[]
  url: string
  name: string
  upload: boolean
}[] = [
  {
    name: 'Carteira de identidade',
    type: [PatientDocument.IDENTITY_CARD],
    url: '/upload/documentos/pessoais',
    upload: true
  },
  {
    name: 'Carteira do convênio',
    type: [PatientDocument.HEALTH_CARD],
    url: '/upload/documentos/convenio',
    upload: true
  },
  {
    name: 'Pedido cirúrgico',
    type: [PatientDocument.SURGICAL_ORDER],
    url: '/upload/documentos/medico',
    upload: true
  },
  {
    name: 'Consentimento Cirúrgico',
    type: [PatientDocument.SURGICAL_CONSENT],
    url: 'upload/documentos/pessoais',
    upload: true
  },
  {
    name: 'Consentimento Anestésico',
    type: [PatientDocument.ANESTHETIC_CONSENT],
    url: 'upload/documentos/pessoais',
    upload: true
  },
  {
    name: 'Consulta Pré-Anéstesica',
    type: [PatientDocument.PRE_ANESTHETIC_EVALUATION],
    url: 'upload/documentos/pessoais',
    upload: true
  },
  {
    name: 'Relatório Médico',
    type: [PatientDocument.MEDICAL_REPORT],
    url: '/upload/documentos/relatorio-medico',
    upload: true
  }
]

export const patientDocumentSolicitationList: {
  type: string[]
  url: string
  name: string
  upload: boolean
}[] = [
  {
    name: 'Carteira do convênio',
    type: [PatientDocument.HEALTH_CARD],
    url: '/upload/documentos/convenio',
    upload: true
  },
  {
    name: 'Laudos dos exames',
    type: [PatientDocument.EXAM_REPORT],
    url: '/upload/laudos',
    upload: true
  },
  {
    name: 'Pedido Cirúrgico',
    type: [PatientDocument.SURGICAL_ORDER],
    url: '/upload/documentos/pedido-cirurgico',
    upload: true
  },
  {
    name: 'Relatório Médico',
    type: [PatientDocument.MEDICAL_REPORT],
    url: '/upload/documentos/relatorio-medico',
    upload: true
  }
]

export const patientDocumentList: {
  type: string[]
  url: string
  name: string
  upload: boolean
}[] = [
  {
    name: 'Planejamento cirúrgico',
    type: [PatientDocument.SURGICAL_PLANNING],
    url: '/upload/documentos/planejamento-cirurgico',
    upload: false
  },
  {
    name: 'Carteira de identidade',
    type: [PatientDocument.IDENTITY_CARD],
    url: '/upload/documentos/pessoais',
    upload: true
  },
  {
    name: 'Carteira do convênio',
    type: [PatientDocument.HEALTH_CARD],
    url: '/upload/documentos/convenio',
    upload: true
  },
  {
    name: 'Laudos dos exames',
    type: [PatientDocument.EXAM_REPORT],
    url: '/upload/laudos',
    upload: true
  },
  {
    name: 'Pedido Cirúrgico',
    type: [PatientDocument.SURGICAL_ORDER],
    url: '/upload/documentos/pedido-cirurgico',
    upload: true
  },
  // {
  //   name: 'Documento do exame',
  //   type: [PatientDocument.EXAM_DOCUMENT],
  //   url: '/upload/documentos/documento-exame',
  //   upload: true
  // },
  // {
  //   name: 'Risco cirúrgico',
  //   type: [PatientDocument.SURGICAL_RISK],
  //   url: '/upload/documentos/risco-cirurgico',
  //   upload: true
  // },
  // {
  //   name: 'Laudo de imagem',
  //   type: [PatientDocument.IMAGE_REPORT],
  //   url: '/upload/laudos',
  //   upload: true
  // },
  {
    name: 'Avaliação pré-anéstesica',
    type: [PatientDocument.PRE_ANESTHETIC_EVALUATION],
    url: '/upload/documentos/pre-anestesico',
    upload: true
  },
  {
    name: 'Relatório Médico',
    type: [PatientDocument.MEDICAL_REPORT],
    url: '/upload/documentos/relatorio-medico',
    upload: true
  },
  {
    name: 'Consent. Cirúrgico',
    type: [PatientDocument.SURGICAL_CONSENT],
    url: '/upload/documentos/consent-cirurgico',
    upload: false
  },
  {
    name: 'Consent. Anestésico',
    type: [PatientDocument.ANESTHETIC_CONSENT],
    url: '/upload/documentos/consent-anestesico',
    upload: false
  }
  // {
  //   name: 'Consent. de hemoterapia',
  //   type: [PatientDocument.HEMOTHERAPY_CONSENT],
  //   url: '/upload/documentos/consent-hemoterapia',
  //   upload: false
  // }
]

export const secretaryPendenciesDocumentList: {
  type: string[]
  url: string
  name: string
  upload: boolean
}[] = [
  {
    name: 'Documento Paciente',
    type: [
      PatientDocument.IDENTITY_CARD_FRONT,
      PatientDocument.IDENTITY_CARD_BACK
    ],
    url: '/upload/documentos/pessoais',
    upload: true
  },
  {
    name: 'Carteira do convênio',
    type: [PatientDocument.HEALTH_CARD_FRONT, PatientDocument.HEALTH_CARD_BACK],
    url: '/upload/documentos/convenio',
    upload: true
  },
  {
    name: 'Pedido Médico',
    type: [PatientDocument.MEDICAL_ORDER],
    url: '/upload/documentos/medico',
    upload: true
  }
]
