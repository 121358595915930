import styled, { css } from 'styled-components'
import { RadioProps } from '.'

type WrapperProps = {
  isChecked: boolean
  showBackground: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isChecked, showBackground }) => css`
    display: flex;
    align-items: center;
    ${showBackground
      ? `border: 1px solid
      ${isChecked ? theme.colors.secondary : theme.colors.lightGray};`
      : ''}
    border-radius: ${theme.border.radius.small};
    height: 60px;
    padding: 20px;
    background-color: ${showBackground ? theme.colors.white : ''};
    cursor: pointer;
  `}
`

type InputProps = {
  inputColor: 'secondary' | 'primary'
}

export const Input = styled.input<InputProps>`
  ${({ theme, inputColor }) => {
    const realColor = theme.colors[inputColor]
    return css`
      flex-shrink: 0;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid ${realColor};
      border-radius: 50%;
      background: transparent;
      transition: background ${theme.transition.fast};
      outline: none;
      cursor: pointer;
      &:before {
        content: '';
        width: 18px;
        height: 18px;
        border: 2px solid ${theme.colors.white};
        box-shadow: 0 0 0 2px ${realColor};
        border-radius: 50%;
        background: ${realColor};
        transition: opacity ${theme.transition.fast};
        opacity: 0;
        position: absolute;
      }
      &:checked {
        & + ${Label} {
          color: ${realColor};
        }
        & ~ div {
          border-color: ${realColor};
        }
        &:before {
          opacity: 1;
        }
      }
    `
  }}
`

export const Label = styled.label<Pick<RadioProps, 'checked'>>`
  ${({ theme, checked }) => css`
    display: block;
    color: ${!checked ? theme.colors.gray : theme.colors.primary};
    padding-left: 24px;
    line-height: 1;
    cursor: pointer;
    width: 100%;
    height: 100%;
  `}
`
