import React from 'react'
import makePatientService from 'main/factories/stores/services/patient'
import makeSurgicalOrderService from 'main/factories/stores/services/surgical-order'
import makeDoctorService from 'main/factories/stores/services/doctor'
import makeSpecialtyService from 'main/factories/stores/services/specialty'
import makeCollaboratorService from 'main/factories/stores/services/collaborator'
import makeUserService from 'main/factories/stores/services/user'
import makeHealthInsuranceService from 'main/factories/stores/services/health-insurance'
import makeEligibilityService from 'main/factories/stores/services/eligibility'
import makeAddressService from 'main/factories/stores/services/address'
import makeSecretaryService from 'main/factories/stores/services/secretary'
import makeSurgicalPendencyService from 'main/factories/stores/services/surgical-pendency'
import makeDocumentService from 'main/factories/stores/services/document'
import makeDoctorDocumentValidationService from 'main/factories/stores/services/doctor-document-validation'
import makeHealthInsurancePlansService from 'main/factories/stores/services/health-insurance-plans'
import makeAccompanyingService from 'main/factories/stores/services/accompanying'
import makeReligionService from 'main/factories/stores/services/religion'
import makeAuthorizationService from 'main/factories/stores/services/authorization'

const services = {
  patient: makePatientService(),
  surgicalOrder: makeSurgicalOrderService(),
  surgicalPendency: makeSurgicalPendencyService(),
  doctor: makeDoctorService(),
  specialty: makeSpecialtyService(),
  user: makeUserService(),
  collaborator: makeCollaboratorService(),
  healthInsurance: makeHealthInsuranceService(),
  healthInsurancePlans: makeHealthInsurancePlansService(),
  eligibility: makeEligibilityService(),
  address: makeAddressService(),
  secretary: makeSecretaryService(),
  document: makeDocumentService(),
  doctorDocumentValidation: makeDoctorDocumentValidationService(),
  accompanying: makeAccompanyingService(),
  religion: makeReligionService(),
  authorization: makeAuthorizationService()
}

export const servicesContext = React.createContext(services)

export type ServicesType = Partial<typeof services>
