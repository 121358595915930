import React, { useEffect, useState } from 'react'

import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { PatientPersonalInfoForm } from 'presentation/patient/components/Forms/CarouselPersonalInfo'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import UpdatePersonalInfoLayout from 'presentation/patient/layouts/CreateSurgicalOrder/UpdatePersonalInfo'
import { Patient } from 'domain/entities/patient-model'

type ChangeSurgicalOrderPersonalInfoProps = {
  useCase?: UpdatePatientInfo
  loadPatientInfo: LoadPatientInfo
}

export default function ChangeSurgicalOrderPersonalInfo({
  useCase,
  loadPatientInfo
}: ChangeSurgicalOrderPersonalInfoProps) {
  const [patientInfo, setPatientInfo] = useState({} as LoadPatientInfo.Model)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      const patient = await loadPatientInfo.load([
        'name',
        'birthday',
        'phone',
        'cpf',
        'email',
        'gender'
      ])

      setPatientInfo({ ...patient } as Patient)
      setLoading(false)
    }
    loadData()
  }, [])

  return (
    <>
      {!loading && (
        <UpdatePersonalInfoLayout
          useCase={useCase}
          patientInfo={patientInfo as unknown as PatientPersonalInfoForm}
        />
      )}
    </>
  )
}
