import { GetAgendaAllocation } from 'domain/usecases/agenda/get-agenda-allocation'

import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteGetAgendaAllocation implements GetAgendaAllocation {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  get(params: GetAgendaAllocation.Params): Promise<GetAgendaAllocation.Model> {
    return this.surgicalOrderRepository.getAgendaAllocation(params)
  }
}
