import {
  SurgicalOrderStatus,
  TimelineStatus
} from 'presentation/shared/components/Timeline/status'

export function getStatusInfo(status?: string):
  | {
      label: string
      status: SurgicalOrderStatus[] | string[]
      color: string
    }
  | undefined {
  const tstatus = TimelineStatus.find((timelineStatus) => {
    return timelineStatus.status.find((val) => val === status)
  })
  if (tstatus) return tstatus
  return {
    label: status,
    status: [status],
    color: '#A394FF'
  }
}
