import React, { useEffect, useState } from 'react'

import moment from 'moment-timezone'

import theme from 'presentation/styles/theme'
import * as S from './styles'
import StatusChip from '../StatusChip'
import { useStores } from 'presentation/hooks/use-stores'
import Divider from '../Divider'

export type TimelineStatusItem = {
  status: string
  color?: string
  isConcluded: boolean
  isActive?: boolean
  createdAt?: string
  updatedAt?: string
}

export type TimelineProps = {
  minified?: boolean
  items?: TimelineStatusItem[]
  timelineStatusProp?: {
    label: string
    status: string[]
    color: string
  }[]

  supportButtonLabel?: string
  supportButtonClick?: () => void
}

export default function Timeline({
  minified = false,
  items,
  timelineStatusProp,
  supportButtonLabel,
  supportButtonClick
}: TimelineProps) {
  const stores = useStores()
  const integrationStatus = stores.surgicalstatus

  const [surgicalStatus, setSurgicalStatus] = useState([])

  function getDotColor(status) {
    if (status?.isConcluded) {
      return theme.colors.primary
    }
  }

  useEffect(() => {
    const integratedStatus = integrationStatus.getStatus()

    const allStatus = [
      {
        internalStatus: 'Revisão'
      },
      ...integratedStatus
    ]

    const status = allStatus.map((status) => {
      const foundStatus = items.find(
        (stat) => stat.status === status.internalStatus
      )
      if (foundStatus) {
        return foundStatus
      }
      return {
        status: status.internalStatus,
        isConcluded: false,
        isActive: false
      }
    })

    setSurgicalStatus(status)
  }, [items])

  return (
    <S.Wrapper minified={minified}>
      {surgicalStatus.length &&
        surgicalStatus?.map((status, index) => (
          <>
            <S.TimelineItem
              done={status?.isConcluded}
              minified={minified}
              actual={status?.isActive}
              key={index}
            >
              {minified && index !== 0 && <S.Bar done={status.isConcluded} />}
              <S.Dot
                done={status?.isConcluded || status?.isActive}
                minified={minified}
                color={getDotColor(status)}
              />
              {!minified && (
                <S.ItemContainer>
                  <S.DescriptionContainer>
                    <S.StatusLabel>
                      {status?.status}{' '}
                      {status?.isActive && (
                        <S.ActualStatusTag>Atual</S.ActualStatusTag>
                      )}
                    </S.StatusLabel>
                    {supportButtonLabel ? (
                      status?.isActive ? (
                        <StatusChip
                          color={'pink'}
                          label={supportButtonLabel}
                          onClick={(e) => {
                            if (supportButtonClick) {
                              supportButtonClick()
                            }
                            e.stopPropagation()
                          }}
                        />
                      ) : null
                    ) : null}
                    {status?.isConcluded && (
                      <S.DoneDate>
                        {status.updatedAt &&
                          moment(status.updatedAt).format('DD/MM/YYYY - H:mm')}
                      </S.DoneDate>
                    )}
                  </S.DescriptionContainer>
                </S.ItemContainer>
              )}
            </S.TimelineItem>
            <Divider />
          </>
        ))}
    </S.Wrapper>
  )
}
