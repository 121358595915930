import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import LoadingGif from 'presentation/assets/icons/loading.gif'
import Heading from 'presentation/shared/components/Heading'
import {
  pendencyTranslator,
  PendencyType,
  SurgicalPendency,
  SurgicalPendencyStatus
} from 'domain/entities/surgical-pendency'
import StatusChip from 'presentation/shared/components/StatusChip'
import { Container } from 'presentation/shared/components/Container'
import { toUtc } from 'common/utils/toUtc'
import Button from 'presentation/shared/components/Button'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'
import Tabs from 'presentation/shared/components/Tabs'
import ActualPage from 'presentation/shared/components/ActualPage'
import Header from 'presentation/shared/components/Header'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'

const PAGE_SIZE = 5

type LoadPendenciesProps = {
  pageNumber: number
  patientName?: string
  status?: SurgicalPendencyStatus
  accumulate?: boolean
  pendencyType?: PendencyType
}

type PendencyTab = {
  label: string
  componentChildren: JSX.Element
  type: PendencyType
}

type totalPages = {
  type: PendencyType | undefined
  total: number | undefined
}

export const SurgicalPendencyList = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const [loadingMore, setLoadingMore] = useState<boolean>(false)
    const [pendenciesData, setPendenciesData] = useState<SurgicalPendency[]>([])
    const [pendenciesTabPrevious, setPendenciesTabPrevious] = useState<
      {
        data: SurgicalPendency[]
        type: PendencyType
      }[]
    >([])
    const [pendendenciesRenderItems, setPendenciesRenderItems] =
      useState<JSX.Element>(<></>)
    const [actualPendencyType, setActualPendencyType] = useState(
      PendencyType.AUTHORIZATION
    )
    const [actualPage, setActualPage] = useState<number>()
    const [pageSize, setPageSize] = useState<number>()
    const [assistanceTotalPage, setAssistanceTotalPage] = useState(
      {} as totalPages
    )
    const [authorizationTotalPage, setAuthorizationTotalPage] = useState(
      {} as totalPages
    )
    const [authorizationActualPage, setAuthorizationActualPage] =
      useState<number>()
    const [assistanceActualPage, setAssistanceActualPage] = useState<number>()

    const history = useHistory()

    const service = useServices().doctor
    const patientService = useServices().patient

    const user = useStores().currentAccount.getCurrentAccount().user

    const pendencyTabItems: PendencyTab[] = [
      {
        label: 'Autorização',
        componentChildren: pendendenciesRenderItems,
        type: PendencyType.AUTHORIZATION
      },
      {
        label: 'Assistencial',
        componentChildren: pendendenciesRenderItems,
        type: PendencyType.ASSISTANCE
      }
    ]

    const loadPendencies = async (params: LoadPendenciesProps) => {
      try {
        setIsLoading(true)
        const findParams = {
          params: [
            'data {surgical_pendency_id, surgical_order_id, answer, answeredAt, requestedBy {name}, createdAt,' +
              'documents {document_id, type, name},' +
              'status, observation, surgicalOrder {patientName, procedure{description}, createdBy {name}}, type}',
            'pageInfo {' +
              'currentPage, ' +
              'itemsPerPage, ' +
              'totalItems, ' +
              'totalPages' +
              '}'
          ],
          pagination: {
            pageNumber: params.pageNumber,
            pageSize: PAGE_SIZE
          },
          query: {
            name: params.patientName,
            status: params.status,
            pendencyType: params.pendencyType
          }
        }
        setLoadingMore(true)
        const response = await (user.role === Profile.DOCTOR
          ? service.loadDoctorPendencies(findParams)
          : patientService.loadPatientPendencies(findParams))

        const data = response?.pendencies?.data || []
        const newPendencies = params?.accumulate
          ? [...pendenciesData, ...data]
          : data
        setPendenciesData(newPendencies)
        const pageInfo = response?.pendencies?.pageInfo
        setPageSize(pageInfo?.totalPages)
        const type = data[0]?.type
        type === 'ASSISTANCE'
          ? setAssistanceTotalPage({
              type,
              total: pageInfo?.totalPages
            })
          : setAuthorizationTotalPage({
              type,
              total: pageInfo?.totalPages
            })
        type === 'AUTHORIZATION'
          ? setAuthorizationActualPage(pageInfo?.currentPage)
          : setAssistanceActualPage(pageInfo?.currentPage)
        setActualPage(pageInfo?.currentPage)
      } catch (error: any) {
        toast.error(error?.message)
      } finally {
        setLoadingMore(false)
        setIsLoading(false)
      }
    }

    const getBottomButton = () => {
      if (loadingMore) {
        return (
          <S.LoadingMore>
            <img src={LoadingGif} />
          </S.LoadingMore>
        )
      }

      if (pageSize && actualPage) {
        if (
          (actualPendencyType == authorizationTotalPage.type &&
            authorizationTotalPage.total! > authorizationActualPage!) ||
          (actualPendencyType == assistanceTotalPage.type &&
            assistanceTotalPage.total! > assistanceActualPage!)
        ) {
          return (
            <Button
              fullWidth
              type="button"
              variant="outlined"
              onClick={() => {
                loadPendencies({
                  pageNumber: actualPage + 1,
                  accumulate: true,
                  pendencyType: actualPendencyType
                })
              }}
            >
              Ver mais resultados
            </Button>
          )
        }
      }
      if (pendenciesData?.length === 0) {
        return (
          <S.NoPendenciesLabel>
            Sua pesquisa não encontrou nenhum resultado correspondente
          </S.NoPendenciesLabel>
        )
      }
      return <></>
    }

    const getPreviousTabsMemoized = (newPendencyTab: PendencyTab) => {
      return pendenciesTabPrevious.find(
        (pendencyTabPrevious) =>
          pendencyTabPrevious.type === newPendencyTab.type
      )
    }

    const memoizeActualTab = () => {
      setPendenciesTabPrevious([
        ...pendenciesTabPrevious,
        {
          data: pendenciesData,
          type: actualPendencyType
        }
      ])
    }

    const handleGetPendenciesOnChangeTab = async (newTabIndex: number) => {
      try {
        const newPendencyTab = pendencyTabItems.find(
          (pendency, idx) => pendency && idx === newTabIndex
        )
        if (newPendencyTab) {
          const previousTab = getPreviousTabsMemoized(newPendencyTab)
          memoizeActualTab()
          if (previousTab) {
            setPendenciesData(previousTab.data)
          } else {
            await loadPendencies({
              pageNumber: 1,
              pendencyType: newPendencyTab.type
            })
          }
          setActualPendencyType(newPendencyTab.type)
        }
      } catch (err) {
        toast.error('Falha ao exibir pendências')
      }
    }

    const getPendencyRenderItems = () => {
      if (pendenciesData.length === 0) {
        return <></>
      }
      return (
        <S.Content>
          {pendenciesData.map((pendency) => (
            <S.PendencyWrapper
              key={pendency.surgical_pendency_id}
              onClick={() => history.push('/pendencia', { pendency })}
              data-testid="pendency-card"
            >
              <header>
                <Heading as="h4">{pendency.surgicalOrder?.patientName}</Heading>
                <StatusChip
                  color={
                    pendency.status === SurgicalPendencyStatus.RESOLVED
                      ? 'primary'
                      : 'red'
                  }
                  label={pendencyTranslator[pendency.status]}
                  fontSize="xxxsmall"
                />
              </header>
              <div>
                <div>
                  <h6>Responsável:</h6>
                  <span>{pendency.surgicalOrder?.createdBy?.name}</span>
                </div>
                <div>
                  <h6>Pendência gerada em:</h6>
                  <span>
                    {new Intl.DateTimeFormat('pt-BR', {
                      timeStyle: 'short',
                      dateStyle: 'short'
                    }).format(toUtc(new Date(pendency.createdAt)))}
                  </span>
                </div>
                <div>
                  <h6>Responsável CRMO:</h6>
                  <span>{pendency.requestedBy?.name}</span>
                </div>
                <div>
                  <h6>Número do aviso:</h6>
                  <span>{pendency.surgical_order_id}</span>
                </div>
              </div>
            </S.PendencyWrapper>
          ))}
        </S.Content>
      )
    }

    const refreshPendenciesRenderOnFetch = () => {
      setPendenciesRenderItems(getPendencyRenderItems)
    }

    const fetchPendenciesOnLoad = async () => {
      await loadPendencies({
        pageNumber: 1,
        pendencyType: PendencyType.AUTHORIZATION
      })
    }

    useEffect(() => {
      fetchPendenciesOnLoad()
    }, [])

    useEffect(() => {
      refreshPendenciesRenderOnFetch()
    }, [pendenciesData])

    return (
      <>
        <Header />
        <S.Wrapper>
          <S.Header>
            <ActualPage
              data-testid="btn-go-back"
              text="Voltar"
              onClick={() => history.push('/home')}
            />
          </S.Header>
          <Container title="Pendências" noPadding>
            <S.PendenciesTabContainer data-testid="pendency-tabs-container">
              <Tabs
                tabItems={pendencyTabItems}
                styleOptions={{
                  primary: true
                }}
                onChangeTab={(tabIndex) =>
                  handleGetPendenciesOnChangeTab(tabIndex)
                }
              />
            </S.PendenciesTabContainer>
            <S.LoadMoreButton data-testid="btn-load-more">
              {getBottomButton()}
            </S.LoadMoreButton>
          </Container>
        </S.Wrapper>
      </>
    )
  }
)
