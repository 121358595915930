export default {
  border: {
    radius: {
      xsmall: '4px',
      small: '10px',
      medium: '15px',
      large: '20px',
      xlarge: '34px',
      rounded: '50%'
    }
  },
  font: {
    family:
      "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    black: 700,
    sizes: {
      xxxsmall: '0.625em',
      xxsmall: '0.75em',
      xsmall: '0.875em',
      small: '1em',
      medium: '1.125em',
      large: '1.5em',
      xlarge: '2em',
      xxlarge: '3em',
      xxxlarge: '3.5em'
    }
  },
  colors: {
    primary: '#6024B8',
    primaryDarker: '#2e1057',
    secondary: '#834fc9',
    lightSecondary: '#995beb',
    lightestPink: '#EDF1ED',
    alert: '#F48989',
    mainBg: '#F8F4FF',
    white: '#FFFFFF',
    success: '#1BD15D',
    neutralWhite: '#FBFBFB',
    black: '#000000',
    mediumLightGray: '#6D6D6D',
    gray: '#4A4A4A',
    lightGreen: '#81BB81',
    lightGray: '#9B9B9B',
    lightestGray: '#E5E5E5',
    green: '#125F1C',
    darkGreen: '#03601C',
    moreLightGreen: '#E3EEE2',
    lightestGreen: '#A5D8AC',
    blueishGreen: '#00A499',
    blue: '#3BA3F4',
    bluishGreen: '#06D7CB',
    darkBlue: '#3F3F9E',
    pink: '#F27C9B',
    lightPink: '#FEEFEF',
    red: '#E31A0B',
    lightRed: '#FA7676',
    moreLightRed: '#FFDADA',
    lightOrange: '#F5AA77',
    salmon: '#FF6341',
    orange: '#F1904E',
    lightPurple: '#A394FF',
    purple: '#BA509D',
    darkPurple: '#6A2297',
    greyishBlue: '#3F6F9E',
    golden: '#D1B41B',
    yellow: '#F6BC26',
    violet: '#9E3F9E',
    brightOrange: '#FF8F39',
    mediumGray: '#888888',
    darkGray: '#414141',
    rootBg: '#D8D0E4BD'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 150
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
} as const
