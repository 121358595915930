import { MedicalTeamTypeEnum } from 'common/enum/medical-team-professional'

export const medicalTeamType = [
  {
    label: '1º Cirurgião',
    value: MedicalTeamTypeEnum.FIRST_SURGEON
  },
  {
    label: '2º Cirurgião',
    value: MedicalTeamTypeEnum.SECOND_SURGEON
  },
  {
    label: 'Assistente',
    value: MedicalTeamTypeEnum.ASSISTANT
  },
  {
    label: 'Instrumentador',
    value: MedicalTeamTypeEnum.INSTRUMENTALIST
  }
]

export const medicalTeamTypeDictionary = {
  [MedicalTeamTypeEnum.FIRST_SURGEON]: '1º Cirurgião',
  [MedicalTeamTypeEnum.SECOND_SURGEON]: '2º Cirurgião',
  [MedicalTeamTypeEnum.ASSISTANT]: 'Assistente',
  [MedicalTeamTypeEnum.INSTRUMENTALIST]: 'Instrumentador'
}
