import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
`
export const Image = styled.img`
  width: 100%;
  margin: 16px auto 0;
  display: block;
  max-width: 420px;
  border-radius: 0.5rem;
`

export const List = styled.ul`
  li {
    margin-top: 10px;
    list-style: none;

    &::before {
      content: '- ';
    }
  }
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-top: 40px;
  align-items: center;
  text-align: center;

  h1 {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
