import React from 'react'
import * as S from './styles'

export default function PostSurgeryInfo() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.PreSurgery>
          <S.Title>Orientações Pós-Cirúrgicas</S.Title>

          <S.Description>
            Para garantir uma recuperação rápida e eficaz, siga atentamente as
            orientações abaixo:
            <br />
            <br />
            <strong>- Repouso Adequado:</strong>
            <br />
            Descanse bastante durante as primeiras 24 horas após a cirurgia.
            Evite esforços físicos intensos e mantenha-se relaxado para
            facilitar a cicatrização.
            <br />
            <br />
            <strong>- Medicação:</strong>
            <br />
            Tome os medicamentos prescritos conforme as orientações do médico.
            Não hesite em relatar qualquer efeito colateral ou desconforto ao
            profissional de saúde.
            <br />
            <br />
            <strong>- Cuidados com a Ferida:</strong>
            <br />
            Mantenha o curativo limpo e seco. Siga as instruções sobre a troca
            de curativos e a aplicação de pomadas, se necessário. Evite molhar a
            área da cirurgia durante o período recomendado.
            <br />
            <br />
            <strong>- Alimentação Saudável:</strong>
            <br />
            Mantenha uma dieta equilibrada, rica em nutrientes e vitaminas.
            Evite alimentos que possam causar desconforto ou interferir na
            recuperação.
            <br />
            <br />
            <strong>- Higiene Pessoal:</strong>
            <br />
            Mantenha uma boa higiene, tomando banhos conforme orientações
            médicas. Evite exposição desnecessária a substâncias irritantes.
            <br />
            <br />
            <strong>- Atividade Física Gradual:</strong>
            <br />
            Inicie atividades leves conforme orientações do profissional de
            saúde. Evite exercícios extenuantes até obter a aprovação do seu
            médico.
            <br />
            <br />
            <strong>- Acompanhamento Médico:</strong>
            <br />
            Siga o cronograma de consultas pós-cirúrgicas. Relate qualquer
            preocupação ou sintoma novo ao seu médico imediatamente.
            <br />
            <br />
            <strong>- Sinais de Complicações:</strong>
            <br />
            Esteja atento a sinais de infecção, como vermelhidão, inchaço
            excessivo, odor desagradável ou secreção. Informe imediatamente o
            médico sobre qualquer sinal de complicações.
            <br />
            <br />
            <strong>- Suporte Emocional:</strong>
            <br />
            Compartilhe suas preocupações com amigos, familiares ou
            profissionais de saúde. Esteja ciente de alterações emocionais
            normais após a cirurgia e procure apoio, se necessário.
            <br />
            <br />
            <strong>- Restrições Específicas:</strong>
            <br />
            Siga qualquer instrução específica dada pelo seu cirurgião em
            relação a atividades, dieta e estilo de vida.
            <br />
            <br />
            <br />
            Lembre-se, a recuperação é um processo gradual. Siga estas
            orientações e esteja em contato constante com sua equipe médica para
            garantir uma recuperação tranquila e bem-sucedida. Desejamos a você
            uma recuperação rápida e completa.
          </S.Description>
        </S.PreSurgery>
      </S.Container>
    </S.Wrapper>
  )
}
