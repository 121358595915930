import { User } from './user-model'

type SpecialityHospital = {
  name?: string
}

export class Hospital {
  private _hospital_id: number
  private _nickname: string
  private _name: string
  private _user?: User
  private _specialityHospital?: SpecialityHospital[]
  private _hasPartnerCode?: boolean

  constructor(
    hospital_id: number,
    nickname: string,
    name: string,
    specialityHospital?: SpecialityHospital[],
    hasPartnerCode?: boolean
  ) {
    this._hospital_id = hospital_id
    this._nickname = nickname
    this._name = name
    this._specialityHospital = specialityHospital
    this._hasPartnerCode = hasPartnerCode
  }

  public get hospital_id(): number {
    return this._hospital_id
  }

  public set hospital_id(hospital_id: number) {
    this._hospital_id = hospital_id
  }

  public get nickname(): string {
    return this._nickname
  }

  public set nickname(nickname: string) {
    this._nickname = nickname
  }

  public get name(): string {
    return this._name
  }

  public set name(name: string) {
    this._name = name
  }

  public get user(): User | undefined {
    return this._user
  }

  public set user(user: User | undefined) {
    this._user = user
  }

  public get specialityHospital(): SpecialityHospital[] | undefined {
    return this._specialityHospital
  }

  public set specialityHospital(
    specialityHospital: SpecialityHospital[] | undefined
  ) {
    this._specialityHospital = specialityHospital
  }

  public get hasPartnerCode(): boolean | undefined {
    return this._hasPartnerCode
  }

  public set hasPartnerCode(hasPartnerCode: boolean | undefined) {
    this._hasPartnerCode = hasPartnerCode
  }

  toJSON() {
    return {
      hospital_id: this._hospital_id,
      nickname: this._nickname,
      name: this._name,
      user: this._user,
      specialityHospital: this._specialityHospital,
      hasPartnerCode: this._hasPartnerCode
    }
  }
}
