import Heading from 'presentation/shared/components/Heading'
import React from 'react'
import PatientRecoveryImage from '../../../assets/patientRecovering.png'
import * as S from '../styled'
import { SurgeryJourney } from '../index'
import theme from 'presentation/styles/theme'

export function HospitalRecovery({ goBack, goNext }: SurgeryJourney) {
  return (
    <S.Wrapper>
      <S.Header>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goBack}
        >
          <path
            d="M8 2L3 9L8 16"
            stroke={theme.colors.primary}
            strokeOpacity="0.5"
            strokeWidth="4"
          />
        </svg>
        <Heading as="h1" size="large">
          Recuperação no Hospital
        </Heading>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goNext}
        >
          <path
            d="M2 16L7 9L2 2"
            stroke={theme.colors.primary}
            strokeWidth="4"
          />
        </svg>
      </S.Header>

      <p>
        A recuperação no hospital segue o pós-operatório imediato e envolve
        monitoramento contínuo, administração de medicamentos e, se necessário,
        início da fisioterapia para promover a mobilidade.
      </p>
      <br />
      <p>
        Durante esta fase, o paciente é avaliado regularmente pela equipe
        médica, permitindo ajustes no plano de cuidados conforme o progresso. As
        visitas do cirurgião ou outros profissionais de saúde são frequentes
        para garantir uma transição segura para a próxima etapa de recuperação
        em casa.
      </p>
      <br />

      <S.Image src={PatientRecoveryImage} alt="Médico fazendo anotações" />
    </S.Wrapper>
  )
}
