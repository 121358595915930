import React from 'react'
import * as S from './styles'

import Heading from '../Heading'
import SupportText from '../SupportText'
import List from '../List'
import { MedicalTeam } from 'domain/entities/surgery-model'
import { ReactComponent as PreSurgeryIcon } from 'presentation/assets/icons/pre-surgery.svg'
import { ReactComponent as AddIcon } from 'presentation/assets/icons/plus.svg'
import { useHistory } from 'react-router-dom'
import { useStores } from 'presentation/hooks/use-stores'
import { Accompanying } from 'domain/entities/accompanying-model'
import Error from 'presentation/shared/components/Error'
import { getRandomProfileImageUrl } from 'presentation/utils/get-random-profile-image'
import { Profile } from 'common/enum/profile'
import Button from '../Button'

type SurgeryInfoProps = {
  medicalTeam?: MedicalTeam[]
  surgery_id?: number
  mainAccompanying?: Accompanying
  hospital?: string
}

export default function SurgeryInfo({
  medicalTeam,
  surgery_id,
  mainAccompanying,
  hospital
}: SurgeryInfoProps) {
  const history = useHistory()
  const currentSurgery = useStores().currentSurgery
  const user = useStores().currentAccount.getCurrentAccount().user

  const handleNavigateSurgeryToSurgeryInfoTabs = () => {
    if (surgery_id) {
      currentSurgery.setSurgeryIdSelected(surgery_id)
      history.push('/orientacoes/cirurgicas/pre-cirurgica')
    }
  }

  const handleNavigateToMyHospitalization = () => {
    if (surgery_id) {
      currentSurgery.setSurgeryIdSelected(surgery_id)
      history.push('/internacao', {
        hospital
      })
    }
  }
  return (
    <S.Wrapper>
      <Heading>Resumo do pedido</Heading>
      <Button
        fullWidth
        type="button"
        onClick={() =>
          history.push('/pedido-cirurgico/resumo', {
            surgicalOrder: {
              surgical_order_id: surgery_id
            },
            visualizationMode: true
          })
        }
      >
        Visualizar resumo do pedido
      </Button>
      <Heading>Acompanhantes</Heading>
      <SupportText color="lightGray">
        {user.role === Profile.PATIENT
          ? 'Cadastre as pessoas que irão acompanhá-lo ao longo de toda a jornada cirúrgica'
          : 'Acompanhantes cadastrados para acompanhar a jornada cirúrgica'}
      </SupportText>
      {mainAccompanying?.name ? (
        <List
          size="small"
          arrow={true}
          padding={false}
          items={[
            {
              icon: getRandomProfileImageUrl(),
              title: <S.PersonRole>Acompanhante</S.PersonRole>,
              description: (
                <S.PersonName>{mainAccompanying?.name}</S.PersonName>
              ),
              click: () => history.push('/acompanhantes')
            }
          ]}
        />
      ) : (
        <Error
          color={'red'}
          size={'xsmall'}
          style={{
            padding: 0
          }}
        >
          Nenhum acompanhante selecionado
        </Error>
      )}
      {user.role === Profile.PATIENT && (
        <S.AddCompanion onClick={() => history.push('/acompanhantes')}>
          <AddIcon />
          <label>Adicionar um acompanhante</label>
        </S.AddCompanion>
      )}
      {/* <Heading>Equipe médica</Heading> */}
      {/* <SupportText color="lightGray">
        Conheça a equipe médica responsavel por sua cirurgia
      </SupportText>
      <List
        size="small"
        arrow={true}
        padding={false}
        items={
          medicalTeam?.map((doctor) => ({
            title: <S.PersonRole>{doctor.function}</S.PersonRole>,
            icon: 'https://source.unsplash.com/random',
            description: <S.PersonName>{doctor.name}</S.PersonName>
          })) ?? []
        }
      /> */}
      {user.role === Profile.PATIENT && (
        <>
          <Heading style={{ marginTop: '40px' }}>Orientações</Heading>
          <S.Guidance>
            <S.SurgeryInfo
              onClick={() => handleNavigateSurgeryToSurgeryInfoTabs()}
            >
              <PreSurgeryIcon />
              <span>Orientações cirúrgicas</span>
            </S.SurgeryInfo>
            <S.MyHospitalization
              onClick={() => handleNavigateToMyHospitalization()}
            />
          </S.Guidance>
        </>
      )}
    </S.Wrapper>
  )
}
