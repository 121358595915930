import React from 'react'

import { useHistory } from 'react-router-dom'
import Heading from 'presentation/shared/components/Heading'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

import * as S from '../styles'

type CmeReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function CmeReview({
  surgicalOrder,
  visualizationMode
}: CmeReviewProps) {
  const history = useHistory()

  const cmes = surgicalOrder.cme || []

  return (
    <>
      <S.ProcedureContainer>
        <header>
          <Heading style={{ margin: '32px 0 24px' }} as="h2" weight={500}>
            CME
          </Heading>
          {!visualizationMode && (
            <small
              onClick={() =>
                history.push('/pedido-cirurgico/cme', {
                  surgical_order_id: surgicalOrder.surgical_order_id,
                  cmes
                })
              }
            >
              Editar
            </small>
          )}
        </header>
        {!cmes?.length && <span>Procedimento não possui CME.</span>}
        {cmes?.length
          ? cmes?.map((cme, idx) => (
              <div key={cme.description}>
                {<h6>CME {idx + 1}</h6>}
                <br />
                <h6>Descrição: </h6>
                <span>{cme.description}</span>
                <br />
                <h6>Quantidade: </h6>
                <span>{cme.quantity}</span>
                <br />
              </div>
            ))
          : null}
      </S.ProcedureContainer>
    </>
  )
}
