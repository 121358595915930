import { PatientDocument } from 'common/enum/patient-document'
import 'main/config/yup'
import { observer } from 'mobx-react'
import File from 'presentation/contexts/file/file-context'
import { useStores } from 'presentation/hooks/use-stores'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import ActualPage from 'presentation/shared/components/ActualPage'
import Button from 'presentation/shared/components/Button'
import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as S from './styles'

type Location = {
  type: 'order' | 'medical_report' | 'medical_exams'
}

export const AddSurgicalOrderForm = observer(() => {
  const { state } = useLocation<Location>()
  const type = state?.type
  const history = useHistory()
  const filesContext = useStores().file as File<PatientDocument>
  const getPatientDocumentByType = () => {
    return documentItems[type]?.documentType
  }

  const getInitialFiles = () => {
    return filesContext.listFiles?.filter(
      (listFile) => listFile.identifier === getPatientDocumentByType()
    )
  }

  useEffect(() => {
    if (filesContext.listFiles && !files) {
      onAdd(getInitialFiles() || [])
    }
  }, [filesContext.listFiles])

  const { filesInfo, onAdd, onDelete, onUpdate, onDownload, files } =
    useUploadSection<PatientDocument>({
      maxFileSizeInMB: documentItems[type]?.maxFileSizeInMB,
      maxFiles: documentItems[type]?.maxFiles,
      verifyUniqueFile: true,
      initialFiles: getInitialFiles(),
      extensionsAllowed: ['pdf', 'jpg', 'jpeg']
    })

  const handleSubmit = async () => {
    const filteredFiles =
      filesContext.listFiles?.filter(
        (listFile) => listFile.identifier !== getPatientDocumentByType()
      ) || []
    filesContext.setListFiles([...filteredFiles, ...files])
    toast.success('Documentos Adicionados com sucesso')
    history.goBack()
  }

  const handleAdd = (files: ListFile[]) => {
    onAdd(
      files.map((file) => ({
        ...file,
        identifier: getPatientDocumentByType()
      }))
    )
  }

  const titleProps = {
    title: documentItems[type]?.title,
    titleButtonProps: {
      showTitleButton: false
    },
    subtitle: 'Faça aqui o upload do seu documento'
  }
  const canUpload = {
    canAdd: filesInfo.canAdd,
    canDelete: true,
    canDownload: true,
    canUpdate: true,
    canPreview: false
  }

  const isRequired = documentItems[type]?.required

  return (
    <>
      <Header />
      <Container
        hasHeader
        primaryButton={
          <Button
            disabled={isRequired && files.length < 1}
            fullWidth
            type="submit"
            onClick={() => handleSubmit()}
            data-testid="btn-send"
            style={{ marginBottom: '15px' }}
          >
            Enviar
          </Button>
        }
        secondaryButton={
          <Button
            fullWidth
            variant="outlined"
            data-testid="btn-cancel"
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
        }
      >
        <S.Wrapper>
          <ActualPage
            data-testid="btn-go-back"
            onClick={() => history.goBack()}
            text="Voltar"
          />
          <UploadSection
            titleProps={titleProps}
            files={files}
            can={canUpload}
            onAdd={handleAdd}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onDownload={onDownload}
            errors={filesInfo.errorMessage}
            acceptFiles={'application/pdf, image/jpeg'}
            showTitle
          />
        </S.Wrapper>
      </Container>
    </>
  )
})

const documentItems = {
  order: {
    title: 'Pedido Cirúrgico',
    documentType: PatientDocument.SURGICAL_ORDER,
    maxFileSizeInMB: 5,
    maxFiles: 5,
    required: true
  },
  medical_report: {
    title: 'Relatório Médico',
    documentType: PatientDocument.MEDICAL_REPORT,
    maxFileSizeInMB: 10,
    maxFiles: 10,
    required: false
  },
  medical_exams: {
    title: 'Laudos',
    documentType: PatientDocument.EXAM_REPORT,
    maxFileSizeInMB: 19,
    maxFiles: 60,
    required: false
  }
}

export default AddSurgicalOrderForm
