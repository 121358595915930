import React from 'react'

import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import Heading from 'presentation/shared/components/Heading'

import * as S from '../styles'
import { useHistory } from 'react-router-dom'

type SurgicalInfoReviewProps = {
  surgicalOrder: SurgicalOrderModel
  visualizationMode?: boolean
}

export function SurgicalInfoReview({
  surgicalOrder,
  visualizationMode
}: SurgicalInfoReviewProps) {
  const history = useHistory()

  return (
    <>
      <S.ReviewHeader>
        <Heading weight={500}>Dados cirúrgicos</Heading>
        {!visualizationMode && (
          <small
            onClick={() =>
              history.push('/pedido-cirurgico/dados-cirurgicos', {
                surgical_order_id: surgicalOrder.surgical_order_id,
                surgicalOrder
              })
            }
          >
            Editar
          </small>
        )}
      </S.ReviewHeader>
      <S.InfoColumnContainer>
        <h6>Cintilografia prévia</h6>
        <span>{surgicalOrder.preliminaryScintigraphyTime ? 'Sim' : 'Não'}</span>
        <h6>Hora da cintilografia</h6>
        <span>{surgicalOrder.preliminaryScintigraphyTime || '-'}</span>
        <h6>Lateralidade</h6>
        <span>{getLaterality(surgicalOrder.laterality)}</span>
        <h6>Raio-X da peça</h6>
        <span>{surgicalOrder.xrayOfPieceTime ? 'Sim' : 'Não'}</span>
        <h6>Hora do raio-x</h6>
        <span>{surgicalOrder.xrayOfPieceTime || '-'}</span>
        <h6>Alergias</h6>
        <span>{surgicalOrder.allergy || 'Não informado'}</span>
      </S.InfoColumnContainer>
    </>
  )
}

export const getLaterality = (val?: string) => {
  switch (val) {
    case 'LEFT':
      return 'Esquerdo'
    case 'RIGHT':
      return 'Direito'
    case 'BILATERAL':
      return 'Bilateral'
    case 'NOT_APPLICABLE':
      return 'Não se aplica'
    default:
      return '-'
  }
}
