import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 20px;

  h2 {
    margin: 12px 0;
  }

  h6 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.font.sizes.small};
    display: inline-block;
    font-weight: normal;
  }

  span {
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.font.sizes.small};
    display: inline-block;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  span {
    margin-left: 8px;
  }
`

export const InfoColumnContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;

  h6 {
    margin-top: 8px;
  }

  span {
    text-align: left !important;
    margin-top: 4px;
  }
`

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-bottom: 8px;
    margin-right: 8px;
  }
`

export const InsuranceCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2%;
  & div:last-child {
    margin-top: 0;
  }
  span {
    display: flex;
  }
`

export const ReviewHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 32px;

  small {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
    cursor: pointer;
  }
`

export const ProcedureContainer = styled.div`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 14px;
      cursor: pointer;
    }
  }

  div + div {
    margin-top: 20px;
  }

  div {
    background: white;
    border-radius: ${({ theme }) => theme.border.radius.small};
    padding: 12px 18px;

    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);

    p {
      color: ${({ theme }) => theme.colors.lightGray};
      font-weight: ${({ theme }) => theme.font.semiBold};
      font-size: ${({ theme }) => theme.font.sizes.small};
    }

    h6,
    span {
      font-size: 14px !important;
      display: inline;
      margin: 10 0 0;
    }
  }
`
