import React from 'react'

import { Container } from 'presentation/shared/components/Container'
import Header from 'presentation/shared/components/Header'
import UpdatePersonalInfoForm from 'presentation/patient/components/Forms/UpdatePersonalInfo'
import { Patient } from 'domain/entities/patient-model'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'

type Props = {
  updatePersonalInfo?: UpdatePatientInfo
  patient: Patient
}

export default function UpdatePersonalInfoLayout({
  patient,
  updatePersonalInfo
}: Props) {
  if (!patient.name) return <></>

  return (
    <>
      <Header />
      <Container hasHeader>
        <UpdatePersonalInfoForm
          patient={patient}
          updatePatientInfo={updatePersonalInfo}
        />
      </Container>
    </>
  )
}
