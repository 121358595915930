import Heading from 'presentation/shared/components/Heading'
import React from 'react'
import DischargeImage from '../../../assets/discharge.png'
import * as S from '../styled'
import { SurgeryJourney } from '../index'
import theme from 'presentation/styles/theme'

export function ImmediatePostoperative({ goBack, goNext }: SurgeryJourney) {
  return (
    <S.Wrapper>
      <S.Header>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goBack}
        >
          <path
            d="M8 2L3 9L8 16"
            stroke={theme.colors.primary}
            strokeOpacity="0.5"
            strokeWidth="4"
          />
        </svg>
        <Heading as="h1" size="large">
          Pós-operatório Imediato
        </Heading>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={goNext}
        >
          <path
            d="M2 16L7 9L2 2"
            stroke={theme.colors.primary}
            strokeWidth="4"
          />
        </svg>
      </S.Header>

      <p>
        O pós-operatório imediato compreende o período logo após a cirurgia,
        concentrando-se na recuperação do paciente na sala de recuperação ou
        área designada. Durante essa fase, a equipe médica monitora atentamente
        os sinais vitais do paciente, como frequência cardíaca, pressão arterial
        e respiração, garantindo a estabilidade física após a intervenção
        cirúrgica. Simultaneamente são implementadas estratégias para controlar
        a dor pós-operatória, ajustando medicamentos conforme necessário.
      </p>
      <br />
      <p>
        Informações sobre o procedimento realizado e orientações iniciais sobre
        os cuidados pós-cirúrgicos são fornecidas ao paciente, estabelecendo as
        bases para a continuação do processo de recuperação. Este período
        imediato é essencial para garantir uma transição suave do ambiente
        cirúrgico para a fase inicial de recuperação, otimizando a segurança e o
        bem-estar do paciente.
      </p>
      <br />

      <S.Image src={DischargeImage} alt="Médico fazendo anotações" />
    </S.Wrapper>
  )
}
