import React from 'react'

import { useHistory } from 'react-router-dom'
import Header from 'presentation/shared/components/Header'
import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import List, { ListItem } from 'presentation/shared/components/List'
import Button from 'presentation/shared/components/Button'
import ActualPage from 'presentation/shared/components/ActualPage'

import * as S from './styles'

type SaveSurgicalOrderLayoutProps = {
  handleSubmit: (e: React.FormEvent) => Promise<void>
  items: ListItem[]
  disabled: boolean
}

export default function SaveSurgicalOrderLayout({
  disabled,
  handleSubmit,
  items
}: SaveSurgicalOrderLayoutProps) {
  const history = useHistory()
  return (
    <>
      <Header />
      <S.Wrapper onSubmit={handleSubmit}>
        <S.Content>
          <ActualPage
            data-testid="btn-go-back"
            text="Voltar"
            onClick={() => history.push('/home')}
          />
          <Heading size="large" as="h1">
            Pedido de cirurgia
          </Heading>
          <SupportText>
            Anexe, abaixo, todos os dados e documentos solicitados
          </SupportText>
          <S.List data-testid="list-items">
            <List items={items} size={'small'} />
          </S.List>
        </S.Content>
        <Button data-testid="btn-send" disabled={disabled} fullWidth>
          Enviar
        </Button>
        <Button data-testid="btn-cancel" fullWidth variant="outlined">
          Cancelar
        </Button>
      </S.Wrapper>
    </>
  )
}
