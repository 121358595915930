import React from 'react'

import { Property } from 'csstype'

import Heading from 'presentation/shared/components/Heading'
import SupportText from 'presentation/shared/components/SupportText'
import ActualPage from '../ActualPage'
import { ProgressBar } from '../ProgressBar'

import * as S from './styles'

export type ContainerTypes = {
  hasHeader?: boolean
  noPadding?: boolean
  form?: boolean
  onSubmit?: () => void
}

export const Container = (
  props: {
    children: React.ReactNode
    title?: string | React.ReactNode
    subtitle?: string
    primaryButton?: React.ReactNode
    secondaryButton?: React.ReactNode
    headingButton?: React.ReactNode
    justifyContent?: Property.JustifyContent
    style?: React.CSSProperties
    actualPageText?: string
    actualPageOnClick?: () => void
    titleColor?: 'primary' | 'primaryDarker' | 'gray' | 'lightGray'
    subtitleColor?:
      | 'primary'
      | 'primaryDarker'
      | 'gray'
      | 'lightGray'
      | 'darkGray'
    progressBar?: {
      totalSteps: number
      activeStep: number
    }
  } & ContainerTypes
) => {
  return (
    <S.Wrapper
      style={props.style}
      noPadding={props.noPadding}
      as={props.form ? 'form' : 'div'}
      onSubmit={props.onSubmit}
    >
      <S.ContainerStyle noPadding={props.noPadding}>
        <S.Header style={{ padding: props.noPadding ? '0' : '0px 24px' }}>
          <div style={{ display: 'flex' }}>
            {props.actualPageText && (
              <ActualPage
                onClick={props.actualPageOnClick}
                text={props.actualPageText}
              />
            )}
            {props.headingButton && (
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  fontSize: '16px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {props.headingButton}
              </div>
            )}
          </div>

          {props.title && (
            <Heading
              as="h1"
              color={props.titleColor || 'gray'}
              style={{ margin: '10px 0px' }}
            >
              {props.title}
            </Heading>
          )}
          {props.subtitle && (
            <SupportText color={props?.subtitleColor || 'gray'}>
              {props.subtitle}
            </SupportText>
          )}
        </S.Header>
        <S.Content
          id="container-content"
          style={{
            justifyContent: props.justifyContent,
            padding: props.noPadding ? '0' : '0px 24px'
          }}
        >
          {props.children}
        </S.Content>
        {props.progressBar && (
          <ProgressBar
            stepAmount={props.progressBar.totalSteps}
            actualStep={props.progressBar.activeStep}
          />
        )}
        {(props.primaryButton || props.secondaryButton) && (
          <S.Buttons noPadding={props.noPadding}>
            {props.primaryButton}
            {props.secondaryButton}
          </S.Buttons>
        )}
      </S.ContainerStyle>
    </S.Wrapper>
  )
}
