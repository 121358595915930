import React, { useEffect, useState } from 'react'

import { useFormik } from 'formik'

import { Container } from 'presentation/shared/components/Container'
import * as S from './styles'
import Heading from 'presentation/shared/components/Heading'
import { dateInputFormat } from 'presentation/utils/date-format'
import { UpdateSurgicalOrder } from 'domain/usecases/surgical-order/update-surgical-order'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { useHistory } from 'react-router-dom'
import { AddDoctorSurgicalOrderFormValues } from 'presentation/doctor/pages/AddSurgicalOrder'
import Button from 'presentation/shared/components/Button'
import { toast } from 'react-toastify'
import Modal from 'presentation/shared/components/Modal'
import Header from 'presentation/shared/components/Header'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import getDateFormattedByLocale from 'common/utils/getDateFormmatedByLocale'
import { DateLocales } from 'common/enum/date-locales'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { PatientDocument } from 'common/enum/patient-document'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { getDocumentsLabelLocationByEnum } from 'presentation/utils/document-types-location'
import { useStores } from 'presentation/hooks/use-stores'
import { useServices } from 'presentation/hooks/use-services'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import FavoriteSurgicalOrderModal from 'presentation/doctor/components/FavoriteSurgicalOrderModal'
import FavoriteButton from 'presentation/shared/components/FavoriteButton'
import { MedicalTeamReview } from './MedicalTeamReview'
import { ReservationInfoReview } from './ReservationInfoReview'
import { ProcedureReview } from './ProcedureReview'
import { SurgicalInfoReview } from './SurgicalInfoReview'
import { BloodBankReview } from './BloodBankReview'
import { EquipmentsReview } from './EquipmentsReview'
import { OpmeReview } from './OpmeReview'
import { CmeReview } from './CmeReview'

type Props = {
  updateSurgicalOrder?: UpdateSurgicalOrder
  surgicalOrder: SurgicalOrderModel
  loadSurgicalOrderDocument?: LoadSurgicalOrderDocument
  loadSurgicalCenter: LoadSurgeryCenter
  visualizationMode?: boolean
} & WithLoadingProps

const ReviewOrder = WithLoading(
  ({
    surgicalOrder,
    loadSurgicalOrderDocument,
    loadSurgicalCenter,
    setIsLoading,
    visualizationMode
  }: Props) => {
    const [favoriteModal, setFavoriteModal] = useState(false)
    const [successModal, setSuccessModal] = useState<boolean>(false)
    const [surgicalCenterId, setSurgicalCenterId] = useState<number>()
    const [createdSurgicalOrder, setCreatedSurgicalOrder] =
      useState<SurgicalOrderModel>()
    const services = useServices().doctor
    const history = useHistory()
    const surgicalOrderService = useServices().surgicalOrder

    const { values, submitForm, isSubmitting } =
      useFormik<AddDoctorSurgicalOrderFormValues>({
        initialValues: {
          ...surgicalOrder,
          use_opme: Number(surgicalOrder.opme?.solicitations?.length) > 0,
          tuss: surgicalOrder.procedure,
          opmes: surgicalOrder.opme?.solicitations,
          provider1: surgicalOrder.opme?.providers[0],
          provider2: surgicalOrder.opme?.providers[1],
          provider3: surgicalOrder.opme?.providers[2],
          clinicalHistory: surgicalOrder.clinicalHistory,
          specialTreatment: surgicalOrder.patient?.specialTreatment,
          dialitic: surgicalOrder.patient?.dialitic,
          latex: surgicalOrder.allergy?.[0],
          patient: { ...surgicalOrder.patient, name: surgicalOrder.patientName }
        } as unknown as AddDoctorSurgicalOrderFormValues,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: async () => {
          if (surgicalOrder.surgical_order_id) {
            try {
              setIsLoading(true)
              setCreatedSurgicalOrder(
                await services.finishDoctorSurgicalOrder({
                  surgical_order_id: surgicalOrder.surgical_order_id,
                  surgical_center_id: surgicalCenterId ?? 0
                })
              )
              setSuccessModal(true)
              setIsLoading(false)
            } catch (e: any) {
              toast.error(e.message)
            } finally {
              setIsLoading(false)
            }
          }
        }
      })

    useEffect(() => {
      ;(async () => {
        setSurgicalCenterId(
          await loadSurgicalCenter
            .load({
              hospital_id: surgicalOrder.hospital?.hospital_id ?? 0,
              fields: ['codeCenter', 'description']
            })
            .then(
              (res) =>
                res.find(
                  (val) => val.description === surgicalOrder.surgicalCenter
                )?.codeCenter
            )
        )
      })()
    }, [surgicalOrder])

    const handleViewGuide = async (type: PatientDocument) => {
      try {
        setIsLoading(true)
        const guides = await surgicalOrderService
          .loadSurgicalOrder(
            createdSurgicalOrder?.surgical_order_id as number,
            ['documents {document_id, type}']
          )
          .then(
            (res?: SurgicalOrderModel) =>
              res?.documents?.filter((document) => document.name === type) || []
          )
        if (guides && guides.length > 0) {
          await Promise.all(
            guides.map(async (guide) => {
              const file = await loadSurgicalOrderDocument?.load(
                createdSurgicalOrder?.surgical_order_id ?? 0,
                guide.document_id!
              )
              if (file) {
                downloadFileFromBlob(
                  file?.data,
                  file.contentType,
                  getDocumentsLabelLocationByEnum(guide.type)
                )
              }
            })
          )
        } else {
          toast.warn(
            'Não há guias para esse tipo de documento ainda, tente novamente em instantes.'
          )
        }
      } catch (error: any) {
        toast.error(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    const patientData: DataList = [
      {
        label: 'Paciente',
        value: values.patient?.name
      },
      {
        label: 'Data de nascimento',
        value: dateInputFormat(values.patient?.birthday)
      },
      {
        label: 'Sexo',
        value: values.patient?.gender
      },
      {
        label: 'E-mail',
        value: values.patient?.email
      },
      {
        label: 'Celular',
        value: values.patient?.phone
      }
    ]

    const doctor = useStores().currentAccount.getCurrentAccount().user

    return (
      <>
        <Header />
        <Container
          title="Resumo do pedido"
          actualPageText="Voltar"
          actualPageOnClick={() => history.goBack()}
        >
          <S.Wrapper>
            <S.InfoContainer>
              <h6>Número do pedido:</h6>
              <span>{surgicalOrder.surgical_order_id}</span>
            </S.InfoContainer>
            <S.InfoContainer>
              <h6>Unidade:</h6>
              <span
                style={{
                  whiteSpace: 'pre',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {values.hospital?.name}
              </span>
            </S.InfoContainer>

            <Heading style={{ margin: '32px 0 10px' }} weight={500}>
              Dados do paciente
            </Heading>
            {patientData?.map((data) => (
              <S.InfoContainer key={data.label}>
                <h6>{data.label}:</h6>
                <span>{data.value}</span>
              </S.InfoContainer>
            ))}

            <ReservationInfoReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
            <ProcedureReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
            <SurgicalInfoReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
            <MedicalTeamReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
            <BloodBankReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
            <EquipmentsReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
            <OpmeReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
            <CmeReview
              surgicalOrder={surgicalOrder}
              visualizationMode={visualizationMode}
            />
          </S.Wrapper>
          {!visualizationMode && (
            <>
              <Button
                fullWidth
                type="button"
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Criar Pedido Cirúrgico
              </Button>
              <FavoriteButton
                data-testid="btn--doctor-procedure-favorite"
                onClick={() => setFavoriteModal(!favoriteModal)}
                style={{ margin: '10px 0px 20px', fontSize: '1.2rem' }}
                fullWidth
              />
            </>
          )}

          <Modal
            title="Pedido criado com sucesso!"
            show={successModal}
            hasCloseButton="Fechar"
            bgZIndex={12}
            close={() => history.push('/home')}
            subtitleProps={{
              colorFont: 'black'
            }}
            closeButtonProps={{
              variant: 'outlined',
              style: {
                marginTop: '8px'
              }
            }}
            style={{ padding: '24px' }}
          />

          {favoriteModal ? (
            <FavoriteSurgicalOrderModal
              surgicalOrder={surgicalOrder}
              onClose={() => setFavoriteModal(false)}
            />
          ) : null}
        </Container>
      </>
    )
  }
)

type DataList = Record<'label' | 'value', any>[]

export default ReviewOrder
