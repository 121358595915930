import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefRenderFunction
} from 'react'
import * as S from './styles'

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps = {
  label?: string
  as?: React.ElementType
  fullWidth?: boolean
} & ButtonTypes

const FavoriteButton: ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  {
    label = '★ Favoritar Procedimento',
    fullWidth = false,
    ...props
  }: ButtonProps,
  ref
) => (
  <S.Button fullWidth={fullWidth} ref={ref} {...props}>
    <p>{label}</p>
  </S.Button>
)

export default forwardRef(FavoriteButton)
