import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import { makeAutoObservable } from 'mobx'

type Services = {
  answerSurgicalPendency: AnswerSurgicalPendency
  uploadSurgicalPendencyDocument: UploadSurgicalPendencyDocument
}

export class SurgicalPendencyService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async answerSurgicalPendency(
    params: AnswerSurgicalPendency.Params
  ): Promise<AnswerSurgicalPendency.Model> {
    return this.services.answerSurgicalPendency.answer(params)
  }

  async uploadSurgicalPendencyDocument(
    params: UploadSurgicalPendencyDocument.Params
  ): Promise<UploadSurgicalPendencyDocument.Model> {
    return this.services.uploadSurgicalPendencyDocument.upload(params)
  }
}

export default SurgicalPendencyService
