import { GetPatientClassificationTypes } from 'domain/usecases/patient/get-patient-classification-types'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export class RemoteGetPatientClassificationTypes
  implements GetPatientClassificationTypes
{
  constructor(private readonly patientRepository: PatientRepository) {}

  get(
    params: GetPatientClassificationTypes.Params
  ): Promise<GetPatientClassificationTypes.Model> {
    return this.patientRepository.getPatientClassificationTypes(params)
  }
}
