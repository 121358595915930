import styled, { css, DefaultTheme } from 'styled-components'
import { lighten } from 'polished'

import { TextFieldProps } from '.'

type IconPositionProps = Pick<
  TextFieldProps,
  | 'iconPosition'
  | 'bgColor'
  | 'iconLocale'
  | 'iconMargin'
  | 'outline'
  | 'outlineColor'
  | 'inputSize'
  | 'showCalendarIcon'
>

type WrapperProps = Pick<TextFieldProps, 'disabled' | 'type'> & {
  error?: boolean
}

export const InputWrapper = styled.div<
  Pick<IconPositionProps, 'outline' | 'showCalendarIcon'>
>`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: ${({ theme }) => theme.border.radius.xsmall};
  ${({ showCalendarIcon, theme }) => {
    return showCalendarIcon && wrapperModifiers['date'](theme)
  }}
`

export const Input = styled.input<IconPositionProps>`
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.sizes.small};
  line-height: ${({ theme }) => theme.font.sizes.large};
  height: 2.75rem;
  padding-left: ${({ iconPosition }) =>
    iconPosition === 'left' ? '50px' : '18px'};
  padding-right: 18px;
  background: ${({ theme, bgColor }) => theme.colors[bgColor!]};
  border: ${({ outline, outlineColor, theme }) =>
    outline ? '1px solid' + theme.colors[outlineColor!] : 'none'};

  outline: none;
  border-radius: ${({ theme }) => theme.border.radius.xsmall};
  width: ${({ inputSize }) => inputSize};

  &:focus {
    outline: solid;
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.colors.primary} !important;
  }

  &:active {
    border-color: red;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }

  &::-webkit-outer-spin-button {
    color: red;
    background-color: yellow;
  }
`

export const Date = styled.input<IconPositionProps>`
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.sizes.small};
  line-height: ${({ theme }) => theme.font.sizes.large};
  height: 2.75rem;
  padding-left: ${({ iconPosition }) =>
    iconPosition === 'left' ? '50px' : '18px'};
  padding-right: 18px;
  background: ${({ theme, bgColor }) => theme.colors[bgColor!]};
  border: ${({ outline, outlineColor, theme }) =>
    outline ? '1px solid' + theme.colors[outlineColor!] : 'none'};

  outline: none;
  border-radius: ${({ theme }) => theme.border.radius.xsmall};
  width: ${({ iconPosition, iconLocale }) =>
    iconPosition === 'right' && iconLocale === 'outside'
      ? 'calc(100% - 2.2px)'
      : '100%'};

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }

  &:active {
    border-color: red;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`

export const Label = styled.label<
  Pick<TextFieldProps, 'labelColor' | 'labelSize'>
>`
  font-size: ${({ theme, labelSize }) => theme.font.sizes[labelSize!]};
  color: ${({ theme, labelColor }) =>
    theme.colors[labelColor!] ?? theme.colors.primary};
  font-weight: 400;
  cursor: pointer;
  margin-bottom: 12px;
`

export const LabelWrapper = styled.div`
  display: flex;
`

export const LabelIcon = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`

export const CalendarIcon = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;

  // Mobile styles
  background: ${({ theme }) => theme.colors.primary};
  height: 100%;
  width: 44px;
  border-top-left-radius: ${({ theme }) => theme.border.radius.xsmall};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius.xsmall};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`

export const Icon = styled.button<IconPositionProps>`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  order: ${({ iconPosition }) => (iconPosition === 'right' ? 1 : 0)};
  position: ${({ iconLocale }) =>
    iconLocale === 'inside' ? 'absolute' : 'relative'} !important;
  top: 50%;
  margin-top: ${({ iconLocale }) => (iconLocale !== 'inside' ? '-12px' : '0')};
  transform: translateY(-50%);
  left: ${({ iconLocale, iconPosition, iconMargin }) =>
    iconLocale === 'inside' && iconPosition === 'right'
      ? 'calc(100% - ' + iconMargin + ')'
      : 0};
  border-radius: ${({ iconPosition, theme }) =>
    iconPosition === 'right'
      ? `0 ${theme.border.radius.xsmall + ' ' + theme.border.radius.xsmall} 0`
      : `${theme.border.radius.xsmall + ' 0 0 ' + theme.border.radius.xsmall}`};
  padding: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 44px;
  outline: none;

  :focus {
    svg {
      outline: 2px solid ${({ theme }) => theme.colors.primary} !important;
      outline-offset: 5px;
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }

  svg {
    position: relative;
    max-width: 22px;
    max-height: 22px;
    outline-offset: -5px;
    transition-property: outline, outline-offset;
    transition: 0.2s;
    path {
      fill: ${({ theme }) => theme.colors.white};
      opacity: 1;
    }
  }
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.lightRed};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${Input} {
      border-color: ${theme.colors.lightRed};
      border-width: 1px;
      border-style: solid;
    }
    ${Icon} {
      color: ${theme.colors.lightRed};
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${Input},
    ${Icon} {
      &::placeholder {
        color: currentColor;
      }
    }
    ${Input} {
      background-color: ${lighten(0.3, theme.colors.lightGray)};
      color: ${theme.colors.lightGray};
    }
  `,

  date: (theme: DefaultTheme) => css`
    input[type='date'],
    input[type='datetime-local'] {
      color: ${theme.colors.primary};
      font-weight: ${theme.font.semiBold};
      font-size: ${theme.font.sizes.small};
      padding-left: 50px;
      width: 100%;
    }

    input[type='date']::-webkit-inner-spin-button,
    input[type='datetime-local']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator,
    input[type='datetime-local']::-webkit-calendar-picker-indicator {
      background: ${theme.colors.primary};
      bottom: 0;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      border-top-left-radius: ${theme.border.radius.xsmall};
      border-bottom-left-radius: ${theme.border.radius.xsmall};
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`

export const Asterisk = styled.span`
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.lightRed};
  cursor: pointer;
`
