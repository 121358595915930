import { ChangeHospital } from 'domain/usecases/hospital/change-hospital'

export const signupMutation = {
  query: `
  mutation signUpPatient($data: SignUpPatient!) {
	  signUpPatient(data: $data) {
      user_id
    }
  }
`,
  name: 'signUpPatient'
}

export const authMutation = {
  query: `
  mutation login($data: Login!) {
	  login(data: $data) {
      accessToken
      user {
        name
        photoKey
        email
        user_id
        role
        selectedHospital
        hospitals {
          hospital_id,
          name,
          nickname
        }
        finishedAppOnboarding
      }
    }
  }
`,
  name: 'login'
}

export const refreshTokenMutation = {
  query: `
  mutation refreshToken($accessToken: String!) {
	  refreshToken(accessToken: $accessToken) {
      accessToken
    }
  }
`,
  name: 'refreshToken'
}

export const logoutMutation = {
  query: `
  mutation logout($data: String!) {
	  logout(accessToken:$data)
  }
`,
  name: 'logout'
}

export const getPasswordRecoveryCodeMutation = {
  query: `
  mutation sendRecoverPassword($data: SendRecoverPassword!) {
	  sendRecoverPassword(data:$data)
  }
`,
  name: 'sendRecoverPassword'
}

export const changePasswordMutation = {
  query: `
  mutation changePassword($data: ChangePassword!) {
	  changePassword(data:$data)
  }
`,
  name: 'changePassword'
}

export const updateExamMutation = {
  query: `
  mutation updateExam($data: IUpdateExam!) {
      updateExam(data:$data) {
        exam_id
        visualized
      }
    }

  `,
  name: 'updateExam'
}

export const updatePatientInfoByIdMutation = {
  query: `
  mutation updatePatientById($data: UpdatePatientById!) {
    updatePatientById(data:$data) {
      patient_id
      name
      lastName
      birthday
      cpf
      rg
      phone
      email
      religion
      gender
      accompanists {name, accompanying_id}
      healthInsurance {
        health_insurance_id
        healthInsuranceName
        healthPlanName
        healthCard
        healthInsuranceCode
        healthPlanCode
        company
        validThru
      }
      lastHealthInsuranceUsed {
        healthInsuranceName
        healthPlanName
        card
        healthInsuranceCode
        healthPlanCode
        expirationDate
        subPlanCode
        subPlanName
      }
    }
  }
  `,
  name: 'updatePatientById'
}

export const updatePatientInfoMutation = {
  query: `
  mutation updatePatientPersonalData($data: UpdatePatient!, $forceUpdateInMV: Boolean!) {
	  updatePatientPersonalData(data:$data, forceUpdateInMV: $forceUpdateInMV) {
      patient_id
      name
      lastName
      birthday
      cpf
      phone
      email
      accompanists {name, accompanying_id}
      healthInsurance {
        health_insurance_id
        healthInsuranceName
        healthPlanName
        healthCard
        healthInsuranceCode
        healthPlanCode
        company
        validThru
      }
      lastHealthInsuranceUsed {
        healthInsuranceName
        healthPlanName
        card
        healthInsuranceCode
        healthPlanCode
        expirationDate
        subPlanCode
        subPlanName
      }
    }
  }
`,
  name: 'updatePatientPersonalData'
}

export const invitePatientMutation = {
  query: `
  mutation InvitePatient($data: CreatePatient!) {
	  invitePatient(data:$data) {
      name,
      cpf,
      phone,
      landlinePhone,
      email,
      patient_id,
      gender,
      birthday,
      maritalStatus,
      company,
      healthInsurance {
        health_insurance_id
        healthCard
        healthInsuranceCode
        healthInsuranceName
        healthPlanCode
        healthPlanName
        validThru
        company
        ansRegister
      },
    }
  }
`,
  name: 'invitePatient'
}

export const updatePatientHealthInsurance = {
  query: `
  mutation AddOrUpdateHealthInsurancePlan($data: CreateOrUpdateHealthInsurancePlan!) {
	  addOrUpdateHealthInsurancePlan(data:$data) {
      health_insurance_id
    }
  }
`,
  name: 'addOrUpdateHealthInsurancePlan'
}

export const updatePatientHealthInsuranceByCpfMutation = {
  query: `
  mutation UpdatePatientHealthInsuranceByCpf($cpf: String!,$data: UpdatePatientHealthInsurance!) {
	  updatePatientHealthInsuranceByCpf(cpf:$cpf, data:$data) {
      patient_id
    }
  }
`,
  name: 'updatePatientHealthInsuranceByCpf'
}

export const updatePatientMedicineAndDiseasesMutation = {
  query: `
  mutation UpdateMedicinesDiseases($data: UpdateMedicinesDiseases!) {
	  updateMedicinesDiseases(data:$data) {
      patient_id
    }
  }
`,
  name: 'updateMedicinesDiseases'
}

export const updateLifeHabitsMutation = {
  query: `
  mutation UpdateLifeHabits($data: UpdateLifeHabits!) {
	  updateLifeHabits(data:$data) {
      patient_id
    }
  }
`,
  name: 'updateLifeHabits'
}

export const addDoctorMutation = {
  query: `
  mutation signUpDoctor($data: SignUpDoctor!) {
	  signUpDoctor(data:$data) {
      name
    }
  }
`,
  name: 'signUpDoctor'
}

export const finishDoctorSignupMutation = {
  query: `
  mutation CompleteSignUpDoctor($data: CompleteSignUpDoctor!) {
	  completeSignUpDoctor(data:$data) {
      name
    }
  }
`,
  name: 'CompleteSignUpDoctor'
}

export const addSecretaryMutation = {
  query: `
  mutation InviteSecretary($data: InviteSecretary!) {
	  inviteSecretary(data:$data) {
      secretary_id
    }
  }
`,
  name: 'inviteSecretary'
}

export const unlinkSecretaryMutation = {
  query: `
  mutation UnlinkSecretary($secretary_id: Float!) {
	  unlinkSecretary(secretary_id:$secretary_id) {
      user_id
    }
  }
`,
  name: 'unlinkSecretary'
}

export const addCollaboratorMutation = {
  query: `
  mutation SignUpCollaborator($data: SignUpCollaborator!) {
	  signUpCollaborator(data:$data) {
      name
    }
  }
`,
  name: 'signUpCollaborator'
}

export const updatePasswordMutation = {
  query: `
  mutation UpdatePassword($data: UpdatePassword!) {
	  updatePassword(data:$data)
  }
`,
  name: 'updatePassword'
}

export const finishAppOnboardingMutation = {
  query: `
    mutation FinishAppOnboarding {
      finishAppOnboarding{
          user_id
      }
    }
  `,
  name: 'finishAppOnboarding'
}

export const finishCollaboratorSignupMutation = {
  query: `
  mutation CompleteSignUpCollaborator($data: CompleteSignUpCollaborator!) {
	  completeSignUpCollaborator(data:$data) {
      name
    }
  }
`,
  name: 'completeSignUpCollaborator'
}

export const finishSecretarySignupMutation = {
  query: `
  mutation CompleteSignUpSecretary($data: CompleteSignUpSecretary!) {
	  completeSignUpSecretary(data:$data) {
      secretary_id
    }
  }
`,
  name: 'completeSignUpSecretary'
}

export const registerSurgicalOrderMutation = {
  query: `
  mutation CreateSurgicalOrder($data: CreateSurgicalOrder!) {
	  createSurgicalOrder(data:$data) {
      surgical_order_id
    }
  }
`,
  name: 'createSurgicalOrder'
}

export const registerCrmoSurgicalOrderMutation = {
  query: `
  mutation CreateCRMOSurgicalOrder($data: CreateCRMOSurgicalOrder!) {
	  createCRMOSurgicalOrder(data:$data) {
      surgical_order_id
    }
  }
`,
  name: 'createCRMOSurgicalOrder'
}

export const reorderSurgeryMutation = {
  query: `
  mutation ReorderSurgery($data: ReorderSurgery!) {
	  reorderSurgery(data:$data) {
      surgical_order_id
    }
  }
`,
  name: 'reorderSurgery'
}

export const updateSurgicalOrderMutation = {
  query: `
  mutation UpdateSurgicalOrder($data: UpdateSurgicalOrder!) {
	  updateSurgicalOrder(data:$data) {
      surgical_order_id
    }
  }
`,
  name: 'updateSurgicalOrder'
}

export const updateSurgeryStatusMutation = {
  query: `
  mutation ChangeSurgeryStatus($surgical_order_id: Float!, $data: UpdateSurgeryStatus!) {
	  changeSurgeryStatus(surgical_order_id:$surgical_order_id, data:$data) {
      surgery_status_id
    }
  }
`,
  name: 'changeSurgeryStatus'
}

export const addAccompanyingMutation = {
  query: `
  mutation AddAccompanying($data: CreateAccompanying!) {
	  addAccompanying(data:$data) {
      name,
      accompanying_id
    }
  }
`,
  name: 'addAccompanying'
}

export const updateAccompanyingMutation = {
  query: `
  mutation UpdateAccompanying($data: UpdateAccompanying!) {
	  updateAccompanying(data:$data) {
      name
    }
  }
`,
  name: 'updateAccompanying'
}

export const deleteAccompanyingMutation = {
  query: `
  mutation DeleteAccompanists($accompanists_ids: [Int!]!) {
	  deleteAccompanists(accompanists_ids:$accompanists_ids)
  }
`,
  name: 'deleteAccompanists'
}

export const addFavoriteMutation = {
  query: `
  mutation FavoriteSurgicalOrder($data: CreateFavoriteSurgicalOrder!) {
	  favoriteSurgicalOrder(data:$data) {
	    opme {
	      providers
	    }
	  }
  }
`,
  name: 'favoriteSurgicalOrder'
}

export const addFavoriteDoctorMutation = {
  query: `
  mutation FavoriteSurgicalOrderDoctor($data: CreateFavoriteSurgicalOrder!) {
	  favoriteSurgicalOrderDoctor(data:$data) {
	    opme {
	      providers
	    }
	  }
  }
`,
  name: 'favoriteSurgicalOrderDoctor'
}

export const addPainAlertMutation = {
  query: `
  mutation CreatePainAlert($data: CreatePainAlert!) {
	  createPainAlert(data:$data) {
	    pain_alert_id
	  }
  }
`,
  name: 'createPainAlert'
}

export const createHospitalizationServiceMutation = {
  query: `
  mutation CreateHospitalizationService($data: CreateHospitalizationService!) {
	  createHospitalizationService(data:$data) {
	    hospitalization_service_id
	  }
  }
`,
  name: 'createHospitalizationService'
}

export const changeSelectedHospitalMutation = (
  params: ChangeHospital.Params
) => ({
  query: `
    mutation ChangeSelectedHospital($hospital_id: Float!) {
      changeSelectedHospital(hospital_id:$hospital_id) {${params.params.join(
        ','
      )}}
    }
  `,
  name: 'changeSelectedHospital'
})

export const createSuggestionOrCriticismMutation = {
  query: `
    mutation CreateRating($data: CreateRating!) {
      createRating(data:$data) {
        rating_id
      }
    }
  `,
  name: 'createRating'
}

export const addAllergyMutation = {
  query: `
  mutation DoctorAddAllergy($data: CreateAllergy!) {
	  doctorAddAllergy(data:$data) {
      allergy_id
    }
  }
`,
  name: 'doctorAddAllergy'
}

export const addDoctorSurgicalOrderMutation = {
  query: `
    mutation CreateDoctorSurgicalOrder($data: CreateDoctorSurgicalOrder!) {
      createDoctorSurgicalOrder(data:$data) {
        surgical_order_id,
        patient_id
      }
    }
  `,
  name: 'createDoctorSurgicalOrder'
}

export const addPatientMutation = {
  query: `mutation AddPatient($data: CreatePatient!) {
      addPatient(data:$data) {
        name,
        cpf,
        phone,
        landlinePhone,
        email,
        patient_id,
        gender,
        birthday,
        maritalStatus,
        company,
        healthInsurance {
          health_insurance_id
          healthCard
          healthInsuranceCode
          healthInsuranceName
          healthPlanCode
          healthPlanName
          validThru
          company
        },
      }
    }`,
  name: 'addPatient'
}

export const createPatientSurgicalOrderRequestMutation = {
  query: `
    mutation CreatePatientSurgicalOrderRequest($data: CreatePatientSurgicalOrderRequest!) {
      createPatientSurgicalOrderRequest(data: $data) {
        surgical_order_id
      }
    }
  `,
  name: 'createPatientSurgicalOrderRequest'
}

export const createSurgicalPendency = {
  query: `
    mutation CreateSurgicalPendency($data: CreateSurgicalPendency!) {
      createSurgicalPendency(data: $data) {
        status,
        answeredAt,
        surgical_pendency_id,
        createdAt,
        observation,
        documents {document_id},
        surgical_order_id,
        requestedBy {name, user_id}
      }
    }
  `,
  name: 'createSurgicalPendency'
}

export const answerSurgicalPendency = {
  query: `
    mutation AnswerSurgicalPendency($data: AnswerSurgicalPendency!, $group_id: String) {
      answerSurgicalPendency(data: $data, group_id: $group_id) {
        surgical_pendency_id
      }
    }
  `,
  name: 'answerSurgicalPendency'
}

export const finishSurgicalPendency = {
  query: `
    mutation ApproveSurgicalPendency($data: ApproveSurgicalPendency!) {
      approveSurgicalPendency(data: $data) {
        status,
        answeredAt,
        surgical_pendency_id,
        createdAt,
        surgical_order_id,
        requestedBy {name, user_id},
        observation
        errors
      }
    }
  `,
  name: 'approveSurgicalPendency'
}

export const completePatientSignup = {
  query: `mutation CompleteSignUpPatient($data: CompleteSignUpPatient!) {
      completeSignUpPatient(data:$data) {
        patient_id
      }
    }`,
  name: 'completeSignUpPatient'
}

export const updateUserMutation = {
  query: `
  mutation updateUser($data: UpdateUser!) {
	  updateUser(data:$data) {
      user_id
    }
  }
`,
  name: 'updateUser'
}

export const updateCollaboratorMutation = {
  query: `
  mutation updateCollaborator($data: UpdateCollaborator!) {
	  updateCollaborator(data:$data) {
      user_id
    }
  }
`,
  name: 'updateCollaborator'
}

export const createSurgicalOrderGuidesMutation = {
  query: `
  mutation GenerateGuides($data: GenerateSurgicalOrderGuides!) {
	  generateGuides(data:$data)
  }
`,
  name: 'generateGuides'
}

export const finishDoctorSurgicalOrder = {
  query: `
    mutation FinishDoctorSurgicalOrder($surgical_order_id: Float!, $surgical_center_id: Float!) {
      finishDoctorSurgicalOrder(surgical_order_id: $surgical_order_id, surgical_center_id: $surgical_center_id) {
        surgical_order_id
      }
    }
  `,
  name: 'finishDoctorSurgicalOrder'
}

export const validatePatientMotherAndBirthdayMutation = {
  query: `
    mutation ValidatePatientMotherNameAndBirthday($birthday: DateTime!, $motherName: String!, $cpf: String!) {
      validatePatientMotherNameAndBirthday(birthday: $birthday, motherName: $motherName, patientCpf: $cpf) {
        name,
        cpf,
        phone,
        email,
        patient_id,
        gender,
        birthday,
        maritalStatus
      }
    }
  `,
  name: 'validatePatientMotherNameAndBirthday'
}

export const invitePatientFromMVMutation = {
  query: `
    mutation InviteMVPatient($cpf: String!, $send: String!) {
      inviteMVPatient(send: $send, cpf: $cpf) {
        patient_id
      }
    }
  `,
  name: 'inviteMVPatient'
}

export const acceptUserTerm = {
  query: `
  mutation AcceptUserTerm($data: CreateUserTerm!) {
	  acceptUserTerm(data:$data) {
      term
    }
  }
`,
  name: 'acceptUserTerm'
}

export const updateDoctorMutation = {
  query: `
  mutation updateDoctor($data: UpdateDoctor!, $doctor_id: Float!) {
	  updateDoctor(data:$data, doctor_id: $doctor_id) {
      name
    }
  }
`,
  name: 'updateDoctor'
}

export const addAddressMutation = {
  query: `
  mutation addAddress($data: CreateAddress!) {
	  addAddress(data:$data) {
      address_id
    }
  }
`,
  name: 'addAddress'
}

export const concludeSurgicalOrderMutation = {
  query: `
  mutation ConcludeSurgicalOrder($data: ConcludeSurgicalOrder!) {
	  concludeSurgicalOrder(data:$data) {
      surgical_status_id
    }
  }
`,
  name: 'concludeSurgicalOrder'
}

export const deleteSurgicalOrderSolicitationMutation = {
  query: `
  mutation DeleteSurgicalOrderSolicitation($justification: String, $surgical_order_id: Float!) {
	  deleteSurgicalOrderSolicitation(justification: $justification, surgical_order_id: $surgical_order_id)
  }
`,
  name: 'deleteSurgicalOrderSolicitation'
}

export const resendDoctorInviteMutation = {
  query: `
  mutation ResendDoctorInvite($phone: String, $email: String, $doctor_id: Float!) {
	  resendDoctorInvite(phone: $phone, email: $email, doctor_id: $doctor_id) {
      doctor_id
	  }
  }
`,
  name: 'resendDoctorInvite'
}

export const checkinMutation = {
  query: `
  mutation Checkin($surgical_order_id: Float!) {
	  checkin(surgical_order_id: $surgical_order_id) {
      surgical_order_id
    }
  }
`,
  name: 'checkin'
}

export const inviteNewBornPatientMutation = {
  query: `
  mutation InviteNewBornPatient($data: CreateNewbornPatient!) {
	  inviteNewBornPatient(data:$data) {
      name,
      cpf,
      phone,
      landlinePhone,
      email,
      patient_id,
      gender,
      birthday,
      maritalStatus,
      company,
      healthInsurance {
        health_insurance_id
        healthCard
        healthInsuranceCode
        healthInsuranceName
        healthPlanCode
        healthPlanName
        validThru
        company
        ansRegister
      },
    }
  }
`,
  name: 'inviteNewBornPatient'
}

export const updateSurgicalOrderRegenerateGuidesMutation = {
  query: `
    mutation UpdateSurgicalOrderAndRegenerateGuides($input: UpdateSurgicalOrderAndRegenerateGuidesInput!) {
      updateSurgicalOrderAndRegenerateGuides(input: $input) {
        surgical_order_id,
        patient {
            name
            gender
            birthday
            email
            phone
        },
        healthInsurance {
            health_insurance_id
            healthInsuranceCode
            healthInsuranceName
            healthPlanCode
            healthPlanName
            company
            validThru
            healthCard
            ansRegister
        }
      }
    }
  `,
  name: 'updateSurgicalOrderRegenerateGuides'
}

export const registerSecretarySurgicalOrderMutation = {
  query: `
  mutation CreateSecretarySurgicalOrder($data: CreateSecretarySurgicalOrder!) {
	  createSecretarySurgicalOrder(data:$data) {
      surgical_order_id
    }
  }
`,
  name: 'createSecretarySurgicalOrder'
}

export const deleteFavoritedSurgicalOrderMutation = {
  query: `
    mutation DeleteFavoritededSurgicalOrder($data: DeleteFavoriteSurgicalOrder!) {
	    deleteFavoritededSurgicalOrder(data:$data)
  }
`,
  name: 'deleteFavoritededSurgicalOrder'
}

export const confirmMergeDocumentsMutation = {
  query: `
  mutation ConfirmMergeDocuments($group_id: String!, $surgical_order_id: Float!) {
	  mergeDocumentAndSendMV(
      group_id: $group_id
      surgical_order_id: $surgical_order_id
    )
  }
`,
  name: 'mergeDocumentAndSendMV'
}

export const deleteUploadedDocumentsMutation = {
  query: `
    mutation deleteDocumentById($document_id: Float!) {
      deleteDocumentById(document_id: $document_id)
    }
  `,
  name: 'deleteDocumentById'
}

export const deleteDoctorDocumentMutation = {
  query: `
    mutation DeleteDoctorDocument($data: DeleteDoctorDocumentInput!) {
      deleteDoctorDocument(data: $data)
    }`,
  name: 'deleteDoctorDocument'
}

export const DeleteDoctorDocumentValidationByIdMutation = {
  query: `
    mutation DeleteDoctorDocumentValidationById($doctor_document_validation_id: Float!) {
      deleteDoctorDocumentValidationById(doctor_document_validation_id: $doctor_document_validation_id) {
        doctor_document_validation_id
      }
    }
  `,
  name: 'deleteDoctorDocumentValidationById'
}

export const updatePatientByIdMutation = {
  query: `
    mutation UpdatePatientById($data: UpdatePatientById!) {
      updatePatientById(data: $data) {
        patient_id
      }
    }
  `,
  name: 'updatePatientById'
}

export const LinkSecretaryMutation = {
  query: `
    mutation LinkSecretary($secretary_id: Float!) {
      linkSecretary(secretary_id: $secretary_id)
    }
  `,
  name: 'linkSecretary'
}

export const createSurgicalCommentMutation = {
  query: `
    mutation CreateSurgicalCommentMutation($data: CreateSurgicalComment!) {
      createSurgicalComment(data: $data) {
        surgical_order_id
        message
        sentAt
        sentBy
      }
    }
  `,
  name: 'createSurgicalComment'
}
