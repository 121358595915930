import React from 'react'
import { Signup } from 'domain/usecases/authentication/signup'
import { AddPatientFormValues } from 'presentation/patient/pages/Signup'
import AddPatientForm from 'presentation/patient/components/Forms/AddPatientForm'

type PatientSignupLayoutProps = {
  useCase?: Signup
  initialValues?: AddPatientFormValues
  update?: boolean
}

export default function PatientSignupLayout({
  initialValues,
  useCase,
  update
}: PatientSignupLayoutProps) {
  return (
    <AddPatientForm
      useCase={useCase}
      initialValues={initialValues}
      update={update}
    />
  )
}
