import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { ApiRepository } from 'repository/api-repository'
import { RemoteSignup } from 'service/usecases/signup/remote-signup'
import PatientSignup from 'presentation/patient/pages/Signup'

export default function makePatientSignup() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const authenticationRepository = new AuthenticationRepository(
    authApiRepository
  )
  const signup = new RemoteSignup(authenticationRepository)
  return <PatientSignup useCase={signup} />
}
