import { Doctor } from 'domain/entities/doctor-model'
import { AddDoctor } from 'domain/usecases/doctor/add-doctor'
import { AddDoctorSignature } from 'domain/usecases/doctor/add-signature'
import { FinishDoctorSignup } from 'domain/usecases/doctor/finish-doctor-signup'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { LoadDoctorByInviteToken } from 'domain/usecases/doctor/load-doctor-by-invite-token'
import { SearchDoctors } from 'domain/usecases/doctor/search-doctors'
import {
  RepositoryErrors,
  RepositoryErrorsKey
} from 'repository/errors/repository-errors'
import {
  addDoctorMutation,
  addDoctorSurgicalOrderMutation,
  addFavoriteDoctorMutation,
  finishDoctorSignupMutation,
  finishDoctorSurgicalOrder,
  resendDoctorInviteMutation,
  updateDoctorMutation,
  LinkSecretaryMutation
} from 'repository/graphql/mutations'
import {
  getDoctorByInviteTokenQuery,
  getDoctorPendenciesQuery,
  getDoctorQuery,
  getDoctorSurgeryRequestsCountQuery,
  getExternalCertifications,
  getSurgicalOrdersByDoctorAndStatus,
  loadDoctorInfoQuery,
  loadDoctorSurgicalOrdersQuery,
  loadRecommendedDoctorsQuery,
  searchDoctorsQuery
} from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { DoctorRepository as IDoctorRepository } from 'repository/interfaces/doctor-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { AddDoctorModel } from 'service/usecases/add-doctor/remote-add-doctor'
import { FinishDoctorSignupModel } from 'service/usecases/finish-doctor-signup/remote-finish-doctor-signup'
import { AddDoctorSurgicalOrder } from 'domain/usecases/doctor/add-doctor-surgical-order'
import { LoadDoctorSchedule } from 'domain/usecases/doctor/load-doctor-schedule'
import { LoadDoctorSurgeryRequestCount } from 'domain/usecases/doctor/load-doctor-surgery-request-count'
import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'
import { FinishDoctorSurgicalOrder } from 'domain/usecases/doctor/finish-doctor-surgical-order'
import { LoadDoctorInfo } from 'domain/usecases/doctor/load-doctor-info'
import { UpdateDoctor } from 'domain/usecases/doctor/update-doctor'
import { LoadDocumentExternalCertifications } from 'domain/usecases/doctor/load-documents-external-certifications'
import { UploadDoctorDocument } from 'domain/usecases/doctor/upload-doctor-document'
import { LoadDoctorPendencies } from 'domain/usecases/doctor/load-doctor-pendencies'
import { LoadRecommendedDoctors } from 'domain/usecases/doctor/load-recommended-doctors'
import { ResendDoctorInvite } from 'domain/usecases/doctor/resendDoctorInvite'
import { LoadDoctorDocument } from 'domain/usecases/doctor/load-doctor-document'
import { AddFavoriteSurgicalOrder } from 'domain/usecases/doctor/add-favorite-surgical-order'
import { plainToClass } from 'class-transformer'
import { InternalServerError } from 'common/errors'
import { LinkSecretary } from 'domain/usecases/doctor/link-secretary'
import { LoadDoctorSurgicalOrders } from 'domain/usecases/doctor/load-doctor-surgical-orders'

export class DoctorRepository implements IDoctorRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadSurgicalOrdersByDoctor(
    params: LoadSurgicalOrdersByDoctor.Params
  ): Promise<LoadSurgicalOrdersByDoctor.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrdersByDoctor.Model>

    const query = getSurgicalOrdersByDoctorAndStatus(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.order_status, 'order_status'),
          ...makeGraphQLVariable(params.cancelled, 'cancelled'),
          ...makeGraphQLVariable(params.unfinished, 'unfinished'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgicalOrdersByDoctor.Model
    }
  }

  async addDoctor(params: AddDoctor.Params): Promise<Doctor> {
    const apiRepository = this.apiRepository as IApiRepository<AddDoctor.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: addDoctorMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: addDoctorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddDoctorModel
    }
  }

  async loadDoctorByInviteToken(
    token: string,
    params?: LoadDoctorByInviteToken.Params
  ): Promise<LoadDoctorByInviteToken.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorByInviteToken.Model>

    const query = getDoctorByInviteTokenQuery(token, params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(token, 'inviteToken')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorByInviteToken.Model
    }
  }

  async finishDoctorSignup(params: FinishDoctorSignup.Params): Promise<Doctor> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishDoctorSignup.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: finishDoctorSignupMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: addDoctorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishDoctorSignupModel
    }
  }

  async countSurgeryRequests(): Promise<LoadDoctorSurgeryRequestCount.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorSurgeryRequestCount.Model>

    const query = getDoctorSurgeryRequestsCountQuery()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorSurgeryRequestCount.Model
    }
  }

  async loadDoctor(params?: LoadDoctor.Params): Promise<LoadDoctor.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadDoctor.Model>

    const query = getDoctorQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctor.Model
    }
  }

  async loadDoctorPendencies(
    params: LoadDoctorPendencies.Params
  ): Promise<LoadDoctorPendencies.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorPendencies.Model>
    const query = getDoctorPendenciesQuery(params.params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.query, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorPendencies.Model
    }
  }

  async searchDoctors(
    params: SearchDoctors.Params
  ): Promise<SearchDoctors.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchDoctors.Model>

    const query = searchDoctorsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.doctorName, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchDoctors.Model
    }
  }

  async addFavorite(
    params: AddDoctorSignature.Params
  ): Promise<AddDoctorSignature.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddDoctorSignature.Model>
    const httpResponse = await apiRepository.post({
      url: '/doctor/signature',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddDoctorSignature.Model
    }
  }

  async addSurgicalOrder(
    params: AddDoctorSurgicalOrder.Params
  ): Promise<AddDoctorSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddDoctorSurgicalOrder.Model>

    const query = addDoctorSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body!
    }
  }

  async loadDoctorSchedule(
    params: LoadDoctorSchedule.Params
  ): Promise<LoadDoctorSchedule.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorSchedule.Model>
    const httpResponse: any = await apiRepository.get(
      {
        url: `/doctor/schedule?date=${params.date}`
      },
      false
    )
    if (httpResponse?.statusCode === ApiStatusCode.serverError) {
      throw handleGraphQLError(
        RepositoryErrors[
          (httpResponse?.body?.code as RepositoryErrorsKey) || 'ERROR_IN_DB'
        ]
      )
    }
    return httpResponse.body as LoadDoctorSchedule.Model
  }

  async finishDoctorSurgicalOrder(
    params: FinishDoctorSurgicalOrder.Params
  ): Promise<FinishDoctorSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishDoctorSurgicalOrder.Model>

    const query = finishDoctorSurgicalOrder

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            params?.surgical_order_id,
            'surgical_order_id'
          ),
          ...makeGraphQLVariable(
            params?.surgical_center_id,
            'surgical_center_id'
          )
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishDoctorSurgicalOrder.Model
    }
  }

  async updateDoctor(params: UpdateDoctor.Params): Promise<Doctor> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateDoctor.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateDoctorMutation.query,
        variables: {
          ...makeGraphQLVariable(params?.data, 'data'),
          ...makeGraphQLVariable(params?.doctor_id, 'doctor_id')
        }
      },
      query: updateDoctorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateDoctor.Model
    }
  }

  async loadDocumentsExternalCertifications(
    params: LoadDocumentExternalCertifications.Params
  ): Promise<LoadDocumentExternalCertifications.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDocumentExternalCertifications.Model>

    const query = getExternalCertifications(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: { ...makeGraphQLVariable(params?.doctor_id, 'doctor_id') }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDocumentExternalCertifications.Model
    }
  }

  async uploadDoctorDocument(
    params: UploadDoctorDocument.Params
  ): Promise<UploadDoctorDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadDoctorDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/doctor/upload-doctor-document',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadDoctorDocument.Model
    }
  }

  async loadRecommendedDoctors(
    params: LoadRecommendedDoctors.Params
  ): Promise<LoadRecommendedDoctors.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadRecommendedDoctors.Model>

    const query = loadRecommendedDoctorsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.query, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadRecommendedDoctors.Model
    }
  }

  async resendDoctorInvite(
    params: ResendDoctorInvite.Params
  ): Promise<ResendDoctorInvite.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ResendDoctorInvite.Model>

    const query = resendDoctorInviteMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.phone, 'phone'),
          ...makeGraphQLVariable(params.email, 'email'),
          ...makeGraphQLVariable(params.doctor_id, 'doctor_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ResendDoctorInvite.Model
    }
  }

  async loadDoctorInfo(
    doctor_id: number,
    params?: LoadDoctorInfo.Params
  ): Promise<LoadDoctorInfo.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorInfo.Model>

    const query = loadDoctorInfoQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(doctor_id, 'doctor_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return plainToClass(Doctor, httpResponse.body)
    }
  }

  async loadDoctorDocument(
    params: LoadDoctorDocument.Params
  ): Promise<LoadDoctorDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/doctor/${params.doctor_id}/document/${params.document_id}`
      },
      true
    )
    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    if (httpResponse.status && httpResponse.status !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return {
        contentType,
        data
      }
    }
  }

  async addFavoriteSurgicalOrder(
    params: AddFavoriteSurgicalOrder.Params
  ): Promise<AddFavoriteSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddFavoriteSurgicalOrder.Model>

    const query = addFavoriteDoctorMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddFavoriteSurgicalOrder.Model
    }
  }

  async linkSecretary(
    params: LinkSecretary.Params
  ): Promise<LinkSecretary.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LinkSecretary.Model>

    const query = LinkSecretaryMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.secretary_id, 'secretary_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LinkSecretary.Model
    }
  }

  async loadDoctorSurgicalOrders(
    params: LoadDoctorSurgicalOrders.Params
  ): Promise<LoadDoctorSurgicalOrders.Model> {
    const apiRepository = this.apiRepository

    const query = loadDoctorSurgicalOrdersQuery()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body.surgicalOrders as LoadDoctorSurgicalOrders.Model
    }
  }
}
