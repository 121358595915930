import React from 'react'
import CreateSurgicalOrderIntroLayout from 'presentation/patient/layouts/CreateSurgicalOrder/Intro'
import { useHistory } from 'react-router'

export default function CreateSurgicalOrderIntro() {
  const history = useHistory()

  return (
    <CreateSurgicalOrderIntroLayout
      title="Pedido de cirurgia"
      description="Selecione a unidade em que o seu
      procedimento será realizado"
      orderFn={() => history.push('/pedido-cirurgico/save')}
      goBack={() => history.goBack()}
    />
  )
}
