export enum CommunicationParticularity {
  LANGUAGE = 'LANGUAGE',
  AGE = 'AGE',
  SCHOOLING = 'SCHOOLING',
  VISUAL = 'VISUAL',
  HEARING = 'HEARING',
  COGNITIVE = 'COGNITIVE',
  PSYCHIC = 'PSYCHIC',
  COMMUNICATIVE = 'COMMUNICATIVE',
  EMOTIONAL = 'EMOTIONAL'
}
