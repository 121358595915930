import React from 'react'

import * as S from './styles'
import AccompanyingForm, {
  AccompanyingFormValues
} from 'presentation/patient/components/Forms/Accompanying'
import Header from 'presentation/shared/components/Header'
import { Container } from 'presentation/shared/components/Container'
import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'

type Props = {
  method: 'update' | 'create'
  addAccompanying?: AddAccompanying
  updateAccompanying?: UpdateAccompanying
  initialValues?: AccompanyingFormValues
}

export default function AddAccompanyingLayout({
  method,
  initialValues,
  addAccompanying,
  updateAccompanying
}: Props) {
  return (
    <>
      <Header />
      <Container hasHeader>
        <S.Wrapper>
          <AccompanyingForm
            addAccompanying={addAccompanying}
            updateAccompanying={updateAccompanying}
            method={method}
            initialValues={initialValues}
          />
        </S.Wrapper>
      </Container>
    </>
  )
}
