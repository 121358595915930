import React from 'react'

import { useFormik } from 'formik'
import moment from 'moment-timezone'

import { CommunicationParticularity } from 'common/enum/communication-particularity'
import { Gender } from 'common/enum/gender'
import TextField from 'presentation/shared/components/TextField'
import MultipleSelect from 'presentation/shared/components/MultipleSelect'
import SelectField from 'presentation/shared/components/SelectField'
import TextArea from 'presentation/shared/components/TextArea'
import Button from 'presentation/shared/components/Button'
import Heading from 'presentation/shared/components/Heading'
import { dateMask, phoneMask } from 'presentation/utils/masks'
import * as S from './styles'
import { communicationParticularities } from 'presentation/utils/default-communication-particularities'
import ProfilePic from 'presentation/shared/components/ProfilePic'
import { Patient } from 'domain/entities/patient-model'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import Modal from 'presentation/shared/components/Modal'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { maritalStatus } from 'presentation/utils/default-marital-status'
import { useHistory } from 'react-router'
import ActualPage from '../../../../shared/components/ActualPage'

type Props = {
  patient: Patient
  updatePatientInfo?: UpdatePatientInfo
}

export default function UpdatePersonalInfoForm({
  patient,
  updatePatientInfo
}: Props) {
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const history = useHistory()

  const formik = useFormik<updatePersonalInfoFormValues>({
    initialValues: {
      ...patient,
      birthday: patient?.birthday
        ? moment(patient?.birthday).utc().format('DD/MM/YYYY')
        : ''
    } as updatePersonalInfoFormValues,
    onSubmit: async (values) => {
      try {
        await updatePatientInfo?.update({
          ...values,
          phone: values.phone?.replace(/\D+/g, ''),
          birthday: moment(values.birthday, 'DD/MM/YYYY').utc().toISOString(),
          height: Number(values.height?.toString().replace(/\D+/g, '')),
          weight: Number(values.weight?.toString().replace(/\D+/g, '')),
          children: Number(values.children?.toString().replace(/\D+/g, '')),
          needAttention: values.needAttention === 'true'
        })
        setSuccessModal(true)
      } catch (error) {
        toast.error(error.message)
      }
    }
  })

  const getAge = () => {
    if (
      formik.values.birthday?.length === 10 &&
      moment(formik.values.birthday, 'DD/MM/YYYY').isValid()
    ) {
      return moment().diff(
        moment(formik.values.birthday, 'DD/MM/YYYY'),
        'years'
      )
    } else return ''
  }

  return (
    <>
      <S.Wrapper onSubmit={formik.handleSubmit}>
        <ActualPage text="Voltar" />
        <ProfilePic
          src="https://source.unsplash.com/random"
          outlined
          size="large"
          style={{
            display: 'block',
            margin: '0 auto',
            marginTop: '36px',
            marginBottom: '4px'
          }}
        />
        <p>{formik.values.name}</p>
        <TextField
          label="Nome"
          value={formik.values.name}
          onInputChange={formik.handleChange('name')}
          disabled={true}
        />
        <S.Row cols={2}>
          <TextField
            label="Data de nascimento"
            mask={dateMask}
            value={formik.values.birthday}
            onInputChange={formik.handleChange('birthday')}
            disabled={true}
          />
          <TextField label="Idade" disabled value={getAge()} />
        </S.Row>
        <S.Row cols={2}>
          <SelectField
            label="Estado civil"
            value={formik.values.maritalStatus}
            onInputChange={formik.handleChange('maritalStatus')}
            items={maritalStatus}
            disabled={true}
          />
          <TextField
            label="Filhos"
            type="number"
            value={formik.values.children}
            onInputChange={formik.handleChange('children')}
          />
        </S.Row>
        <S.Row cols={1}>
          <SelectField
            label="Sexo"
            value={formik.values.gender}
            items={[
              { label: 'Masculino', value: Gender.MALE },
              { label: 'Feminino', value: Gender.FEMALE }
            ]}
            onInputChange={formik.handleChange('gender')}
            disabled={true}
          />
        </S.Row>
        <S.Row cols={3}>
          <S.TypeTextField>
            <TextField
              label="Peso"
              defaultValue={formik.values.weight?.toString().replace('.', ',')}
              onInputChange={formik.handleChange('weight')}
              icon={
                <S.BodyInfoLabel onMouseDown={(e) => e.stopPropagation()}>
                  kg
                </S.BodyInfoLabel>
              }
              iconLocale="inside"
              iconPosition="right"
              iconMargin="30px"
            />
          </S.TypeTextField>
          <S.TypeTextField>
            <TextField
              label="Altura"
              defaultValue={
                formik.values.height
                  ? formik.values.height
                  : (formik.values.height / 100).toString().replace('.', ',')
              }
              onInputChange={formik.handleChange('height')}
              icon={
                <S.BodyInfoLabel onClick={(e) => e.stopPropagation()}>
                  cm
                </S.BodyInfoLabel>
              }
              iconLocale="inside"
              iconPosition="right"
              iconMargin="30px"
            />
          </S.TypeTextField>
          <TextField
            label="IMC"
            disabled
            type="number"
            value={(
              formik.values.weight /
              Math.pow(
                Number(formik.values.height?.toString().replace(/\D+/g, '')) /
                  100,
                2
              )
            ).toFixed(2)}
          />
        </S.Row>
        <S.Row cols={2}>
          <TextField
            label="Contato"
            mask={phoneMask}
            value={formik.values.phone}
            onInputChange={formik.handleChange('phone')}
          />
          <TextField
            label="E-mail"
            value={formik.values.email}
            onInputChange={formik.handleChange('email')}
          />
        </S.Row>
        <TextField
          label="Escolaridade"
          value={formik.values.schooling}
          onInputChange={formik.handleChange('schooling')}
          style={{ marginTop: '12px' }}
        />
        <S.Row cols={2}>
          <TextField
            label="Profissão"
            value={formik.values.profession}
            onInputChange={formik.handleChange('profession')}
          />
          <TextField
            label="Empresa"
            value={formik.values.company}
            onInputChange={formik.handleChange('company')}
          />
        </S.Row>
        <TextField
          label="Religião"
          value={formik.values.religion}
          onInputChange={formik.handleChange('religion')}
          style={{ marginTop: '12px' }}
        />

        <SelectField
          label="Possui alguma crença ou hábito que precise de atenção durante o tratamento?"
          style={{ marginTop: '12px' }}
          onInputChange={formik.handleChange('needAttention')}
          value={String(formik?.values?.needAttention)}
          items={[
            { label: 'Sim', value: 'true' },
            { label: 'Não', value: 'false' }
          ]}
        />
        <S.Row cols={1}>
          <TextArea
            style={{ width: '100%' }}
            rows={6}
            value={formik.values.additionalObservation}
            onInputChange={formik.handleChange('additionalObservation')}
            placeholder="Observações Adicionais"
          />
        </S.Row>
        <Heading
          as="h2"
          color="primary"
          style={{
            marginTop: '24px',
            marginBottom: '12px',
            alignSelf: 'flex-start'
          }}
        >
          Possui particularidades de comunicação
        </Heading>
        <MultipleSelect
          items={communicationParticularities.map((disease) => ({
            label: disease.label,
            value: disease.value
          }))}
          initialValue={formik.values.communicationParticularity}
          name="communicationParticularity"
          onCheck={formik.handleChange('communicationParticularity')}
        />
        <Button color="primary" fullWidth type="submit">
          Salvar
        </Button>
      </S.Wrapper>
      <Modal
        title="Dados atualizados com sucesso"
        show={successModal}
        close={() => history.push('/perfil')}
      />
    </>
  )
}

type updatePersonalInfoFormValues = {
  name: string
  lastName: string
  birthday: string
  gender: string
  email: string
  phone: string
  needAttention: boolean | string
  additionalObservation: string
  communicationParticularity: CommunicationParticularity[]
  maritalStatus: string
  children: number
  weight: number
  height: number
  schooling: string
  company: string
  religion: string
  profession?: string
}
