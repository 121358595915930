import React from 'react'
import OnboardingLayout from 'presentation/patient/layouts/Onboarding'
import { FinishAppOnboarding } from 'domain/usecases/user/finish-app-onboarding'

type OnboardingProps = {
  finishAppOnboarding: FinishAppOnboarding
}
export default function Onboarding({ finishAppOnboarding }: OnboardingProps) {
  return <OnboardingLayout finishAppOnboarding={finishAppOnboarding} />
}
