import React, { useRef } from 'react'
import ActualPage from 'presentation/shared/components/ActualPage'
import * as S from './styles'

type ServicesMenuProps = {
  goBack?: () => void
  onSelectItem: (value: string) => void
  selectedItemMenu: string
}

type MenuItems = {
  title: string
  key: string
}[]

export function ServicesMenu({
  goBack,
  onSelectItem,
  selectedItemMenu
}: ServicesMenuProps) {
  const menuItems: MenuItems = [{ title: 'CIRURGIA', key: 'surgery' }]

  const scrollRef = useRef<HTMLDivElement>(null)

  const handleSelected = (selectedItem: string) => {
    onSelectItem(selectedItem)
  }

  return (
    <>
      <S.ActualPage>
        <ActualPage text={'Voltar'} onClick={goBack} color="primaryDarker" />
      </S.ActualPage>
      <div style={{ marginBottom: 20 }}>
        <S.Wrapper ref={scrollRef}>
          {menuItems.map((item, index) => (
            <S.MenuItem
              key={index}
              onClick={() => handleSelected(item.key)}
              selected={selectedItemMenu === item.key ?? false}
            >
              {item.title}
            </S.MenuItem>
          ))}
        </S.Wrapper>
      </div>
    </>
  )
}
