import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import * as yup from 'yup'
import 'main/config/yup'

import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'

import ActualPage from 'presentation/shared/components/ActualPage'
import Button from 'presentation/shared/components/Button'
import Carousel, {
  CarouselState
} from 'presentation/shared/components/Carousel'
import { Container } from 'presentation/shared/components/Container'
import Heading from 'presentation/shared/components/Heading'
import SelectField from 'presentation/shared/components/SelectField'
import TextField from 'presentation/shared/components/TextField'
import { cpfMask, phoneMask, dateMask } from 'presentation/utils/masks'

import * as S from './styles'
import { Gender } from 'common/enum/gender'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import Header from 'presentation/shared/components/Header'
import getPhoneWithCountryCode from 'common/utils/getPhoneWithCountryCode'
import { CountryPhoneCode } from 'common/enum/country-phone-codes'

type CarouselPersonalInfoFormProps = {
  useCase?: UpdatePatientInfo
  initialValues?: PatientPersonalInfoForm
}

export default function CarouselPersonalInfoForm({
  useCase,
  initialValues = {} as PatientPersonalInfoForm
}: CarouselPersonalInfoFormProps) {
  const [carousel, setCarousel] = useState({} as CarouselState)

  const history = useHistory()

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CarouselPersonalInfoValidation,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const birthday = moment(values.birthday, 'DD/MM/YYYY', true)
        .tz('America/Sao_Paulo')
        .toISOString()
      const sendObject = Object.assign({}, values)
      delete sendObject.cpf
      delete sendObject.user

      try {
        await useCase?.update({ ...sendObject, birthday })
        toast.success('Dados salvos com sucesso')
        history.push('/pedido-cirurgico/save')
      } catch (err: any) {
        toast.error(err.message)
      }
    }
  })

  const handleApplyPhoneCountryCode = () => {
    if (carousel.activeIndex === 2) {
      formik.setFieldValue(
        'phone',
        getPhoneWithCountryCode(formik.values.phone, CountryPhoneCode.BRAZIL)
      )
    }
  }

  useEffect(() => {
    handleApplyPhoneCountryCode()
  }, [carousel.activeIndex])

  return (
    <>
      <Header />
      <Container hasHeader>
        <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
          <ActualPage
            onClick={
              carousel.activeIndex === 0 ? history.goBack : carousel.slidePrev
            }
            text="Voltar"
          />
          <Heading size="large" as="h1">
            Dados pessoais
          </Heading>
          <Carousel
            state={carousel}
            setState={setCarousel}
            slidesPerView={1}
            touch={false}
          >
            <S.Step>
              <S.Content>
                <TextField
                  label="Paciente"
                  name="name"
                  onInputChange={formik.handleChange('name')}
                  value={formik.values.name}
                  onBlur={formik.handleBlur('name')}
                  error={formik.touched.name && formik.errors.name}
                  required
                />
                <TextField
                  style={{ marginTop: '22px' }}
                  label="CPF"
                  defaultValue={formik.initialValues.cpf}
                  mask={cpfMask}
                  disabled={true}
                  required
                />
              </S.Content>
              <S.Buttons>
                <Button
                  disabled={!!formik.errors.name}
                  type="button"
                  fullWidth
                  onClick={carousel.slideNext}
                >
                  Confirmar
                </Button>
              </S.Buttons>
            </S.Step>

            <S.Step>
              <S.Content>
                <TextField
                  name="birthday"
                  label="Data de nascimento"
                  placeholder="dd/mm/yyyy"
                  mask={dateMask}
                  onInputChange={formik.handleChange('birthday')}
                  onBlur={formik.handleBlur('birthday')}
                  defaultValue={formik.initialValues.birthday}
                  error={
                    formik.touched.birthday &&
                    formik.errors.birthday &&
                    'Data inválida'
                  }
                  required
                />
                <SelectField
                  style={{ marginTop: '22px' }}
                  name="gender"
                  label="Sexo"
                  items={[
                    {
                      label: 'Masculino',
                      value: 'Masculino'
                    },
                    {
                      label: 'Feminino',
                      value: 'Feminino'
                    }
                  ]}
                  onInputChange={formik.handleChange('gender')}
                  onBlur={formik.handleBlur('gender')}
                  defaultValue={formik.initialValues.gender}
                  error={formik.touched.gender && formik.errors.gender}
                  required
                />
              </S.Content>
              <S.Buttons>
                <Button
                  disabled={!!formik.errors.birthday || !!formik.errors.gender}
                  fullWidth
                  type="button"
                  onClick={carousel.slideNext}
                >
                  Confirmar
                </Button>
              </S.Buttons>
            </S.Step>
            <S.Step>
              <S.Content>
                <TextField
                  label="E-mail"
                  name="email"
                  onInputChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                  defaultValue={formik.initialValues.email}
                  error={formik.touched.email && formik.errors.email}
                  required
                />
                <TextField
                  style={{ marginTop: '22px' }}
                  label="Telefone"
                  name="phone"
                  onInputChange={formik.handleChange('phone')}
                  onBlur={formik.handleBlur('phone')}
                  defaultValue={getPhoneWithCountryCode(
                    formik.initialValues.phone,
                    CountryPhoneCode.BRAZIL
                  )}
                  mask={phoneMask}
                  error={formik.touched.phone && formik.errors.phone}
                  required
                />
              </S.Content>
              <S.Buttons>
                <Button
                  disabled={!!formik.errors.email || !!formik.errors.phone}
                  fullWidth
                  type="submit"
                >
                  Enviar
                </Button>
              </S.Buttons>
            </S.Step>
          </Carousel>
        </S.Wrapper>
      </Container>
    </>
  )
}

export type PatientPersonalInfoForm = {
  name: string
  birthday: string
  cpf?: string
  gender: Gender
  email: string
  phone: string
  user?: string
}

const CarouselPersonalInfoValidation = yup.object().shape({
  name: yup.string().required(),
  birthday: yup.date().format('DD/MM/YYYY', true).required(),
  gender: yup.string().oneOf(['Masculino', 'Feminino']).required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .test('masked-phone-validation', 'Telefone inválido', function (val) {
      return !!(
        val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
        val?.match(/^[0-9]{13}$/)
      )
    })
    .required()
})
